<template>
    <div class="right">
        <el-dialog :visible.sync="isRightVisible" top="0" width="250px">
            <el-form>
                <el-form-item>
                    <el-divider>导航模式</el-divider>
                    <el-radio-group v-model="defaultLayout">
                        <el-tooltip class="item" effect="dark" content="横向菜单" placement="top-start">
                            <el-radio label="top">
                                <img src="~@/assets/img/top_layout.svg"/>
                            </el-radio>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="左侧菜单" placement="top-start">
                            <el-radio label="left">
                                <img src="~@/assets/img/left_layout.svg"/>
                            </el-radio>
                        </el-tooltip>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-divider>主题色</el-divider>
                    <div class="tag-group">
                        <el-tooltip effect="dark" :content="item.key" placement="top-start" v-for="(item, index) in colorList"
                                    :key="index">
                            <el-tag :color="item.color" class="themeColorTag" @click="defaultTheme = item.color">
                                <i class="el-icon-check themeColorFont" v-if="item.color === defaultTheme"></i>
                            </el-tag>
                        </el-tooltip>
                    </div>
                </el-form-item>
                <el-divider>其它设置</el-divider>
                <el-form-item label="多页签模式">
                    <el-switch v-model="isTab"></el-switch>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import theme from "@/utils/theme"

    export default {
        mixins: [theme],
        data() {
            return {
                isRightVisible: false, // 是否显示右边配置
                // 主题色
                colorList: [
                    {
                        key: '棕色（默认）', color: '#A9976D'
                    },
                    {
                        key: '文物红', color: '#A82126'
                    },
                    {
                        key: '拂晓蓝', color: '#1890FF'
                    },
                    {
                        key: '薄暮', color: '#F5222D', label: '1'
                    },
                    {
                        key: '火山', color: '#FA541C', label: '2'
                    },
                    {
                        key: '日暮', color: '#FAAD14'
                    },
                    {
                        key: '明青', color: '#13C2C2'
                    },
                    {
                        key: '极光绿', color: '#52C41A'
                    },
                    {
                        key: '极客蓝', color: '#2F54EB'
                    },
                    {
                        key: '酱紫', color: '#722ED1'
                    },
                    {
                        key: '天空蓝', color: '#3e8df7'
                    },
                    {
                        key: '咖啡色', color: '#9a7b71'
                    },
                    {
                        key: '深湖蓝', color: '#07b2d3'
                    },
                    {
                        key: '原谅绿', color: '#0cc26c'
                    },
                    {
                        key: '古铜灰', color: '#757575'
                    },
                    {
                        key: '珊瑚紫', color: '#6779fa'
                    },
                    {
                        key: '橙黄', color: '#eb6607'
                    },
                    {
                        key: '粉红', color: '#f74584'
                    },
                    {
                        key: '青紫', color: '#9463f7'
                    },
                    {
                        key: '橄榄绿', color: '#16b2a3'
                    }
                ]
            }
        },
        computed: {
            // 布局
            defaultLayout: {
                get() {
                    return this.$store.state.config.defaultLayout
                },
                set(val) {
                    localStorage.setItem('defaultLayout', val)
                    this.$store.commit('config/updateDefaultLayout', val)
                }
            },
            // 多页签模式
            isTab: {
                get() {
                    return this.$store.state.common.isTab
                },
                set(val) {
                    localStorage.setItem('isTab', val)
                    this.$store.commit('common/updateIsTab', val)
                }
            },
            // 主题色
            defaultTheme: {
                get () {
                    return this.$store.state.config.defaultTheme
                },
                set (val) {
                    localStorage.setItem('defaultTheme', val)
                    this.$events.$emit('updateTheme', val)
                    return this.$store.commit('config/updateDefaultTheme', val)
                }
            },
        },
        methods: {
            // 显示右边配置
            showRight() {
                this.isRightVisible = true
            }
        }
    }
</script>
<style scoped>
    .right >>> .el-dialog {
        margin: 0 0 0 calc(100% - 250px);
        height: 100vh;
        overflow: hidden;
        border-radius: 0;
    }

    .right >>> .el-dialog__header {
        padding-top: 40px;
    }

    .right >>> .el-dialog__body {
        padding-top: 0;
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    .right >>> .el-radio__label {
        vertical-align: middle;
    }

    .right >>> .el-radio {
        margin-right: 18px;
        margin-bottom: 18px;
    }

    .themeColorTag {
        width: 25px !important;
        height: 25px !important;
        margin-left: 0px !important;
        margin-bottom: 0px !important;
    }

    .themeColorFont {
        position: absolute;
        color: #fff;
        margin-top: 3px;
        margin-left: -6px;
        font-weight: bold;
        font-size: 16px;
    }
</style>
