var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":_vm.title,"close-on-click-modal":false,"width":"700px","visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"inputForm",attrs:{"size":"small","model":_vm.inputForm,"status-icon":"","rules":_vm.dataRule,"label-width":"120px"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.inputFormSubmit()},"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":15}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"角色名称","prop":"name","rules":[
            {required: true, whitespace: true, message: '角色名称不能为空', trigger: 'blur'}
          ]}},[_c('el-input',{attrs:{"placeholder":"角色名称","maxlength":"50"},model:{value:(_vm.inputForm.name),callback:function ($$v) {_vm.$set(_vm.inputForm, "name", $$v)},expression:"inputForm.name"}})],1)],1),(_vm.hasPermission('sys:role:admin'))?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"是否管理员","prop":"isSys","rules":[
            {required: true, message: '是否管理员不能为空', trigger: 'blur'}
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.inputForm.isSys),callback:function ($$v) {_vm.$set(_vm.inputForm, "isSys", $$v)},expression:"inputForm.isSys"}},_vm._l((_vm.optionArr2),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1):_vm._e(),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"是否可用","prop":"useable","rules":[
            {required: true, message: '是否可用不能为空', trigger: 'blur'}
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.inputForm.useable),callback:function ($$v) {_vm.$set(_vm.inputForm, "useable", $$v)},expression:"inputForm.useable"}},_vm._l((_vm.optionArr),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"help-block"},[_vm._v("“是”代表此数据可用，“否”则表示此数据不可用")])],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"备注","maxlength":"200"},model:{value:(_vm.inputForm.remarks),callback:function ($$v) {_vm.$set(_vm.inputForm, "remarks", $$v)},expression:"inputForm.remarks"}})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("关闭")]),_c('el-button',{directives:[{name:"noMoreClick",rawName:"v-noMoreClick"}],attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.inputFormSubmit()}}},[_vm._v("确定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }