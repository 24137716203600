<template>
    <div class="page">
        <div class="query-form p_b1">
            <div class="m_b1">
                <span class="fontSize14">参观日期</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="searchForm.makeDate"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" style="width: 29.6%">
                </el-date-picker>
                <el-select class="m_r1" clearable v-model="searchForm.payState" placeholder="订单状态" size="small"
                           style="width: 18%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('order_status')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="m_r1" clearable v-model="searchForm.ticketingState" placeholder="预约状态" size="small"
                           style="width: 18%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('ticketing_state')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div>
                <span class="fontSize14">入馆时段</span>
                <el-time-picker class="m_l1 m_r1" size="small"
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                                v-model="searchForm.makeStartTime" placeholder="开始时段" style="width: 13.7%">
                </el-time-picker>
                <span class="fontSize14">至</span>
                <el-time-picker class="m_l1 m_r1" size="small"
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                                v-model="searchForm.makeEndTime" placeholder="结束时段" style="width: 13.3%">
                </el-time-picker>
                <el-input class="m_r1" size="small" v-model="searchForm.keyWords" maxlength="50"
                          placeholder="联系人/联系电话/参观人姓名/证件号码" clearable style="width: 24%"></el-input>
                <el-button type="primary" @click="queryData(1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </div>
        </div>
        <div class="bg-white">
            <div class="flex_b_c">
                <el-button type="primary" :disabled="orderList.length <= 0" @click="exportData()" size="small">导出</el-button>
                <el-button-group>
                    <el-button :type="listType ? '': 'primary'" size="mini" @click="changeListType(0)">按订单</el-button>
                    <el-button :type="listType ? 'primary': ''" size="mini" @click="changeListType(1)">按参观人员</el-button>
                </el-button-group>
            </div>
            <el-table v-show="!listType"
                      :data="orderList"
                      v-loading="orderLoading"
                      size="small" ref="orderTable"
                      height="calc(100vh - 350px)" class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="outTradeNo" label="订单编号" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="下单时间" sortable show-overflow-tooltip
                                 width="120"></el-table-column>
                <el-table-column prop="payDate" label="支付时间" sortable show-overflow-tooltip
                                 width="120"></el-table-column>
                <el-table-column prop="payState" label="订单状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("order_status",scope.row.payState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingState" label="预约状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("ticketing_state",scope.row.ticketingState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="makeDate" label="参观日期" sortable show-overflow-tooltip
                                 width="120"></el-table-column>
                <el-table-column prop="storehouseTime" label="入馆时段" show-overflow-tooltip width="140"></el-table-column>
                <el-table-column prop="ticketingNoun" label="参观人数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVerbName" label="联系人" width="100"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVerbPhone" label="联系电话" width="100"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="countMoney" label="总价（元）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="authorizationPhone" label="授权电话" width="100"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('order:list:view')" icon="el-icon-view" type="text" size="mini"
                                   @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table v-show="listType"
                      :data="visitorList"
                      v-loading="visitorLoading"
                      size="small" ref="visitorTable"
                      height="calc(100vh - 350px)" class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cardTypeName" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="证件号码" width="120" sortable
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingValidationCode" label="票号" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorState" label="参观状态" width="100" sortable show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("visitor_state",scope.row.ticketingVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingValidationTime" label="参观时间" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRequestDate" label="退款时间" width="120" show-overflow-tooltip></el-table-column>

                <el-table-column prop="createOutRequestDate" label="退票时间" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="makeDate" label="参观日期" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="storehouseTime" label="入馆时段" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingMoney" label="单价（元）" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('order:list:view')" icon="el-icon-view" type="text" size="mini"
                                   @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="text_center"
                           :current-page="!listType ? orderCurrent : visitorCurrent"
                           :page-size="!listType ? orderSize : visitorSize"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="!listType ? orderTotal : visitorTotal"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--查看-->
        <OrderView ref="orderView"></OrderView>
    </div>
</template>

<script>
    import OrderView from './orderView'

    export default {
        components: {OrderView},
        data() {
            return {
                searchForm: {
                    keyWords: '',
                    payState: '',
                    ticketingState: '',
                    makeDate: [],
                    makeStartTime: '',
                    makeEndTime: '',
                    ticketingTerminal: '',
                },
                listType: 0,
                // 订单列表
                orderList: [],
                orderLoading: false,
                orderCurrent: 1,
                orderSize: 10,
                orderTotal: 0,
                // 人员列表
                visitorList: [],
                visitorLoading: false,
                visitorCurrent: 1,
                visitorSize: 10,
                visitorTotal: 0,
            }
        },
        created() {
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 数据查询 type: 0订单；1人员
            queryData(current) {
                let param = {
                    keyWords: this.searchForm.keyWords,
                    payState: this.searchForm.payState,
                    ticketingState: this.searchForm.ticketingState,
                    ticketingTerminal: this.searchForm.ticketingTerminal,
                    makeStartDate: this.searchForm.makeDate ? this.searchForm.makeDate[0] : '',
                    makeEndDate: this.searchForm.makeDate ? this.searchForm.makeDate[1] : '',
                    makeStartTime: this.searchForm.makeStartTime,
                    makeEndTime: this.searchForm.makeEndTime,
                }
                if (this.listType == 0) {
                    param.size = this.orderSize
                    if (current) {
                        param.current = current
                        this.orderCurrent = current
                    } else {
                        param.current = this.orderCurrent
                    }
                    this.orderLoading = true
                    this.$axios(this.api.order.sysGetTicketingMake, param, 'post').then((res) => {
                        if (res.status) {
                            this.orderList = res.data.records
                            this.orderTotal = parseInt(res.data.total)
                            this.$nextTick(() => {
                                this.$refs.orderTable.doLayout();
                            })
                        } else {
                            this.$message.error('查询失败');
                        }
                        this.orderLoading = false
                    })
                } else {
                    param.size = this.visitorSize
                    if (current) {
                        param.current = current
                        this.visitorCurrent = current
                    } else {
                        param.current = this.visitorCurrent
                    }
                    this.visitorLoading = true
                    this.$axios(this.api.order.sysGetTicketingMakeDetail, param, 'post').then((res) => {
                        if (res.status) {
                            this.visitorList = res.data.records
                            this.visitorTotal = parseInt(res.data.total)
                            this.$nextTick(() => {
                                this.$refs.visitorTable.doLayout();
                            })
                        } else {
                            this.$message.error('查询失败');
                        }
                        this.visitorLoading = false
                    })
                }

            },
            // 重置
            resetSearch() {
                this.searchForm = {
                    keyWords: '',
                    payState: '',
                    makeDate: [],
                    makeStartTime: '',
                    makeEndTime: '',
                    ticketingTerminal: '',
                    ticketingState: '',
                }
                this.queryData(1)
            },
            // 导出
            exportData() {
                let makeStartDate = this.searchForm.makeDate.length ? this.searchForm.makeDate[0] : ''
                let makeEndDate = this.searchForm.makeDate.length ? this.searchForm.makeDate[1] : ''
                window.open(this.$globalUrl() + this.api.order.ticketingmakeExPortList + '?keyWords=' + this.searchForm.keyWords + '&makeStartDate=' + makeStartDate + '&makeEndDate=' + makeEndDate + '&makeEndTime=' + this.searchForm.makeEndTime + '&makeStartTime=' + this.searchForm.makeStartTime + '&payState=' + this.searchForm.payState + '&ticketingState=' + this.searchForm.ticketingState)
            },
            // 列表切换
            changeListType(type) {
                this.listType = type
                this.queryData()
            },
            // 查看
            view(row) {
                this.$refs.orderView.init(row)
            },
            // 订单每页数
            sizeChangeHandle(val) {
                if (!this.listType) {
                    this.orderSize = val;
                    this.orderCurrent = 1;
                } else {
                    this.visitorSize = val;
                    this.visitorCurrent = 1;
                }
                this.queryData()
            },

            // 订单当前页
            currentChangeHandle(val) {
                if (!this.listType) {
                    this.orderCurrent = val;
                } else {
                    this.visitorCurrent = val;
                }
                this.queryData()
            },
        }

    }
</script>

<style scoped>
    .table >>> .el-table__fixed-right, .table >>> .el-table__fixed {
        height: 100% !important;
    }
</style>
