<template>
    <div class="notification">
        <el-dialog
                class="notification"
                :title="title"
                :visible.sync="dialogVisible"
                width="900px"
                :append-to-body="true"
                :close-on-click-modal="false"
                :before-close="beforeClose">

            <uploader
                    :browse_button="triggerId"
                    :url="this.$globalUrl() + updateadress"
                    :chunk_size="updateSize"
                    :multi_selection="true"
                    :max_retries="3"
                    :filters="myfilters"
                    :QueueChanged="queueChanged"
                    :FilesAdded="filesAdded"
                    :BeforeUpload="beforeUpload"
                    :Error="error"
                    :headers="myHeaders"
                    :UploadComplete="uploadComplete"
                    :FileUploaded="fileUploaded"
                    :UploadProgress="uploadProgress"
                    @inputUploader="inputUploader"
            />
            <div v-show="numb !=3" id="495" class="updata-trigger">
                <div style="margin: 30px 0">
                    <!--                <div class="el-icon-plus updata-icon-size"></div>-->
                    <img class="updata-icon-size" src="../../../../assets/img/addupdata.png">
                    <div class="update-condition">{{`支持格式：${allowFiles.length ? allowFiles.join(',') :
                        '全部'}格式文件；单个文件${allowFileSize ? '限'+ allowFileSize + 'MB' : '不限大小'}；${allowFileNumber ? '限'+
                        allowFileNumber + '个' : '不限'}文件。`}}
                    </div>
                </div>
            </div>
            <div v-show="numb ==3" class="flex_b_c">
                <div id="151" @click="threeType(1)" class="updata-trigger2">
                    <div style="margin: 30px 0">
                        <img class="updata-icon-size" src="../../../../assets/img/addupdata.png">
                        <div>高模</div>
                        <div class="update-condition">{{`支持格式：rar、stl、7z、zip格式文件；单个文件${allowFileSize ? '限'+
                            allowFileSize + 'MB' : '不限大小'}；${allowFileNumber ? '限'+
                            allowFileNumber + '个' : '不限'}文件。`}}
                        </div>
                    </div>
                </div>
                <div id="276" @click="threeType(2)" class="updata-trigger2">
                    <div style="margin: 30px 0">
                        <img class="updata-icon-size" src="../../../../assets/img/addupdata.png">
                        <div>中模</div>
                        <div class="update-condition">{{`支持格式：rar、stl、7z、zip格式文件；单个文件${allowFileSize ? '限'+
                            allowFileSize + 'MB' : '不限大小'}；${allowFileNumber ? '限'+
                            allowFileNumber + '个' : '不限'}文件。`}}
                        </div>
                    </div>
                </div>
                <div id="348" @click="threeType(3)" class="updata-trigger2">
                    <div style="margin: 30px 0">
                        <img class="updata-icon-size" src="../../../../assets/img/addupdata.png">
                        <div>低模</div>
                        <div class="update-condition">{{`支持格式：rar、stl、7z、zip格式文件；单个文件${allowFileSize ? '限'+
                            allowFileSize + 'MB' : '不限大小'}；${allowFileNumber ? '限'+
                            allowFileNumber + '个' : '不限'}文件。`}}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <!--上传展示-->
                <div>
                    <!--                &lt;!&ndash;没有上传文件&ndash;&gt;-->
                    <!--                <div v-if="files.length==0" class="text_center m_b2" style="margin-top: 60px">-->
                    <!--                    <img src="../../../assets/img/upload.png">-->
                    <!--                </div>-->
                    <!--有上传文件-->
                    <div class="contentBox">
                        <div class="flex_b_c item" v-for="(item, index) in files" :key="index">
                            <div class="uploadImg">
                                <img src="../../../../assets/img/Scheme.png"
                                     v-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'png'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'jpg'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'gif'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'bmp'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'jpeg'
                            ">
                                <img src="../../../../assets/img/audioIcon.png"
                                     v-else-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'mp3'
                            ">
                                <img src="../../../../assets/img/vudioIcon.png"
                                     v-else-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'mp4'
                            ">
                                <img src="../../../../assets/img/zipIcon.png"
                                     v-else-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'zip'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'rar'
                                 || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == '7z'
                            ">
                                <img src="../../../../assets/img/xlsIcon.png"
                                     v-else-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'xls'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'xlsx'
                            ">
                                <img src="../../../../assets/img/word.png"
                                     v-else-if="item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'doc'
                                || item.name.substring(item.name.indexOf('.')+1).toLowerCase() == 'docx'
                            ">
                                <img src="../../../../assets/img/document.png"
                                     v-else>
                            </div>

                            <div class="jinngdutiao">
                                <el-row>
                                    <el-col :span="8">
                                        <div class="omit">{{item.name}}</div>
                                    </el-col>
                                    <el-col :span="3" class="text_right"
                                            v-if="item.status === 2|| item.status === 1 && item.percent > 0">
                                        <el-button type="text" v-if="!uploading" icon="el-icon-video-play"
                                                   @click="uploadStart()"></el-button>
                                        <el-button type="text" icon="el-icon-video-pause" @click="uploadStop(index)"
                                                   v-else></el-button>
                                    </el-col>
                                    <el-col :span="4" class="text_left"
                                            v-if="item.status === 2|| item.status === 1 && item.percent > 0">{{rate}}
                                    </el-col>
                                    <el-col :span="8" class="text_center">
                                        <span v-if="item.status === -1">正在计算MD5</span>
                                        <span v-if="item.status === 1 && item.percent === 0">等待上传</span>
                                        <span v-if="item.status === 4" style="color: brown">上传失败</span>
                                        <span v-if="item.status === 5" style="color: #009900">上传成功</span>
                                    </el-col>
                                    <el-col style="text-align: right" :span="8">
                                        <el-button
                                                style="margin-right: 20px"
                                                v-if="item.status === 4 || item.status === 5"
                                                size="small" @click="deleteFile(item.id,index)" type="primary">删除
                                        </el-button>
                                        <div class="el-icon-error quxiaoShngChuan"
                                                v-if="item.status == 2 || item.status === 1" @click="deleteFile(item.id,index,1)">
                                        </div>
                                    </el-col>

                                </el-row>
                                <el-progress class="m_t1"
                                             text-color="#ffffff"
                                             v-if="item.status === 2 && !percentflag  || item.status === 1 && item.percent > 0 && !percentflag "
                                             :text-inside="true" :stroke-width="15"
                                             :percentage="item.percent"></el-progress>
                                <el-progress class="m_t1"
                                             text-color="#ffffff"
                                             v-if="item.status === 2 && percentflag || item.status === 1 && percentflag && item.type.substring(item.type.indexOf('|')+1)>0"
                                             :text-inside="true" :stroke-width="15"
                                             :percentage="percentUp"></el-progress>
                            </div>
<!--                            <el-button-->
<!--                                    v-if="item.status == 2 || item.status === 1"-->
<!--                                    size="small" style="margin-left: 20px;box-sizing: border-box;" @click="deleteFile(item.id,index,1)" type="primary">取消上传-->
<!--                            </el-button>-->
                        </div>
                    </div>
                </div>
            </div>
            <div style="text-align:center">
                <el-button size="small" type="primary" @click="complete()">完成</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import FileMd5 from '@/utils/file-md5'
    import Uploader from "@/components/Upload/Uploader"; // 导入上传组件
    export default {
        name: "uploadPopup",
        components: {Uploader},
        props: {
            allowFiles: { //限定上传文件的类型 自定义
                type: Array, //类型
                default: () => [], //默认值
                required: false,  //是否必填
            },
            allowFileSize: { //单个文件大小
                type: Number,
                default: 0, //默认值,MB
                required: false,  //是否必填
            },
            allowFileNumber: { //上传数量
                type: Number,
                default: 0, //默认值,
                required: false,  //是否必填
            },
            fileList: { //是否有上传记录
                type: Array, //类型
                default: () => [], //默认值
                required: true,  //是否必填
            }
        },
        data() {
            return {
                title: '文件上传',
                dialogVisible: false,
                triggerId: ['151', '276', '348', '495'],//触发上传
                num4: 3,
                updateadress: 'xiangxiu-common/file/plUpload', //上传后缀
                updateSize: '50MB',//分片上传文件时，每片文件被切割成的大小，当该值为0时表示不使用分片上传功能
                myfilters: { //单个上传配置
                    // mime_types: [{title: "Imagefiles", extensions: ''}],//限定上传文件的类型
                    // max_file_size: '2GB', //最大只能上传2GB的文件
                    prevent_duplicates: true //不允许选取重复文件},
                },
                up: {},
                files: [], //上传文件列表
                errList: [],//不合格文件列表
                repeatList: [],//重复文件列表
                incorrectFormat: [],//格式不合格列表
                fileSizeList: [],//大小不合格
                phoyoSizeList: [],//图片尺寸
                listRefresh: false, //判断数据是否刷新过
                resolvingPower:'', //限制分辨率

                percent: 0,
                percentflag: false,
                percentUp: 0,
                percentSize: 0,
                eDirectoryName: "",
                tableData: [],
                rate: "",
                uploading: false,
                myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
                numb: '',//区分列表 1图片，2音频
                threeTypeId: '',//区分高中低模
                queueCadfahanged: 0,
                uploadPath: 'guided',
                fileLength:'', //上传的所有文物
            };
        },
        mounted() {
            this.percentUp = 0;
        },
        methods: {
            //关闭
            beforeClose() {
                // this.$confirm('确认关闭？关闭后您上传的数据将会被删除!')
                //     .then(_ => {
                this.up.splice(0, this.up.length)
                this.dialogVisible = false;
                // })
                // .catch(_ => {
                // });
            },

            threeType(id) {
                console.log(id)
                this.threeTypeId = id;
            },

            //完成
            complete() {
                let afileList = this.fileList
                let fiList = this.tableData.filter(item => item.file.status == 5)
                let list = [...fiList, ...afileList]
                this.$emit('updatalist', [list, this.numb])
                this.up.splice(0, this.up.length)
                this.uploading = true;
                this.dialogVisible = false;
                this.tableData = [];
            },

            //判断两个数组是否有相同的数据
            selectArry(arry1, item) {
                let aff = false;
                arry1.forEach(item2 => {
                    if (item.name == item2.fileName) {
                        aff = true;
                    }
                })
                return aff
            },

            //判断文件格式
            selectRepeat(item) {
                let repeat = false;
                let filename = item.name
                let suffix = filename.substring(filename.lastIndexOf(".") + 1);
                let suf = suffix.toLowerCase()
                if (this.allowFiles.length) {
                    if (this.allowFiles.indexOf(suf) == -1) {
                        repeat = true;
                    }
                }
                return repeat
            },

            //判断数据大小是否合格
            selectSize(item) {
                let size = false;
                if (this.allowFileSize) {
                    if (this.allowFileSize * 1024 * 1024 < item.size) {
                        size = true;
                    }
                }
                return size
            },

            //三维文件上传数量判断
            threeNumm() {
                let threenum1 = false;
                let num1 = this.tableData.filter(item => item.modelType == this.threeTypeId).length
                let num = this.fileList.filter(item => item.modelType == this.threeTypeId).length
                if (num1 + num > this.allowFileNumber) {
                    threenum1 = true;
                }
                return threenum1;
            },

            //获取图片比例
            getSize(item, width, height) {
                let that = this;
                return new Promise((resolve, reject) => {
                    let url = window.URL.createObjectURL(item.getNative())
                    let img = new Image();
                    img.src = url;
                    img.onload = function () {
                        console.log(img.width)
                        console.log(img.height)
                        if (width == img.width && height == img.height){
                            resolve({
                                data: false,
                            })
                        }else {
                            resolve({
                                data: true,
                            })
                        }
                    }
                })
            },

            queueChanged(uploader) {
                if (this.numb != 3) {
                    this.fileLength = uploader.files
                    if (this.fileLength.length + this.fileList.length > this.allowFileNumber && this.allowFileNumber) {
                        this.up.splice(this.allowFileNumber - uploader.files.length - this.fileList.length, this.fileLength.length);
                        this.$message.error('只能上传' + this.allowFileNumber + '个文件');
                        this.queueCadfahanged = 1;
                    }
                }
            },

            //当文件添加到上传队列后触发
            filesAdded(up, files) {
                this.percent = 0;
                files.forEach((f, index) => {
                        // f.levelType = this.eleId
                        if (f.name.substring(0, f.name.lastIndexOf(".")).length <= 80) {
                            let filename = f.name
                            var suffix = filename.substring(filename.lastIndexOf(".") + 1);
                            var suf = suffix.toLowerCase()
                            if (f.status == 4) {
                                return;
                            }
                            f.status = -1;
                            let fileSize = f.size / 1024 / 1024
                            let modelType = 0;
                            if (this.numb == 3) {
                                modelType = this.threeTypeId
                            }
                            this.tableData.push({
                                fileName: f.name,
                                file: f,
                                fileFormat: suf,
                                fileShow: 0,
                                fileSize: fileSize.toFixed(2) * 1,
                                modelType: modelType,
                            })
                            FileMd5(f.getNative(), (e, md5) => {
                                    f["md5"] = md5;
                                    f.status = 1;

                                    //格式判断
                                    if (this.selectRepeat(f)) {
                                        this.$message.error("文件" + f.name + "不符合格式！");
                                        f.status = 4;
                                    }

                                    //大小判断
                                    if (this.selectSize(f)) {
                                        this.$message.error("文件" + f.name + '超过' + this.allowFileSize + "M！");
                                        f.status = 4;
                                    }

                                    //文件是否重复
                                    if (this.selectArry(this.fileList, f)) {
                                        this.$message.error('文件:' + f.name + '重复');
                                        f.status = 4;
                                    }

                                console.log(files)
                                    //三维文件数量判断
                                    if (this.numb == 3) {
                                        if (this.threeNumm(files) && this.allowFileNumber) {
                                            this.$message.error('只能上传' + this.allowFileNumber + '个文件');
                                            f.status = 4;
                                        }
                                    } else { //其它文件上传
                                        //分辨率判断
                                        if (this.resolvingPower){
                                            let power = this.resolvingPower.split('*')
                                            this.getSize(f, power[0], power[1]).then(data => {
                                                if (data.data) {
                                                    this.$message.error('文件' + f.name + '尺寸错误，需要上传 '+ this.resolvingPower +'尺寸的图片');
                                                    f.status = 4;
                                                }
                                            })
                                        }

                                        //文件数量判断，（筛选前面没有上传成功的文件）
                                        if (this.queueCadfahanged == 1) {
                                            let allFileLength = this.fileLength.length
                                            if (allFileLength == files.length){
                                                //一次上传的情况
                                                if (index + 1 > this.allowFileNumber) {
                                                    f.status = 4;
                                                }
                                            }else {
                                                //分几次上传的情况
                                                let num = allFileLength - files.length //前几次上传的文件数量
                                                let errFileList = this.fileLength.filter(item=>{return item.status == 4}).length //上传失败的文件数量
                                                console.log(errFileList)
                                                if (index + 1 + num - errFileList > this.allowFileNumber) {
                                                    f.status = 4;
                                                }
                                            }
                                        }
                                    }

                                    //开始上传
                                    setTimeout(() => {
                                        this.uploadStart();
                                    }, 200)
                                }
                            );
                        } else {
                            this.$message.error("文件名过长");
                            this.up.removeFile(f);
                        }
                    }
                )
                ;
            },

            //开始上传
            uploadStart() {
                this.up.start();
                this.uploading = true;
            },

            //暂停上传
            uploadStop(index) {
                this.percentSize = this.files[index].percent
                this.up.stop();
                this.uploading = false;
            },

            //当队列中的某一个文件正要开始上传前触发
            beforeUpload(up, file) {
                up.setOption("multipart_params", {
                    "size": file.size,
                    "md5": file.md5,
                    'fileType': this.numb,
                    'uploadPath': this.uploadPath,
                });
            }
            ,

            //当发生错误时触发
            error(uploader, errObject) {
                this.uploading = false;
                switch (errObject.code) {
                    case -100:
                        this.$message.error("上传失败");
                        break;
                    case -200:
                        this.$message.error("http网络错误");
                        break;
                    case -300:
                        this.$message.error("发生磁盘读写错误时的错误代码，例如本地上某个文件不可读");
                        break;
                    case -400:
                        this.$message.error("发生因为安全问题而产生的错误");
                        break;
                    case -500:
                        this.$message.error("初始化时发生错误");
                        break;
                    case -600:
                        this.$message.error("选择的文件大于2GB，请重新选择");
                        break;
                    case -601:
                        this.$message.error("选择的文件类型不符合要求");
                        break;
                    case -602:
                        this.$message.error("选取了重复的文件");
                        break;
                    case -700:
                        this.$message.error("图片格式错误");
                        break;
                    case -702:
                        this.$message.error("文件大小超过系统所能处理的最大值");
                        break;
                    default:
                        this.$message.error("内存错误");
                }
            }
            ,

            //当上传队列中所有文件都上传完成后触发
            uploadComplete() {
                this.uploading = false;
            }
            ,

            //当队列中的某一个文件上传完成后触发
            fileUploaded(uploader, file, responseObject) {
                this.percentSize = 0;
                let obj = JSON.parse(responseObject.response)
                console.log(obj)
                if (obj.status) { //上传成功
                    if (this.numb == 3) {
                        //三维文件
                        this.tableData.forEach(item => {
                            if (item.fileName == file.name) {
                                this.$set(item, 'decompresses', obj.data.threeRar)
                            }
                        })
                    } else {
                        this.tableData.forEach(item => {
                            if (item.fileName == file.name) {
                                this.$set(item, 'imgUrl', obj.data.urlConvet)
                            }
                        })
                    }
                } else { //上传失败
                    this.files.map(item => {
                        console.log(item)
                        console.log(file.name)
                        if (item.name === file.name) {
                            item.status = 4;
                            console.log(item)
                        }
                    })
                    console.log(file.name)
                    this.$message.error(obj.msg)
                }
            }
            ,

            //会在文件上传过程中不断触发，可以用此事件来显示上传进度
            uploadProgress(uploader, file) {
                this.bytesPerSec = this.up.total.bytesPerSec //文件大小
                if (this.bytesPerSec < 0.1 * 1024) {                            //小于0.1KB，则转化成B
                    this.rate = this.bytesPerSec.toFixed(2) + "B/s"
                } else if (this.bytesPerSec < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
                    this.rate = (this.bytesPerSec / 1024).toFixed(2) + "KB/s"
                } else if (this.bytesPerSec < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
                    this.rate = (this.bytesPerSec / (1024 * 1024)).toFixed(2) + "MB/s"
                } else {                                            //其他转化成GB
                    this.rate = (this.bytesPerSec / (1024 * 1024 * 1024)).toFixed(2) + "GB/s"
                }

                if (file.size < 104857600) {
                    this.percent = file.percent;
                    if (file.percent > 95){
                        file.percent = 95
                    }
                } else {
                    this.percent = file.percent;
                    if (file.percent > 95){
                        file.percent = 95
                    }
                    let uploaded = file.type
                    this.percentflag = file.type * 1000 / 1000 > 0
                    console.log(uploaded)
                    if (uploaded > 0) {
                        let sjs = Math.round(Math.random() * 3);
                        let p = (uploaded * 1000 / 1000) + sjs;
                        console.log(p)
                        if (p >= 100) {
                            file.type = 99
                            this.percentUp = 99;
                        } else if (p < 100) {
                            file.type = p
                            this.percentUp = p
                        }
                    }
                }
            },

            inputUploader(up) {
                this.up = up;
                this.files = up.files;
            }
            ,

            //删除上传数据 num1为取消上传
            deleteFile(id, index,num) {
                if (num == 1){
                    this.percentUp = 0;
                }
                let file = this.up.getFile(id);
                this.percentSize = 0
                this.tableData.splice(index, 1);    //删除数组中的该条数据
                this.up.removeFile(file);
                this.uploadStart()
            },

        },
    }
    ;
</script>

<style scoped>
    .updata-trigger {
        text-align: center;
        width: 100%;
        border-radius: 5px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(-45deg, #C0C0C0 0, #C0C0C0 0.3em, #fff 0, #fff 0.6em);
        /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    }

    .updata-trigger2 {
        text-align: center;
        width: 30%;
        padding: 10px;
        border-radius: 5px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(-45deg, #C0C0C0 0, #C0C0C0 0.3em, #fff 0, #fff 0.6em);
        /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    }

    .updata-icon-size {
        width: 56px;
    }

    .update-condition {
        font-size: 12px;
    }

    .contentBox{
        padding: 0 10px;
        height: 400px;
        overflow-y: auto;
    }

    .contentBox .delBtn {
        position: absolute;
        right: -10px;
        top: -10px;
    }

    .contentBox .item {
        width: 98%;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        background-color: #fff;
        box-shadow: 0px 0px 6px #cecece;
        margin: 20px 5px 40px 5px;
        padding: 10px 0;
        position: relative;
    }

    .item >>> .el-button.el-button--text {
        padding: 0;
    }

    .uploadImg {
        flex: 0;
        width: 100px;
        padding-right: 20px;
        margin:0 20px;
        border-right: 1px solid #cecece;
    }

    .uploadImg img {
        width: 50px;
    }

    .omit{
        width: 100px;
    }

    .jinngdutiao {
        flex: 1;
    }

    .fileName {
        margin-bottom: 10px;
        padding-right: 40px;
    }

    .quxiaoShngChuan{
        position: absolute;
        font-size: 18px;
        top: -26px;
        right: -4px;
    }

    .titlee {
        width: 20%;
        overflow: hidden; /* 超出一行文字自动隐藏 */
        text-overflow: ellipsis; /* 文字隐藏后添加省略号 */
        white-space: nowrap; /* 强制不换行 */
    }


    /*.notification >>> .el-dialog {*/
    /*    height: 550px !important;*/
    /*    overflow-y: auto !important;*/
    /*}*/
</style>
