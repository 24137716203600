<template>
    <div id="comprehensive">
        <div class="topBox">
            <div class="title1">湘绣后台综合数据看板</div>
        </div>
        <div class="centerBox">
            <div class="flex_b_c">
                <div class="smallFrame">
                    <div class="title">今日数据</div>
                    <div class="chunkBox">
                        <div class="item">
                            <div class="num">{{toDayMake}}</div>
                            <div>今日入馆（次）</div>
                        </div>
                        <div class="item item2">
                            <div class="num">{{toCountMake}}</div>
                            <div>累计入馆（次）</div>
                        </div>
                        <div class="item item3">
                            <div class="num">{{serverOrder}}</div>
                            <div>今日线下服务订单数</div>
                        </div>
                        <div class="item item4">
                            <div class="num">{{activeNum}}</div>
                            <div>今日活动场次</div>
                        </div>
                    </div>
                </div>
                <div class="bigFrame">
                    <div class="title">入馆统计</div>
                    <div class="chartTit">近一年入馆（次）</div>
                    <div ref="barChart1" style="width: 100%; height: 11rem"></div>
                </div>
                <div class="smallFrame">
                    <div class="title">入馆人员性别占比（近一年）</div>
                    <div class="pieChartBox flex_b_c">
                        <div class="pieChartInfo">
                            <div class="flex_l_c item" v-for="(item, index) in sexArr" :key="index">
                                <div :class="`dot${index}`"></div>
                                <div class="scaleFontSize10 name">{{item.name}}</div>
                                <div :class="`num${index}`">{{item.value}}</div>
                            </div>
                        </div>
                        <div class="pieBox">
                            <div class="pieChart">
                                <div ref="pieChart1" style="width: 100%; height: 12rem"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex_b_c">
                <div class="smallFrame">
                    <div class="title">线下服务订单统计</div>
                    <div class="chartTit">近一年线下服务订单数</div>
                    <div ref="barChart2" style="width: 100%; height: 11rem"></div>
                </div>
                <div class="bigFrame">
                    <div class="title">活动发布情况</div>
                    <div class="chartTit">近一年活动发布场次</div>
                    <div ref="lineChart1" style="width: 100%; height: 12rem"></div>
                </div>
                <div class="smallFrame">
                    <div class="title">各省参观预约人数统计（近一年）</div>
                    <div class="rankListBox">
                        <div class="tableTh flex_b_c">
                            <div>排序</div>
                            <div>省份</div>
                            <div>人数（人）</div>
                            <div>占比</div>
                        </div>
                        <vue-seamless-scroll :data="rankList" class="seamless-warp" :class-option="classOption">
                            <div class="tableTd flex_b_c" v-for="(item, index) in rankList" :key="item.id">
                                <div class="sortNum">{{ index + 1 }}</div>
                                <div class="omit">
                                    <a class="tableTd" href="javascript:;" :title="item.name">{{ item.name }}</a>
                                </div>
                                <div>{{ item.value }}</div>
                                <div>{{ item.percentage }}</div>
                            </div>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vueSeamlessScroll from "vue-seamless-scroll";

    export default {
        components: {vueSeamlessScroll},
        computed: {
            classOption() {
                return {
                    step: 0.2, // 数值越大速度滚动越快
                    limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                };
            },
        },
        data() {
            return {
                toCountMake: '',
                toDayMake: '',
                serverOrder: '',
                activeNum: '',
                sexArr: [],
                colorOption: ["#2773E6", "#00E4FF"],
                xAxisOption: {
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        color: "#fff",
                        fontSize: 10,
                        // rotate: -45,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    data: [],
                },
                yAxisOption: {
                    type: 'value',
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        color: "#fff",
                        fontSize: 10,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#1B3276',
                        }
                    },
                },
                gridOption: {
                    top: 15,
                    left: 20,
                    right: 20,
                    bottom: 35,
                    containLabel: true,
                },
                legendOption: {
                    selectedMode: false,
                    itemHeight: 8,
                    itemWidth: 8,
                    textStyle: {
                        fontSize: 10,
                        color: "#fff",
                    },
                    itemGap: 22,
                    bottom: 6,
                    data: [],
                },
                // 排行榜
                rankList: [],
            }
        },
        mounted() {
            this.getToday();
            this.sysStatisticsMakeDetail();
            this.sysStatisticsSex();
            this.sysStatisticsAddress();
            this.sysStatisticsServerDetail();
            this.sysStatisticsActivity();
        },
        methods: {
            // 今日数据
            getToday() {
                this.$axios(this.api.order.sysStatisticsMakeDetailToDay).then((data) => {
                    if (data.status) {
                        this.toDayMake = data.data.toDayMake;
                        this.toCountMake = data.data.toCountMake;
                    }
                });
                this.$axios(this.api.order.sysStatisticsServerOrderToDay).then((data) => {
                    if (data.status) {
                        this.serverOrder = data.data;
                    }
                });
                this.$axios(this.api.order.sysStatisticsActivityToDay).then((data) => {
                    if (data.status) {
                        this.activeNum = data.data;
                    }
                });
            },
            // 入馆统计
            sysStatisticsMakeDetail() {
                this.$axios(this.api.order.sysStatisticsMakeDetail).then((data) => {
                    if (data.status) {
                        this.barChart1(data.data);
                    }
                });
            },
            // 入馆人员性别占比（近一年）
            sysStatisticsSex() {
                this.$axios(this.api.order.sysStatisticsSex).then((data) => {
                    if (data.status) {
                        this.pieChart1(data.data.sexCount);
                        this.sexArr = data.data.percent
                    }
                });
            },

            // 线下服务订单统计
            sysStatisticsServerDetail() {
                this.$axios(this.api.order.sysStatisticsServerDetail).then((data) => {
                    if (data.status) {
                        let days = []
                        data.data.days.map(item => {
                            days.push(item.split('-')[1] + '月')
                        })
                        this.$set(data.data, 'days', days)
                        this.barChart2(data.data);
                    }
                });
            },

            // 活动发布情况
            sysStatisticsActivity() {
                this.$axios(this.api.order.sysStatisticsActivity).then((data) => {
                    if (data.status) {
                        this.lineChart1(data.data);
                    }
                });
            },

            // 各省参观预约人数统计（近一年）
            sysStatisticsAddress() {
                this.$axios(this.api.order.sysStatisticsAddress).then((data) => {
                    if (data.status) {
                        this.rankList = data.data
                    }
                });
            },

            // 入馆统计
            barChart1(data) {
                let barChart1 = this.$echarts.init(this.$refs.barChart1);
                this.legendOption.data = ["入馆人次"];
                let xAxisOption = this.xAxisOption;
                xAxisOption.data = data.days;
                let option = {
                    tooltip: {},
                    color: this.colorOption,
                    legend: this.legendOption,
                    grid: this.gridOption,
                    xAxis: xAxisOption,
                    yAxis: this.yAxisOption,
                    series: [
                        {
                            name: this.legendOption.data[0],
                            type: 'bar',
                            barWidth: 6,
                            itemStyle: {
                                barBorderRadius: [10, 10, 0, 0],
                            },
                            data: data.number,
                        }
                    ]
                }
                barChart1.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    barChart1.resize();
                });
            },
            // 线下服务订单统计
            barChart2(data) {
                let barChart2 = this.$echarts.init(this.$refs.barChart2);
                this.legendOption.data = ["线下服务订单数"];
                let xAxisOption = this.xAxisOption;
                xAxisOption.data = data.days;
                let option = {
                    tooltip: {},
                    color: this.colorOption,
                    legend: this.legendOption,
                    grid: this.gridOption,
                    xAxis: xAxisOption,
                    yAxis: this.yAxisOption,
                    series: [
                        {
                            name: this.legendOption.data[0],
                            type: 'bar',
                            barWidth: 6,
                            itemStyle: {
                                barBorderRadius: [10, 10, 0, 0],
                            },
                            data: data.number,
                        }
                    ]
                }
                barChart2.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    barChart2.resize();
                });
            },
            // 入馆人员性别占比（近一年）
            pieChart1(data) {
                let that = this;
                let pieChart1 = this.$echarts.init(this.$refs.pieChart1);
                let option = {
                    tooltip: {},
                    series: [
                        {
                            type: 'pie',
                            hoverAnimation: false,
                            radius: [20, 82],
                            center: ['47%', '52%'],
                            roseType: 'radius',
                            label: {
                                show: false
                            },
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        //自定义颜色
                                        var colorList = [
                                            {
                                                c1: "#00E4FF",
                                                c2: "#008D9E",
                                            },
                                            {
                                                c1: "#1953AB",
                                                c2: "#2773E6",
                                            },
                                            {
                                                c1: "#ECD118",
                                                c2: "#9B890D",
                                            },
                                            {
                                                c1: "#A8A8A8",
                                                c2: "#FFFFFF",
                                            }
                                        ];
                                        return new that.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                            {
                                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                                offset: 0,
                                                color: colorList[params.dataIndex].c1,
                                            },
                                            {
                                                offset: 1,
                                                color: colorList[params.dataIndex].c2,
                                            },
                                        ]);
                                    },
                                },
                            },
                            data: data
                        }
                    ]
                }
                pieChart1.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    pieChart1.resize();
                });
            },
            // 活动发布情况
            lineChart1(data) {
                let lineChart1 = this.$echarts.init(this.$refs.lineChart1);
                this.legendOption.data = ["活动场次"];
                let xAxisOption = this.xAxisOption;
                xAxisOption.data = data.days;
                let legendOption = this.legendOption;
                legendOption.bottom = 20;
                let gridOption = this.gridOption;
                gridOption.bottom = 50;
                let option = {
                    tooltip: {},
                    color: this.colorOption,
                    legend: legendOption,
                    grid: gridOption,
                    xAxis: xAxisOption,
                    yAxis: this.yAxisOption,
                    series: [
                        {
                            type: 'line',
                            name: this.legendOption.data[0],
                            data: data.number,
                            areaStyle: {
                                color: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00E4FF' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#0D1D4D' // 100% 处的颜色
                                    }],
                                    globalCoord: false // 缺省为 false
                                }
                            },
                            smooth: true,
                            itemStyle: {
                                borderColor: "#00E4FF"
                            },
                            lineStyle: {
                                width: 1,
                                color: "#00E4FF",
                            },
                        }
                    ]
                }
                lineChart1.setOption(option);
                // 图表自适应
                window.addEventListener("resize", function () {
                    lineChart1.resize();
                });
            },
        },
    }
</script>

<style scoped>

</style>
