<template>
    <div class="page">
        <div class="query-form p_b1">
            <div class="m_b1">
                <span class="fontSize14">下单时间</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="orderCreateTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间" style="width: 26%">
                </el-date-picker>
                <el-select class="m_r1" clearable v-model="form.activityId" filterable placeholder="请选择活动" size="small"
                           style="width: 15%" @change="queryByCourseSession()">
                    <el-option
                            v-for="item in activityOpt"
                            :key="item.activityId"
                            :label="item.name"
                            :value="item.activityId">
                    </el-option>
                </el-select>
                <el-input class="m_r1" size="small" v-model="form.userName" maxlength="50"
                          placeholder="请输入联系人/报名人姓名（限50字）" clearable style="width: 28.5%"></el-input>
            </div>
            <div>
                <span class="fontSize14">签到时间</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="signTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间" style="width: 26%">
                </el-date-picker>
                <el-select placeholder="活动类型选择" clearable size="small" v-model="form.convention" class="m_r1"
                           style="width: 15%">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('activityType')">
                    </el-option>
                </el-select>
                <el-input class="m_r1" size="small" v-model="form.userCard" maxlength="18"
                          placeholder="请输入报名人身份证号码" clearable style="width: 14%"></el-input>
                <el-input class="m_r1" size="small" v-model="form.userPhone" maxlength="11"
                          placeholder="请输入联系人手机号" clearable style="width: 14%"></el-input>
                <el-button type="primary" @click="queryData(1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </div>
        </div>
        <div class="bg-white">
            <el-menu :default-active="form.isReturn" mode="horizontal" @select="selTab">
                <el-menu-item index="0">全部</el-menu-item>
                <el-menu-item index="1">退票</el-menu-item>
            </el-menu>
            <el-button class="exportBtn" @click="exportData" v-if="hasPermission('activity:applyManagement:export')"
                       size="small" type="primary">导出
            </el-button>
            <el-select class="m_r1 m_t1" clearable v-model="form.activityCourseSessionId" filterable
                       placeholder="请选择活动场次"
                       size="small" style="width: 30%" @change="queryData(1)" @focus="sessionFocus">
                <el-option
                        v-for="item in activityCourseSessionOpt"
                        :key="item.id"
                        :label="item.startTime + '~'+item.endTime"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-select class="m_t1" clearable v-model="form.isSign" placeholder="请选择" size="small"
                       style="width: 20%" @change="queryData(1)">
                <el-option
                        v-for="item in $dictUtils.getDictList('signIn')"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-table :data="list"
                      v-loading="loading"
                      size="small" ref="orderTable"
                      height="calc(100vh - 395px)" class="table">
                <el-table-column prop="activityName" label="活动名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="convention" label="活动类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("activityType", scope.row.convention ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="actiServiceObj" label="活动类别" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("actiServiceObj", scope.row.actiServiceObj ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="联系人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="userPhone" label="联系电话" show-overflow-tooltip></el-table-column>
                <el-table-column prop="activityCourseSessionTime" label="场次" show-overflow-tooltip></el-table-column>
                <el-table-column prop="signIn" label="签到" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("signIn",scope.row.signIn,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="countMoney" label="价格（元）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="下单时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="signTime" label="签到时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="120" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                v-if="hasPermission('activity:applyManagement:ticket') && scope.row.payState == 1 && scope.row.signIn != 1"
                                icon="el-icon-finished" type="text" size="mini" @click="refund(scope.row)">退款
                        </el-button>
                        <el-button v-if="hasPermission('activity:applyManagement:view')" icon="el-icon-view" type="text"
                                   size="mini" @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="text_center"
                           :current-page="form.current"
                           :page-size="form.size"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="total"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="退款" :visible.sync="visible" width="60%" :close-on-click-modal="false">
            <div class="refundTit m_b1">请选择取消活动的人员</div>
            <div class="refundTips m_b1">原路退回（5个工作日内退款到原支付方）</div>
            <div class="b_d p_all1">
                <el-checkbox-group v-model="refundUserList">
                    <el-checkbox class="userInfo m_b1" v-for="item in userList" :key="item.applyDetailId"
                                 :label="item.applyDetailId" :disabled="item.payState != 1">
                        {{item.activityApplyVisitorUser}} <span class="p_r1 p_l1">{{item.activityApplyCardType}}</span>
                        {{item.activityApplyVisitorCard}}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <el-pagination
                    :current-page="refundForm.current"
                    :page-size="refundForm.size"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="refundTotal"
                    @size-change="sizeChangeHandleRefund"
                    @current-change="currentChangeHandleRefund"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
            </span>
        </el-dialog>
        <el-dialog title="退款提示" :visible.sync="visibleTips" width="40%" :close-on-click-modal="false">
            <div class="m_b2">逾期退款需要收取 20% 的退款服务费，是否收取？</div>
            <div class="m_b1 m_l1">
                <el-radio v-model="gatherType" label="0">是，收取20%退款服务费</el-radio>
            </div>
            <div class="m_b1 m_l1">
                <el-radio v-model="gatherType" label="1">否，全额退款</el-radio>
            </div>
            <div class="m_b1 m_l1">
                <el-radio v-model="gatherType" label="2">自定义退款金额</el-radio>
            </div>
            <div class="refundAmountBox" v-if="gatherType == 2">
                <div class="flex_l_c">
                    <el-input size="mini" v-model="refundAmount" maxlength="6" @input="priceChange()"
                              style="width: 30%;"></el-input>
                    （元）
                </div>
                <div class="tips">大于0元，且不超过活动实付款金额</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visibleTips = false">取消</el-button>
                <el-button size="small" type="primary" @click="submitRefund()" v-noMoreClick>提交</el-button>
            </span>
        </el-dialog>
        <ApplyDetails ref="applyDetails"></ApplyDetails>
    </div>
</template>

<script>
    import ApplyDetails from './applyDetails'

    export default {
        components: {ApplyDetails},
        data() {
            return {
                orderCreateTime: [],
                signTime: [],
                form: {
                    activityCourseSessionId: '',
                    activityId: '',
                    current: 1,
                    size: 10,
                    isReturn: '0',
                    orderCreateStartTime: '',
                    orderCreateEndTime: '',
                    signStartTime: '',
                    signEndTime: '',
                    userCard: '',
                    userName: '',
                    userPhone: '',
                    isSign: '',
                    convention: '',
                },
                activityOpt: [],
                activityCourseSessionOpt: [],
                list: [],
                loading: true,
                total: 0,
                // 退款
                visible: false,
                userList: [],
                refundUserList: [],
                refundTotal: 0,
                refundForm: {
                    applyId: '',
                    current: 1,
                    size: 10,
                },
                // 退款提示
                visibleTips: false,
                gatherType: '0',
                refundAmount: '',
                refundPrice: '',
            }
        },
        watch: {
            'gatherType'() {
                this.refundAmount = ''
            },
        },
        mounted() {
            this.queryActivityinfo()
            this.queryData(1)
        },
        methods: {
            // 查询数据
            queryData(current) {
                if (current) {
                    this.form.current = current
                }
                let param = JSON.parse(JSON.stringify(this.form))
                param.orderCreateStartTime = this.orderCreateTime ? this.orderCreateTime[0] : ''
                param.orderCreateEndTime = this.orderCreateTime ? this.orderCreateTime[1] : ''
                param.signStartTime = this.signTime ? this.signTime[0] : ''
                param.signEndTime = this.signTime ? this.signTime[1] : ''
                this.$axios(this.api.activity.sysGetActivityApply, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                        this.$nextTick(() => {
                            this.$refs.orderTable.doLayout();
                        })
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // tab切换
            selTab(index) {
                this.form.isReturn = index
                this.queryData(1)
            },
            // 查询活动
            queryActivityinfo() {
                this.$axios(this.api.activity.activityinfo).then((res) => {
                    if (res.status) {
                        this.activityOpt = res.data
                    }
                })
            },
            // 场次聚焦
            sessionFocus() {
                if (!this.form.activityId) {
                    this.$message('请先选择活动后才有对应的活动场次');
                }
            },
            // 场次查询
            queryByCourseSession() {
                this.$axios(this.api.activity.queryByCourseSession, {activityId: this.form.activityId}).then((res) => {
                    if (res.status) {
                        this.activityCourseSessionOpt = res.data
                    }
                })
            },
            // 导出
            exportData() {
                window.open(this.$globalUrl() + this.api.activitymanagement.exPortList + '?activityCourseSessionId=' + this.form.activityCourseSessionId + '&activityId=' + this.form.activityId + '&isReturn=' + this.form.isReturn + '&orderCreateEndTime=' + this.form.orderCreateEndTime + '&orderCreateStartTime=' + this.form.orderCreateStartTime + '&signEndTime=' + this.form.signEndTime + '&signStartTime=' + this.form.signStartTime + '&userCard=' + this.form.userCard + '&userName=' + this.form.userName + '&userPhone=' + this.form.userPhone + '&isSign=' + this.form.isSign + '&convention=' + this.form.convention)
            },
            // 查看
            view(row) {
                this.$refs.applyDetails.init(row)
            },
            // 退款
            refund(row) {
                console.log(row);
                this.refundForm.applyId = row.applyId
                this.refundPrice = row.promotionPrice || row.activityPrice
                this.refundForm.current = 1
                this.refundForm.size = 10
                this.refundUserList = []
                this.userList = []
                this.visible = true
                this.getList()
            },
            // 获取退款列表
            getList() {
                this.$axios(this.api.activity.getSysActivityApplyDetailById, this.refundForm).then((res) => {
                    if (res.status) {
                        this.userList = res.data.records
                        this.refundTotal = parseInt(res.data.total)
                    }
                })
            },
            // 提交
            submit() {
                if (this.refundUserList.length == 0) {
                    this.$message('请选择退款人员');
                    return
                }
                let flag = false
                this.userList.map(item => {
                    if (this.refundUserList.includes(item.applyDetailId) && item.activityApplyVisitorState == 3) {
                        flag = true
                    }
                })
                if (flag && this.refundPrice > 0) {
                    this.visibleTips = true
                    this.gatherType = '0'
                } else {
                    this.returnActivityApplyDetail()
                }
            },
            // 提交退款
            submitRefund() {
                if (this.gatherType == 2 && this.refundAmount == '') {
                    this.$message.error('请输入价格');
                    return
                }
                if (this.gatherType == 2 && !this.validate.isPrice(this.refundAmount)) {
                    this.$message.error('请输入正确的价格');
                    return
                }
                this.returnActivityApplyDetail(1)
            },
            returnActivityApplyDetail(type) {
                let p = {activityApplyDetailIds: this.refundUserList}
                if(type == 1) {
                    p.returnMoney = this.refundAmount
                    p.returnType = this.gatherType
                }
                this.$axios(this.api.activity.returnActivityApplyDetail, p, 'post').then((res) => {
                    if (res.status) {
                        this.$message.success('退款成功');
                        this.visible = false
                        this.visibleTips = false
                        this.queryData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 重置
            resetSearch() {
                this.orderCreateTime = []
                this.signTime = []
                this.form = {
                    activityCourseSessionId: '',
                    activityId: '',
                    current: 1,
                    size: 10,
                    isReturn: 0,
                    orderCreateStartTime: '',
                    orderCreateEndTime: '',
                    signStartTime: '',
                    signEndTime: '',
                    userCard: '',
                    userName: '',
                    userPhone: '',
                }
                this.queryData(1)
            },
            // 价格修改
            priceChange() {
                let value = this.refundAmount.replace(/[^\d^\.]+/g, '')
                if (value > this.refundPrice) {
                    this.refundAmount = this.refundPrice
                } else {
                    this.refundAmount = value
                }
            },
            // 每页数
            sizeChangeHandle(val) {
                this.form.size = val;
                this.form.current = 1;
                this.queryData()
            },

            // 当前页
            currentChangeHandle(val) {
                this.form.current = val;
                this.queryData()
            },

            // 每页数
            sizeChangeHandleRefund(val) {
                this.refundForm.size = val;
                this.refundForm.current = 1;
                this.getList()
            },

            // 当前页
            currentChangeHandleRefund(val) {
                this.refundForm.current = val;
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .bg-white {
        position: relative;
    }

    .exportBtn {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    .refundTit {
        font-size: 16px;
    }

    .refundTips {
        font-size: 12px;
        color: #b3b3b3;
    }

    .userInfo {
        display: block;
    }

    .refundAmountBox {
        margin-left: 34px
    }

    .tips {
        margin-top: 10px;
        font-size: 14px;
        color: #9c9c9c;
    }
</style>
