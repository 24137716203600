<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="21">
                <el-input size="small" v-model="searchForm.bannerTitle" placeholder="banner标题" maxlength="50"
                          class="m_r1" style="width: 14%" clearable></el-input>
                <el-select size="small" v-model="searchForm.terrace" placeholder="请选择" class="m_r1"
                           style="width: 12%" clearable>
                    <el-option
                            v-for="item in terraceOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-select size="small" v-model="searchForm.state" placeholder="请选择启用状态" class="m_r1" style="width: 13%"
                           clearable>
                    <el-option
                            v-for="item in $dictUtils.getDictList('public_enable')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker class="m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="termOfValidity"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="有效期开始日期"
                                end-placeholder="有效期结束日期" style="width: 28%">
                </el-date-picker>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="3" class="text_right">
                <el-button type="primary" v-if="hasPermission('banner:add')" @click="addEdit(null, 0)" size="small">新增
                </el-button>
                <el-button type="primary" v-if="hasPermission('banner:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="bannerImageUrl" label="服务图片" width="110">
                    <template slot-scope="scope">
                        <el-image class="infoImg" :src="scope.row.bannerImageUrl" fit="contain">
                            <el-image slot="placeholder" fit="contain" class="infoImg"
                                      :src="require('../../../assets/img/zhanwei.png')"></el-image>
                            <el-image slot="error" fit="contain" class="infoImg"
                                      :src="require('../../../assets/img/zhanwei.png')"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="启用状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="bannerTitle" label="标题" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="bannerHref" label="链接地址" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sort" label="排序" show-overflow-tooltip></el-table-column>
                <el-table-column prop="termOfValidityStart" label="有效期" width="180" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.termOfValidityStart}} <span v-if="scope.row.termOfValidityStart">~</span>
                        {{scope.row.termOfValidityEnd}}
                    </template>
                </el-table-column>
                <el-table-column prop="terrace" label="发布平台" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.terrace == 0 ? '官网':'小程序'}}
                    </template>
                </el-table-column>
                <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="添加时间" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('banner:edit')" type="text" size="mini"
                                   @click="addEdit(scope.row, 1)">编辑
                        </el-button>
                        <el-button v-if="hasPermission('banner:delete')" :disabled="scope.row.state == '0'" type="text"
                                   size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <AddForm ref="addForm" @refreshDataList="queryData"></AddForm>
    </div>
</template>

<script>
    import AddForm from './addForm'

    export default {
        components: {AddForm},
        data() {
            return {
                termOfValidity: [],
                searchForm: {
                    bannerTitle: '',
                    terrace: '',
                    state: '',
                    termOfValidityStart: '',
                    termOfValidityEnd: '',
                    current: 1,
                    size: 10,
                },
                terraceOpt: [
                    {
                        value: '0',
                        label: '官网'
                    },
                    {
                        value: '1',
                        label: '小程序'
                    }
                ],
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.termOfValidityStart = this.termOfValidity ? this.termOfValidity[0] : ''
                param.termOfValidityEnd = this.termOfValidity ? this.termOfValidity[1] : ''
                this.$axios(this.api.website.websitebanner, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            checkSelectable(row) {
                return row.state == '1'
            },
            // 重置
            resetSearch() {
                this.termOfValidity = []
                this.searchForm = {
                    bannerTitle: '',
                    terrace: '',
                    state: '',
                    termOfValidityStart: '',
                    termOfValidityEnd: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.website.websitebannerUpdateById, row, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },
            // 新增、编辑 row:数据；method：0新增，1编辑
            addEdit(row, method) {
                this.$refs.addForm.init(row, method)
            },
            // 查看
            view(row) {
                this.$refs.addForm.init(row)
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.website.batchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>
    .infoImg {
        width: 100px;
        height: 100px;
    }
</style>
