<template>
    <div class="leftRightLayout page">
        <!--<div class="leftLayout">
            <div class="leftTitle">
                <el-input
                        placeholder="请输入关键字过滤"
                        size="small"
                        clearable
                        v-model="filterText">
                </el-input>
            </div>
            <div class="el-scrollbar">
                <div class="el-scrollbar__wrap">
                    <el-tree :data="officeTreeData" v-loading="treeLoading"
                             :props="{
                                    value: 'id',             // ID字段名
                                    label: 'name',         // 显示名称
                                    children: 'children'    // 子级字段名
                                 }"
                             node-key="id"
                             default-expand-all
                             :filter-node-method="filterNode"
                             :expand-on-click-node="false"
                             highlight-current
                             @node-click="handleNodeClick" ref="officeTree">
                        <span class="span-ellipsis" slot-scope="{ node }">
		                    <span :title="node.label">{{ node.label }}</span>
	                    </span>
                    </el-tree>
                </div>
            </div>
        </div>-->
        <div class="rightLayout">
            <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                     @keyup.enter.native="refreshList()" @submit.native.prevent>
                <el-form-item prop="name">
                    <el-input size="small" v-model="searchForm.name" placeholder="姓名" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh()" size="small" icon="el-icon-search">查询</el-button>
                    <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                </el-form-item>
            </el-form>
            <el-dialog title="导入Excel" :visible.sync="isImportCollapse">
                <el-form size="small" :inline="true" ref="importForm">
                    <el-form-item>
                        <el-button type="default" @click="downloadTpl()" size="small">下载模板</el-button>
                    </el-form-item>
                    <el-form-item prop="loginName">
                        <el-upload
                                class="upload-demo"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :show-file-list="true">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">只允许导入“xls”或“xlsx”格式文件！</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <div class="bg-white top">
                <el-row>
                    <el-button v-if="hasPermission('sys:user:add')" type="primary" size="small" icon="el-icon-plus"
                               @click="add()">新建
                    </el-button>
                    <el-button v-if="hasPermission('sys:user:del')" type="danger" size="small" icon="el-icon-delete"
                               @click="del()"
                               :disabled="dataListSelections.length <= 0" plain>删除
                    </el-button>
                    <el-button-group class="f_r">
<!--                        <el-tooltip class="item" effect="dark" content="导入" placement="top">-->
<!--                            <el-button v-if="hasPermission('sys:user:import')" type="default" size="small"-->
<!--                                       icon="el-icon-upload2" title="导入"-->
<!--                                       @click="isImportCollapse = !isImportCollapse"></el-button>-->
<!--                        </el-tooltip>-->
<!--                        <el-tooltip class="item" effect="dark" content="导出" placement="top">-->
<!--                            <el-button v-if="hasPermission('sys:user:export')" type="default" size="small"-->
<!--                                       icon="el-icon-download" title="导出" @click="exportExcel()"></el-button>-->
<!--                        </el-tooltip>-->
                        <el-tooltip class="item" effect="dark" content="刷新" placement="top">
                            <el-button
                                    type="default"
                                    size="small"
                                    icon="el-icon-refresh"
                                    @click="refreshList">
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </el-row>
                <el-table
                        :data="dataList"
                        v-loading="loading"
                        @selection-change="selectionChangeHandle"
                        @sort-change="sortChangeHandle"
                        size="small"
                        height="calc(100% - 80px)"
                        class="table">
                    <el-table-column
                            type="selection"
                            width="50">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            sortable
                            show-overflow-tooltip
                            label="姓名">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" v-if="hasPermission('sys:user:edit')"
                                     @click="edit(scope.row)">{{scope.row.name}}
                            </el-link>
                            <el-link type="primary" :underline="false" v-else-if="hasPermission('sys:user:view')"
                                     @click="view(scope.row)">{{scope.row.name}}
                            </el-link>
                            <span v-else>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="photo"
                            label="头像">
                        <template slot-scope="scope">
                            <el-avatar size="small" :src="scope.row.image"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="sex"
                            label="性别">
                        <template slot-scope="scope">
                            {{scope.row.sex == 0 ? "保密" : (scope.row.sex == 1 ? "男":"女")}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="mobilePhone"
                            label="手机号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="telPhone"
                            label="电话" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="email"
                            label="邮箱" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            width="200"
                            label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="hasPermission('sys:user:view')" type="text" size="small"
                                       icon="el-icon-view" @click="view(scope.row)">查看
                            </el-button>
                            <el-button v-if="hasPermission('sys:user:edit')" type="text" size="small"
                                       icon="el-icon-edit" @click="edit(scope.row)">修改
                            </el-button>
                            <el-button v-if="hasPermission('sys:user:del')" type="text" size="small"
                                       icon="el-icon-delete" @click="del(scope.row.id)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <!-- 弹窗, 新增 / 修改 -->
            <user-form ref="userForm" @refreshDataList="refreshList"></user-form>
        </div>
    </div>
</template>

<script>
    import UserForm from './UserForm'

    export default {
        data() {
            return {
                searchForm: {
                    name: '',
                    loginName: '',
                },
                isShow: true,
                filterText: '',
                dataList: [],
                officeTreeData: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                orderBy: '',
                dataListSelections: [],
                isImportCollapse: false,
                loading: false,
                treeLoading: false
            }
        },
        components: {
            UserForm
        },
        activated() {
            this.refreshTree()
            this.refreshList()
        },
        watch: {
            filterText(val) {
                this.$refs.officeTree.filter(val)
            }
        },
        methods: {
            filterNode(value, data) {
                if (!value) return true
                return data.name.indexOf(value) !== -1
            },
            refresh(){
               this.currentChangeHandle(1)
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.auth.sysuserQueryAllList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'orderBy': this.orderBy,
                    ...this.searchForm
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            refreshTree() {
                /*this.treeLoading = true;
                this.$axios(this.api.sys.officeTreeData, {useable: '1'}, 'get').then(data => {
                    this.officeTreeData = data.treeData
                    this.treeLoading = false;
                })*/
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 排序
            sortChangeHandle() {
                this.refreshList()
            },
            // 新增
            add() {
                this.$refs.userForm.init('add', '')
            },
            // 修改
            edit(row) {
                this.$refs.userForm.init('edit', row)
            },
            // 查看
            view(row) {
                this.$refs.userForm.init('view', row)
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.auth.sysuserRemove, {'ids': ids}, 'delete').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg);
                            this.refreshList();
                        }
                    })
                })
            },
            // 下载模板
            downloadTpl() {
                this.$utils.download('/sys/user/import/template')
            },
            handleSuccess(res) {
                if (res.success) {
                    this.$message.success({
                        dangerouslyUseHTMLString: true,
                        message: res.msg
                    })
                } else {
                    this.$message.error(res.msg)
                }
            },
            handleNodeClick(data) {
                this.refreshList()
            },
            beforeUpload(file) {
                const isExcel = file.name.indexOf('.xls') >= 0
                const isLt2M = file.size / 1024 / 1024 < 10

                if (!isExcel) {
                    this.$message.error('只能上传excel文件!')
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 10MB!')
                    return false
                }
                return true
            },
            exportExcel() {
                this.$utils.download('/sys/user/export')
            },
            resetSearch() {
                this.searchForm.name = ''
                this.pageNo = 1
                this.refreshList()
            }
        }
    }
</script>
