/**
 * 获取页面链接参数
 * @param {*} str 参数名称
 */
export function QueryString(str) {
  var sValue = window.location.href.match(new RegExp("[\?\&]" + str + "=([^\&]*)(\&?)", "i"));
  return sValue ? sValue[1] : sValue;
}

/**
 * 是否有权限
 * @param {*} key
 */
export function hasPermission (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
export function recover (target, source) {
  if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
  var to = Object(target)
  if (source === undefined || source === null) { return to }
  var keysArray = Object.keys(Object(target))
  for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
    var nextKey = keysArray[nextIndex]
    var desc = Object.getOwnPropertyDescriptor(target, nextKey)
    if (desc !== undefined && desc.enumerable) {
      if (to[nextKey] instanceof Array) {
        to[nextKey] = source[nextKey]
      } else if (to[nextKey] instanceof Object) {
        recover(to[nextKey], source[nextKey])
      } else if (source[nextKey] !== undefined) {
        to[nextKey] = source[nextKey]
      } else if (typeof (to[nextKey]) === 'string') {
        to[nextKey] = ''
      } else {
        to[nextKey] = undefined
      }
    }
  }
  return to
}

export function download (url, params = {}) {
  this.$axios(url, params, 'get', 'blob').then(data => {
    if (data.status) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([response.data]))
      link.target = '_blank'
      let filename = response.headers['content-disposition']
      link.download = decodeURI(filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  })
}

export default {hasPermission, recover, download, QueryString}
