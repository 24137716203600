import { render, staticRenderFns } from "./recycleDetails.vue?vue&type=template&id=4fe320ac&scoped=true"
import script from "./recycleDetails.vue?vue&type=script&lang=js"
export * from "./recycleDetails.vue?vue&type=script&lang=js"
import style0 from "./recycleDetails.vue?vue&type=style&index=0&id=4fe320ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe320ac",
  null
  
)

export default component.exports