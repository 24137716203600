// 订单管理
let order = {
    updateByTicketingPromptConfig: 'xiangxiu-ticketing/ticketingpromptconfig/updateByTicketingPromptConfig', // 修改预约内容
    getByTicketingPromptConfig: 'xiangxiu-ticketing/ticketingpromptconfig/getByTicketingPromptConfig', // 获取预约内容配置

    getByTicketingMakeConfig: 'xiangxiu-ticketing/ticketingmakeconfig/getByTicketingMakeConfig', // 获取预约规则配置
    updateByTicketingMakeConfig: 'xiangxiu-ticketing/ticketingmakeconfig/updateByTicketingMakeConfig', // 修改预约规则配置

    getTicketingStorehouseConfig: 'xiangxiu-ticketing/ticketingstorehouseconfig/getTicketingStorehouseConfig', // 获取票仓配置
    saveTicketingStorehouseConfig: 'xiangxiu-ticketing/ticketingstorehouseconfig/saveTicketingStorehouseConfig', // 保存票仓配置规则

    getByDayTicketingStorehouse: 'xiangxiu-ticketing/ticketingstorehouse/getByDayTicketingStorehouse', // 获取指定日期票仓数据
    buildDiyTicketingStorehouse: 'xiangxiu-ticketing/ticketingstorehouse/buildDiyTicketingStorehouse', // 指定日期创建票仓数据
    restoreDefaultTicketingStorehouse: 'xiangxiu-ticketing/ticketingstorehouse/restoreDefaultTicketingStorehouse', // 指定日期恢复默认票仓
    getByYearsTicketingStorehouse: 'xiangxiu-ticketing/ticketingstorehouse/getByYearsTicketingStorehouse', // 指定月份获取票仓数据

    getByYearsTicketingClose: 'xiangxiu-ticketing/ticketingclose/getByYearsTicketingClose', // 指定月份获取闭馆数据
    importTicketingclose: 'xiangxiu-ticketing/ticketingclose/import', // excel导入开馆、闭馆日期
    saveOpenTicketing: 'xiangxiu-ticketing/ticketingclose/saveOpenTicketing', // 新增开馆数据
    saveTicketingClose: 'xiangxiu-ticketing/ticketingclose/saveTicketingClose', // 新增闭馆数据

    sysGetTicketingMakeDetail: 'xiangxiu-ticketing/ticketingmake/sysGetTicketingMakeDetail', // 后台参观列表
    sysGetTicketingMake: 'xiangxiu-ticketing/ticketingmake/sysGetTicketingMake', // 后台订单列表
    sysGetTicketingMakeById: 'xiangxiu-ticketing/ticketingmake/sysGetTicketingMakeById', // 后台根据预约ID获取详情
    sysGetTicketingBlack: 'xiangxiu-ticketing/ticketingblack/sysGetTicketingBlack', // 黑名单列表
    addBlackDay: 'xiangxiu-ticketing/ticketingblack/addBlackDay', // 黑名单加时长
    releaseTicketingBlack: 'xiangxiu-ticketing/ticketingblack/releaseTicketingBlack', // 黑名单释放
    getBaseMakeBlack: 'xiangxiu-ticketing/basemakeblack/getBaseMakeBlack', // 获取黑名单配置
    basemakeblackSave: 'xiangxiu-ticketing/basemakeblack/save', // 保存黑名单配置
    sysGetTicketingBlackById: 'xiangxiu-ticketing/ticketingblack/sysGetTicketingBlackById', // 黑名单详情
    sysGetTicketingBreakAppointment: 'xiangxiu-ticketing/ticketingbreakappointment/sysGetTicketingBreakAppointment', // 后台查看爽约记录
    sysGetTicketingBreakAppointmentDetail: 'xiangxiu-ticketing/ticketingbreakappointment/sysGetTicketingBreakAppointmentDetail', // 爽约记录查看详情
    downLoadTempExcel: 'xiangxiu-ticketing/ticketingclose/downLoadTempExcel', // 下载闭馆日期模版
    sysGetTicketingServer: 'xiangxiu-ticketing/ticketingserver/sysGetTicketingServer', // 后台&小程序查询服务列表
    sysGetTicketingServerById: 'xiangxiu-ticketing/ticketingserver/sysGetTicketingServerById', // 后台&小程序查询服务详情
    allNumberOfPeople: 'xiangxiu-ticketing/ticketingnumberofpeople/allNumberOfPeople', // 获取所有人数
    allAge: 'xiangxiu-ticketing/ticketingage/allAge', // 获取所有年龄
    allLanguage: 'xiangxiu-ticketing/ticketinglanguage/allLanguage', // 获取所有语言
    sysSaveTicketingServer: 'xiangxiu-ticketing/ticketingserver/sysSaveTicketingServer', // 后台编辑保存服务
    sysGetTicketingNumberOfPeople: 'xiangxiu-ticketing/ticketingnumberofpeople/sysGetTicketingNumberOfPeople', // 后台人数类型列表
    saveTicketingnumberofpeople: 'xiangxiu-ticketing/ticketingnumberofpeople/sysSave',
    updateTicketingnumberofpeopleById: 'xiangxiu-ticketing/ticketingnumberofpeople/sysUpdateById',
    batchRemoveTicketingNumberOfPeople: 'xiangxiu-ticketing/ticketingnumberofpeople/batchRemoveTicketingNumberOfPeople',
    sysGetTicketingLanguage: 'xiangxiu-ticketing/ticketinglanguage/sysGetTicketingLanguage',
    saveTicketinglanguage: 'xiangxiu-ticketing/ticketinglanguage/sysSave',
    updateTicketinglanguageById: 'xiangxiu-ticketing/ticketinglanguage/sysUpdateById',
    batchRemoveTicketingLanguage: 'xiangxiu-ticketing/ticketinglanguage/batchRemoveTicketingLanguage',
    sysGetTicketingAge: 'xiangxiu-ticketing/ticketingage/sysGetTicketingAge',
    saveTicketingage: 'xiangxiu-ticketing/ticketingage/sysSave',
    updateTicketingageById: 'xiangxiu-ticketing/ticketingage/sysUpdateById',
    batchRemoveTicketingAge: 'xiangxiu-ticketing/ticketingage/batchRemoveTicketingAge',
    sysGetTicketingServerOrder: 'xiangxiu-ticketing/ticketingserverorder/sysGetTicketingServerOrder',
    sysGetTicketingServerOrderById: 'xiangxiu-ticketing/ticketingserverorder/sysGetTicketingServerOrderById',
    updateTicketingServerState: 'xiangxiu-ticketing/ticketingserver/updateTicketingServerState',
    sysGetTicketingMakeTeam: 'xiangxiu-ticketing/ticketingmaketeam/sysGetTicketingMakeTeam',
    getTicketingMakeTeamById: 'xiangxiu-ticketing/ticketingmaketeam/getTicketingMakeTeamById',
    getTicketingMakeDetailById: 'xiangxiu-ticketing/ticketingmaketeam/getTicketingMakeDetailById',
    ticketingMakeTeamToExamine: 'xiangxiu-ticketing/ticketingmaketeam/ticketingMakeTeamToExamine',
    // 大数据看板
    sysStatisticsMakeDetailToDay: 'xiangxiu-ticketing/ticketingmakedetail/sysStatisticsMakeDetailToDay',
    sysStatisticsServerOrderToDay: 'xiangxiu-ticketing/ticketingserverorderdetail/sysStatisticsServerOrderToDay',
    sysStatisticsActivityToDay: 'xiangxiu-activity/activityinfo/sysStatisticsActivityToDay',
    sysStatisticsMakeDetail: 'xiangxiu-ticketing/ticketingmakedetail/sysStatisticsMakeDetail',
    sysStatisticsSex: 'xiangxiu-ticketing/ticketingmakedetail/sysStatisticsSex',
    sysStatisticsAddress: 'xiangxiu-ticketing/ticketingmakedetail/sysStatisticsAddress',
    sysStatisticsServerDetail: 'xiangxiu-ticketing/ticketingserverorderdetail/sysStatisticsServerDetail',
    sysStatisticsActivity: 'xiangxiu-activity/activityinfo/sysStatisticsActivity',
    serverOrderExPortList: 'xiangxiu-ticketing/ticketingserverorder/exPortList',
    teamOrderExPortList: 'xiangxiu-ticketing/ticketingmaketeam/exPortList',
    ticketingmakeExPortList: 'xiangxiu-ticketing/ticketingmake/exPortList',
}

let website = {
    websitebanner: 'xiangxiu-website/websitebanner/list',
    batchRemove: 'xiangxiu-website/websitebanner/batchRemove',
    websitebannerSave: 'xiangxiu-website/websitebanner/save',
    websitebannerUpdateById: 'xiangxiu-website/websitebanner/updateById',
    websitequestionnaire: 'xiangxiu-website/websitequestionnaire/list',
    questionBatchRemove: 'xiangxiu-website/websitequestionnaire/batchRemove',
    websitequestionnaireSave: 'xiangxiu-website/websitequestionnaire/save',
    websitequestionnaireGetById: 'xiangxiu-website/websitequestionnaire/getById',
    openWebsiteQuestionnaire: 'xiangxiu-website/websitequestionnaire/openWebsiteQuestionnaire',
    websiteuserquestionnaire: 'xiangxiu-website/websiteuserquestionnaire/list',
    websiteuserquestionnaireGetById: 'xiangxiu-website/websiteuserquestionnaire/getById',
    websitenewscategory: 'xiangxiu-website/websitenewscategory/list',
    websitenewscategorySave: 'xiangxiu-website/websitenewscategory/save',
    websitenewscategoryUpdateById: 'xiangxiu-website/websitenewscategory/updateById',
    websitenewscategoryBatchRemove: 'xiangxiu-website/websitenewscategory/batchRemove',
    websiteannouncementcategory: 'xiangxiu-website/websiteannouncementcategory/list',
    websiteannouncementcategorySave: 'xiangxiu-website/websiteannouncementcategory/save',
    websiteannouncementcategoryUpdateById: 'xiangxiu-website/websiteannouncementcategory/updateById',
    websiteannouncementcategoryBatchRemove: 'xiangxiu-website/websiteannouncementcategory/batchRemove',
    sysGetWebsiteJournalism: 'xiangxiu-website/websitejournalism/sysGetWebsiteJournalism',
    reviewedWebsiteJournalism: 'xiangxiu-website/websitejournalism/reviewedWebsiteJournalism',
    websitejournalismBatchRemove: 'xiangxiu-website/websitejournalism/batchRemove',
    websitejournalismUpdateById: 'xiangxiu-website/websitejournalism/updateById',
    websitejournalismUpdateEnableById: 'xiangxiu-website/websitejournalism/updateEnableById',
    websitejournalismSave: 'xiangxiu-website/websitejournalism/save',
    websitenoticeannouncement: 'xiangxiu-website/websitenoticeannouncement/list',
    websitenoticeannouncementSave: 'xiangxiu-website/websitenoticeannouncement/save',
    reviewedWebsiteNoticeAnnouncement: 'xiangxiu-website/websitenoticeannouncement/reviewedWebsiteNoticeAnnouncement',
    websitenoticeannouncementUpdateById: 'xiangxiu-website/websitenoticeannouncement/updateById',
    websitenoticeannouncementUpdateEnableById: 'xiangxiu-website/websitenoticeannouncement/updateEnableById',
    websitenoticeannouncementBatchRemove: 'xiangxiu-website/websitenoticeannouncement/batchRemove',
    getWebsiteBriefIntroductionAddress: 'xiangxiu-website/websitebriefintroductionaddress/getWebsiteBriefIntroductionAddress',
    speech: 'xiangxiu-website/websitebriefintroductionaddress/saveAddress',
    introduction: 'xiangxiu-website/websitebriefintroductionaddress/saveBriefIntroduction',
    openTime: 'xiangxiu-website/websitebriefintroductionaddress/saveOpenContent',
    visitNotice: 'xiangxiu-website/websitebriefintroductionaddress/saveNoticeToVisitorsContent',
    traffic: 'xiangxiu-website/websitebriefintroductionaddress/saveMassTransit',
    contactUs: 'xiangxiu-website/websitebriefintroductionaddress/saveContactUs',
    regulations: 'xiangxiu-website/websitebriefintroductionaddress/saveNoticeToVisitors',
    teamNotice: 'xiangxiu-website/websitebriefintroductionaddress/saveTicketBookingInstructions',
    ticketingteamtype: 'xiangxiu-ticketing/ticketingteamtype/list',
    batchRemoveTicketingTeamType: 'xiangxiu-ticketing/ticketingteamtype/batchRemoveTicketingTeamType',
    ticketingteamtypeSave: 'xiangxiu-ticketing/ticketingteamtype/save',
    updateTicketingTeamTypeById: 'xiangxiu-ticketing/ticketingteamtype/updateTicketingTeamTypeById',
    websiteworkingpersonneljob: 'xiangxiu-website/websiteworkingpersonneljob/list',
    websiteworkingpersonneljobBatchRemove: 'xiangxiu-website/websiteworkingpersonneljob/batchRemove',
    websiteworkingpersonneljobSave: 'xiangxiu-website/websiteworkingpersonneljob/save',
    websiteworkingpersonneljobUpdateById: 'xiangxiu-website/websiteworkingpersonneljob/updateById',
    websitehistory: 'xiangxiu-website/websitehistory/list',
    websitehistorySave: 'xiangxiu-website/websitehistory/save',
    websitehistoryBatchRemove: 'xiangxiu-website/websitehistory/batchRemove',
    websitehistoryUpdateById: 'xiangxiu-website/websitehistory/updateById',
    websiteworkingpersonnel: 'xiangxiu-website/websiteworkingpersonnel/list',
    websiteworkingpersonnelBatchRemove: 'xiangxiu-website/websiteworkingpersonnel/batchRemove',
    websiteworkingpersonnelSave: 'xiangxiu-website/websiteworkingpersonnel/save',
    websiteworkingpersonnelUpdateById: 'xiangxiu-website/websiteworkingpersonnel/updateById',
    allWebsiteNewsCategory: 'xiangxiu-website/websitenewscategory/allWebsiteNewsCategory',
    allWebsiteAnnouncementCategory: 'xiangxiu-website/websiteannouncementcategory/allWebsiteAnnouncementCategory',
    sysGetCreateUser: 'xiangxiu-website/websitequestionnaire/sysGetCreateUser',
    allWebsiteWorkingPersonnelJob: 'xiangxiu-website/websiteworkingpersonneljob/allWebsiteWorkingPersonnelJob',
    sysGetWebsiteBill: 'xiangxiu-website/websitebill/sysGetWebsiteBill',
    updateApplicationStatus: 'xiangxiu-website/websitebill/updateApplicationStatus',
}

let auth = {
    verifySms: '/xiangxiu-common/tools/sendSmsMessage',
    basesysrole: 'xiangxiu-user/basesysrole/list',
    queryRoleUserList: 'xiangxiu-user/basesysrole/queryRoleUserList',
    basesysroleSave: 'xiangxiu-user/basesysrole/save',
    basesysroleBatchRemove: 'xiangxiu-user/basesysrole/batchRemove',
    getMenus: 'xiangxiu-user/sysuser/getMenus',  // 用户权限查询接口
    getById: 'xiangxiu-user/sysrolemenu/getById',  // 查询角色权限
    sysroleList: 'xiangxiu-user/sysrole/list',  // 角色列表
    sysnewmenuList: 'xiangxiu-user/sysnewmenu/list',  // 查询菜单列表
    sysnewmenuRemoveById: 'xiangxiu-user/sysnewmenu/removeById',  // 删除菜单
    sysnewmenuUpdateById: 'xiangxiu-user/sysnewmenu/updateById',  // 修改菜单信息
    sysnewmenuSave: 'xiangxiu-user/sysnewmenu/save',  // 新增菜单信息
    sysrolemenuSave: 'xiangxiu-user/sysrolemenu/save',  // 角色授权保存
    removeById: 'xiangxiu-user/sysuserrole/removeById',  // 移除角色用户
    sysroleGetById: 'xiangxiu-user/sysrole/getById',  // 查询角色信息
    sysroleUpdateById: 'xiangxiu-user/sysrole/updateById',  // 修改角色信息
    sysroleRemoveById: 'xiangxiu-user/sysrole/removeById',  // 删除角色
    sysroleSave: 'xiangxiu-user/sysrole/save',  // 新增角色
    sysuserQueryAllList: 'xiangxiu-user/sysuser/queryUserList',  // 查询所有用户
    sysuserQueryRoleUserList: 'xiangxiu-user/sysuserrole/queryRoleUserList',  // 查询非角色用户
    getByIdAllUser: 'xiangxiu-user/sysuserrole/getByIdAllUser',  // 根据角色查询用户列表
    sysuserroleSave: 'xiangxiu-user/sysuserrole/save',  // 批量关联角色用户
    login: "xiangxiu-user/login/login", // 登录
    loginout: "xiangxiu-user/login/loginout", // 登出日志记录
    findList: "xiangxiu-common/logmannger/findList",
    filesave: "pdf-service/sysfile/save",
    onlinePreview: "pdf-service/sysfile/upload",
    listFiles: "pdf-service/sysfile/listFiles",
    deleteFile: "pdf-service/sysfile/deleteFile",//删除文件
    sysuserUpdateById: "xiangxiu-user/sysuser/updateById", // 修改用户信息
    sysuserRemove: "xiangxiu-user/sysuser/remove", // 删除用户信息
    sysuserSave: "xiangxiu-user/sysuser/save", // 保存用户
    GetUserById: "xiangxiu-user/sysuser/getById",
    //字典管理
    dictTypeList: "xiangxiu-common/dictType/queryAllByLimitDictType",
    dictQueryById: "xiangxiu-common/dictType/queryByIdDictType",
    dictSave: "xiangxiu-common/dictType/insertDictType",
    updateDictType: "xiangxiu-common/dictType/updateDictType",
    dictDelete: "xiangxiu-common/dictType/deleteDictType",
    getDictMap2: "xiangxiu-common/dictType/getDictMap",


    dictGetDictValue: "xiangxiu-common/dictValue/queryAllDictValue",
    dictQueryDictValue: "xiangxiu-common/dictValue/queryByIdDictValue",
    dictSaveDictValue: "xiangxiu-common/dictValue/insertDictValue",
    updateDictValue: "xiangxiu-common/dictValue/updateDictValue",
    dictDeleteDictValue: "xiangxiu-common/dictValue/deleteByIdDictValue",
    getDictMap: "xiangxiu-common/dictValue/queryByIdDictTypeValue",
    uploadFile: "pdf-service/sysfile/save",


    customColList: "xiangxiu-common/customcol/customColList",
    save: "xiangxiu-common/customcol/save",
}

// 系统模块接口
let sys = {
    getMenus: "user/getMenus", // 用户权限菜单
    menuTreeData2: "menu/treeData2", // 菜单栏数据
    menuTreeData: "menu/treeData", // 菜单栏数据
    menuQueryById: "menu/queryById", // 单个菜单查询
    menuSave: "menu/save", // 单个菜单新增
    menuDelete: "menu/delete", // 单个菜单删除
    dataRuleQuery: "dataRule/queryById", // 数据规则查询
    dataRuleSave: "dataRule/save", // 数据规则保存
    dataRuleList: "dataRule/list", // 数据规则列表
    dataRuleDelete: "dataRule/delete", // 数据规则删除
    dataRuleTreeData: "dataRule/treeData", // 数据规则删除
    officeTreeData: "office/treeData", // 机构管理查询
    officeQueryById: "office/queryById",
    officeSave: "office/save",
    officeDelete: "office/delete",
    roleAssign: "role/assign",
    roleOutrole: "role/outrole",
    roleAssignrole: "role/assignrole",
    roleList: "role/list",
    roleDelete: "role/delete",
    roleQueryById: "role/queryById",
    roleSave: "role/save",
    userQueryById: "user/queryById",
    userList: "user/list",
    userDelete: "user/delete",
    userSave: "user/save",
    userSaveInfo: "user/saveInfo",
    userSavePwd: "user/savePwd",
    dataMine: "log/data/mine",
    postList: "post/list",
    postDelete: "post/delete",
    postQueryById: "post/queryById",
    postSave: "post/save",
    areaTreeData: "area/treeData",
    areaDelete: "area/delete",
    areaQueryById: "area/queryById",
    areaSave: "area/save",
    logList: "xiangxiu-common/clickHouse/clickhouseTest",
}
/**
 * 活动接口
 *
 */
let activity = {
    // 活动报名管理
    sysGetActivityApply: 'activityapply/sysGetActivityApply',
    queryByCourseSession: 'activityinfo/queryByCourseSession',
    activityinfo: 'activityinfo/all',
    sysGetActivityApplyById: 'activityapply/sysGetActivityApplyById',
    getSysActivityApplyDetailById: 'activityapply/getSysActivityApplyDetailById',
    returnActivityApplyDetail: 'activityapplydetail/sysReturnActivityApplyDetail',

    //摆错位置了（活动黑名单的）
    releaseBlack: 'blacklist/releaseBlack/',
    getByIdBlackSession: 'blacklist/getByIdBlackSession',
    getByIdPullBlackDetail: 'blacklist/getByIdPullBlackDetail',
    pullBlackMessage: 'blacklist/pullBlackMessage',
    list: 'balckrule/list',
    batchRuleUpdateById: 'balckrule/batchRuleUpdateById',//修改黑名单规则接口
}

//活动管理接口
let activitymanagement = {
    activityList: "xiangxiu-activity/activityinfo/list",
    modifyState: "xiangxiu-activity/activityinfo/updateByActivityInfo",
    activityDetails: "xiangxiu-activity/activityinfo/backstageGetById/",
    newActivities: "xiangxiu-activity/activityinfo/save",
    modifyActivity: "xiangxiu-activity/activityinfo/updateById",
    seriesList: "xiangxiu-activity/series/list",
    idGetSeriesInformation: "xiangxiu-activity/series/getById/",
    modifySeries: "xiangxiu-activity/series/updateById",
    addSeries: "xiangxiu-activity/series/save",
    tagListRequest: "xiangxiu-activity/label/list",
    newLabel: "xiangxiu-activity/label/save",
    idGetTag: "xiangxiu-activity/label/getById/",
    modifyLabel: "xiangxiu-activity/label/updateById",
    enrollmentManagementList: "xiangxiu-activity/activityapply/list",
    allactivity: "xiangxiu-activity/activityinfo/all",
    delete: 'xiangxiu-activity/activityinfo/removeById/',
    listSeriesActivities: 'xiangxiu-activity/series/queryActivityList',
    teamEnrollmentList: 'xiangxiu-activity/activityapplyteam/list',
    messagePush: 'xiangxiu-common/basemessage/messageContent',
    activitymessagePush: 'xiangxiu-activity/activityinfo/pushMessage',
    getContent: 'xiangxiu-activity/activityinfo/activityLabelSeries',
    backstageGetInfoCount: 'xiangxiu-activity/activityinfo/backstageGetInfoCount',
    exPortList: 'xiangxiu-activity/activityapply/exPortList',
    createSignCode: 'xiangxiu-activity/activityinfo/createSignCode',

    getPreviewMessages: 'xiangxiu-activity/activityinfo/preview',
    pushBanner: 'xiangxiu-activity/activityinfo/pushBanner',
    queryCopy: 'xiangxiu-activity/activityinfo/backstageGetById/',
    blackList: 'xiangxiu-activity/blacklist/list',
    pullBlack: 'xiangxiu-activity/blacklist/pullBlack',
    continueBlackTime: 'xiangxiu-activity/blacklist/continueBlackTime',
    supplementaryBySignature: 'xiangxiu-activity/activityapply/supplementaryBySignature',
    backstageGetById: 'xiangxiu-activity/activityinfo/backstageGetById/',
    singleMessageSave: 'xiangxiu-activity/single/singleMessageSave',
    findActivitySysLogListById: 'xiangxiu-activity/activitysyslog/findActivitySysLogListById',

    updateByIdRelease: 'xiangxiu-activity/activityinfo/updateByIdRelease',
    updateByActivityTop: 'xiangxiu-activity/activityinfo/updateByActivityTop',
    updateByActivityInfo: 'xiangxiu-activity/activityinfo/updateByActivityTop',
}

//H5权限管理
let authority = {
    allRoles: 'xiangxiu-user/baseuserrole/all',//所有角色
    userAssociationList: 'xiangxiu-user/baseuserandrole/list',
    newlyAdded: 'xiangxiu-user/baseuserandrole/save',
    modificationManagement: 'xiangxiu-user/baseuserandrole/updateById',
    roleList: 'xiangxiu-user/baseuserrole/list',
    addRole: 'xiangxiu-user/baseuserrole/save',
    modifyRole: 'xiangxiu-user/baseuserrole/updateById',
    deleteRole: 'xiangxiu-user/baseuserrole/removeById/',
    deleassociated: 'xiangxiu-user/baseuserandrole/removeById/',
}

//证件管理
let registrationMethod = {
    list: 'xiangxiu-common/cardmanagement/list',
    save: 'xiangxiu-common/cardmanagement/save',
    updateById: 'xiangxiu-common/cardmanagement/updateById',
    batchRemove: 'xiangxiu-common/cardmanagement/batchRemove',
    all: 'xiangxiu-common/cardmanagement/all',
    findActivityCardTypeById: 'xiangxiu-common/cardmanagement/findActivityCardTypeById',
    backstageCancelApply: 'xiangxiu-common/activityapply/backstageCancelApply',
}

//文件信息表
let threeDFileControl = {
    list:'xiangxiu-cultural/culturaldetail/selectAllList',
    fileRemoveById:'xiangxiu-cultural/culturalfile/removeById/', //上传文件的删除
    fileSave:'xiangxiu-cultural/culturalfile/save',


    removeById:'xiangxiu-cultural/culturaldetail/removeById/',
    getById:'xiangxiu-cultural/culturaldetail/getHtById/',
    updateById:'xiangxiu-cultural/culturaldetail/updateById',
    updateStateById:'xiangxiu-cultural/culturaldetail/updateStateById',
    save:'xiangxiu-cultural/culturaldetail/save',
    wechatSession:'xiangxiu-user/userTool/wechatSession'
}

//文物类型
let culturalRelics = {
    list:'xiangxiu-cultural/sysculturaldict/selectByType',//查询列表
    selectByTypeAll:'xiangxiu-cultural/sysculturaldict/selectByTypeAll',
    selsetQueryAll:'xiangxiu-cultural/sysculturaldict/queryAll',
    removeById:'xiangxiu-cultural/sysculturaldict/removeById',//删除
    save:'xiangxiu-cultural/sysculturaldict/save',//新增
    updateById:'xiangxiu-cultural/sysculturaldict/updateById',//修改
    getById:'xiangxiu-cultural/sysculturaldict/getById/', //详情
    getZanTingList:'xiangxiu-guided/guidedExhibition/queryAll',
}

//意见反馈
let feedback = {
    list:"xiangxiu-website/websiteFeedback/list",
    getById:"xiangxiu-website/websiteFeedback//getById/",
    removeById:"xiangxiu-website/websiteFeedback/removeById/",
}

//展厅
let exhibitionHall = {
    save:'xiangxiu-guided/guidedExhibition/save',
    list:'xiangxiu-guided/guidedExhibition/queryAllByLimit',
    getById:'xiangxiu-guided/guidedExhibition/getById/',
    updateById: 'xiangxiu-guided/guidedExhibition/updateById',
    removeById:'xiangxiu-guided/guidedExhibition/removeById',
    queryAll:'xiangxiu-guided/exhibitionManagement/queryAll',
    queryByIdWhetherHaveCultural:'xiangxiu-guided/guidedExhibition/queryByIdWhetherHaveCultural',
}

//地图导览
let mapGuide = {
    list:'xiangxiu-cultural/culturalPositionMap/list',
    punctuationList:'xiangxiu-cultural/culturalPositionCoordinate/list',
    mapPhototSave:'xiangxiu-cultural/culturalPositionMap/save',
    mapPhototModify:'xiangxiu-cultural/culturalPositionMap/updateById',
    mapDelete:'xiangxiu-cultural/culturalPositionMap/removeById/',
    getNotPunctuatedFileList:'xiangxiu-cultural/culturaldetail/selectAllByCulturalPosition',
    punctuationSave:'xiangxiu-cultural/culturalPositionCoordinate/save',
    punctuationUpdateById:'xiangxiu-cultural/culturalPositionCoordinate/updateById',
    punctuationDelete:'xiangxiu-cultural/culturalPositionCoordinate/removeById/',
    queryAll:'xiangxiu-guided/exhibitionManagement/queryAll',
    getByIdByExhibitionManagementId:'xiangxiu-guided/guidedExhibition/getByIdByExhibitionManagementId/',
    queryByExhibitionId:'xiangxiu-cultural/culturalPositionMap/queryByExhibitionId',
}

//展览管理
let zhanLanMessage = {
    save:'xiangxiu-guided/exhibitionClassification/save',
    list:'xiangxiu-guided/exhibitionClassification/list',
    getById:'xiangxiu-guided/exhibitionClassification/getById/',
    updateById:'xiangxiu-guided/exhibitionClassification/updateById',
    batchRemove:'xiangxiu-guided/exhibitionClassification/batchRemove',
    saveExhibitionManagement:'xiangxiu-guided/exhibitionManagement/saveExhibitionManagement',
    fenLeiQueryAll:'xiangxiu-guided/exhibitionClassification/queryAll',
    zhanLanList:'xiangxiu-guided/exhibitionManagement/queryAllByLimit',
    queryAllExpire:'xiangxiu-guided/exhibitionManagement/queryAllExpire',
    zhanlanQueryById:'xiangxiu-guided/exhibitionManagement/queryById/',
    zhanLanUpdateById:'xiangxiu-guided/exhibitionManagement/updateById',
    zhanLanRemoveById:'xiangxiu-guided/exhibitionManagement/batchRemove',
    removeById:'xiangxiu-guided/guidedExhibitionFile/removeById/',
}

//小程序地图导览
let minAppMap = {
    save:'xiangxiu-guided/guidedMapGuide/save',
    updateById:'xiangxiu-guided/guidedMapGuide/updateById',
    queryAllByLimit:'xiangxiu-guided/guidedMapGuide/queryAllByLimit',
    getById:'xiangxiu-guided/guidedMapGuide/getById/',
    removeById:'xiangxiu-guided/guidedMapGuide/removeById',
}

//数字产品
let digitalProducts = {
    list:'xiangxiu-website/websitedigitalproducts/list',
    updateById:'xiangxiu-website/websitedigitalproducts/updateById',
    batchRemove:'xiangxiu-website/websitedigitalproducts/batchRemove',
    save:'xiangxiu-website/websitedigitalproducts/save',
    getById:'xiangxiu-website/websitedigitalproducts/getById/',
}

//服务设施
let installation = {
    save:'xiangxiu-website/websiteservicefacilities/save',
    updateById:'xiangxiu-website/websiteservicefacilities/updateById',
    list:'xiangxiu-website/websiteservicefacilities/list',
    batchRemove:'xiangxiu-website/websiteservicefacilities/batchRemove',
    getById:'xiangxiu-website/websiteservicefacilities/getById/',
}

let app = {
    restModels: 'rest/models',
}

// 系统
for (var s in sys) {
    sys[s] = 'sys/' + sys[s];
}
//活动
for (var act in activity) {
    activity[act] = 'xiangxiu-activity/' + activity[act];
}

for (var a in app) {
    app[a] = 'app/' + app[a];
}


export default {
    order,
    auth,
    sys,
    app,
    activity,
    activitymanagement,
    authority,
    registrationMethod,
    threeDFileControl,
    culturalRelics,
    feedback,
    exhibitionHall,
    mapGuide,
    zhanLanMessage,
    minAppMap,
    website,
    digitalProducts,
    installation,
}
