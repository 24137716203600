<template>
    <el-dialog title="活动订单详情" width="70%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="订单信息" :column="2" class="b_b p_b1">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="平台交易号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
                <el-descriptions-item label="支付状态">
                    <el-tag size="small" v-if="info.payState">
                        {{$dictUtils.getDictLabel("order_status",info.payState,'')}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="支付方式">{{$dictUtils.getDictLabel("pay_platform",info.payPlatform,'')}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="活动信息" :column="2" class="b_b p_t2 p_b1">
                <el-descriptions-item label="活动名称">{{info.activityName}}</el-descriptions-item>
                <el-descriptions-item label="活动类型">{{$dictUtils.getDictLabel("activityType",info.convention,'')}}</el-descriptions-item>
                <el-descriptions-item label="活动类别">{{$dictUtils.getDictLabel("actiServiceObj",info.actiServiceObj,'')}}</el-descriptions-item>
                <el-descriptions-item label="活动场次">{{info.activityCourseSessionTime}}</el-descriptions-item>
                <el-descriptions-item label="活动地点">{{info.address}}</el-descriptions-item>
                <el-descriptions-item label="单价（元）">{{info.activityPrice}}</el-descriptions-item>
                <el-descriptions-item label="优惠价（元）">{{info.promotionPrice}}</el-descriptions-item>
                <el-descriptions-item label="数量">{{info.numberOfAppointments}}</el-descriptions-item>
                <el-descriptions-item label="实付（元）">{{info.countMoney}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{info.userName}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.userPhone}}</el-descriptions-item>
            </el-descriptions>
            <div class="el-descriptions__title el-descriptions p_t2 p_b1">活动参加人员</div>
            <el-table :data="list" border size="small" style="width: 100%">
                <el-table-column prop="activityApplyVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="activityApplyCardType" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="activityApplyVisitorCard" label="证件号码" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="activityApplyVisitorState" label="签到状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("signIn",scope.row.activityApplyVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="signTime" label="签到时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.activityApplyVisitorState == 1 ? scope.row.signTime:''}}
                    </template>
                </el-table-column>
                <el-table-column prop="createOutRequestDate" label="退款时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRequestNo" label="退款单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="commission" label="退款服务费" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outMoney" label="退款金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="payState" label="退款状态" show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.payState == 2 || scope.row.payState == 3">
                        {{$dictUtils.getDictLabel("order_status",scope.row.payState,'-')}}
                    </template>
                </el-table-column>
                <el-table-column prop="outRequestDate" label="退款到账时间" width="160" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-pagination
                    :current-page="form.current"
                    :page-size="form.size"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
                form: {
                    applyId: '',
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0
            }
        },
        methods: {
            init(row) {
                this.form.applyId = row.applyId
                this.visible = true
                this.$axios(this.api.activity.sysGetActivityApplyById, {applyId: row.applyId}).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    }
                })
                this.getList()
            },
            getList() {
                this.$axios(this.api.activity.getSysActivityApplyDetailById, this.form).then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.form.size = val;
                this.form.current = 1;
                this.getList()
            },

            // 当前页
            currentChangeHandle(val) {
                this.form.current = val;
                this.getList()
            },
        }
    }
</script>

<style scoped>
    .dialogBox >>> .el-descriptions-item__label {
        white-space: nowrap !important;
    }
    .dialogBox >>> .el-descriptions-item__content {
        padding-right: 40px !important;
    }
</style>
