<template>
    <div class="flex_l_t page bg-white basicSeet">
        <div class="tabBox">
            <el-button :type="activeName == item.id? 'primary':''" v-for="(item, index) in tabs" :key="index" @click="tabSel(item.id)">
                {{item.name}}
            </el-button>
        </div>
        <div class="flex_1 infoBox">
            <div ref="introduction" class="infoItem">
                <div class="m_b2">湘绣简介</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">湘绣简介：</div>
                    <Editor class="flex_1" :editortext="info.briefIntroduction" :disMenus="'video'" ref="briefIntroductionEditor" @changeHtml="briefIntroductionEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('introduction')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="speech" class="infoItem">
                <div class="m_b2">馆长致辞</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">馆长致辞：</div>
                    <Editor class="flex_1" :editortext="info.address" :disMenus="'video'" ref="addressEditor" @changeHtml="addressEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('speech')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="openTime" class="infoItem">
                <div class="m_b2">开放时间</div>
                <div class="p_l2 m_b2">
                    <el-form size="small" :model="info" ref="openTimeForm" label-width="130px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="开放入馆时间：" prop="openTime"
                                              :rules="[{ required: true, message: '请选择开放入馆时间', trigger: 'blur' }]">
                                    <el-time-picker size="small"
                                                    value-format="HH:mm:ss"
                                                    format="HH:mm:ss" :picker-options="openTimeOptions"
                                                    v-model="info.openTime" placeholder="任意时段">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="停止入馆时间：" prop="endTime"
                                              :rules="[{ required: true, message: '请选择停止入馆时间', trigger: 'blur' }]">
                                    <el-time-picker size="small"
                                                    value-format="HH:mm:ss"
                                                    format="HH:mm:ss" :picker-options="endTimeOptions"
                                                    v-model="info.endTime" placeholder="任意时段">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="闭馆时间：" prop="closeTime"
                                              :rules="[{ required: true, message: '请选择闭馆时间', trigger: 'blur' }]">
                                    <el-time-picker size="small"
                                                    value-format="HH:mm:ss"
                                                    format="HH:mm:ss" :picker-options="closeTimeOptions"
                                                    v-model="info.closeTime" placeholder="任意时段">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div class="flex_l_t">
                        <div class="label">内容设置：</div>
                        <Editor class="flex_1" :editortext="info.openContent" :disMenus="'video'" ref="openContentEditor" @changeHtml="openContentEditor"></Editor>
                    </div>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('openTime')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="teamNotice" class="infoItem">
                <div class="m_b2">订票须知（团队）</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">订票须知：</div>
                    <Editor class="flex_1" :editortext="info.ticketBookingInstructions" :disMenus="'video'" ref="ticketBookingInstructionsEditor" @changeHtml="ticketBookingInstructionsEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('teamNotice')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="visitNotice" class="infoItem">
                <div class="m_b2">参观须知</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">参观须知：</div>
                    <Editor class="flex_1" :editortext="info.noticeToVisitorsContent" :disMenus="'video'" ref="noticeToVisitorsContentEditor" @changeHtml="noticeToVisitorsContentEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('visitNotice')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="regulations" class="infoItem">
                <div class="m_b2">游客须知</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">游客须知：</div>
                    <Editor class="flex_1" :editortext="info.noticeToVisitors" :disMenus="'video'" ref="noticeToVisitorsEditor" @changeHtml="noticeToVisitorsEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('regulations')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="contactUs" class="infoItem">
                <div class="m_b2">联系我们</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">联系我们：</div>
                    <Editor class="flex_1" :editortext="info.contactUs" :disMenus="'video'" ref="contactUsEditor" @changeHtml="contactUsEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('contactUs')" v-noMoreClick>提交</el-button>
                </div>
            </div>
            <div ref="traffic" class="infoItem">
                <div class="m_b2">公共交通</div>
                <div class="flex_l_t p_l2 m_b2">
                    <div class="label">公共交通：</div>
                    <Editor class="flex_1" :editortext="info.massTransit" :disMenus="'video'" ref="massTransitEditor" @changeHtml="massTransitEditor"></Editor>
                </div>
                <div class="text_center">
                    <el-button type="primary" size="small" @click="submit('traffic')" v-noMoreClick>提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor';

    export default {
        components: {Editor},

        data() {
            return {
                activeName: 'introduction',
                tabs: [
                    {
                        name: '湘绣简介',
                        id: 'introduction',
                    },
                    {
                        name: '馆长致辞',
                        id: 'speech',
                    },
                    {
                        name: '开放时间',
                        id: 'openTime',
                    },
                    {
                        name: '订票须知（团队）',
                        id: 'teamNotice',
                    },
                    {
                        name: '参观须知',
                        id: 'visitNotice',
                    },
                    {
                        name: '游客须知',
                        id: 'regulations',
                    },
                    {
                        name: '联系我们',
                        id: 'contactUs',
                    },
                    {
                        name: '公共交通',
                        id: 'traffic',
                    }
                ],
                info: {
                    openTime: null,
                    endTime: null,
                    closeTime: null,
                },
                flag: true,
            }
        },
        computed: {
            // 开放入馆时间
            openTimeOptions() {
                return {
                    selectableRange: `00:00:00-${this.info.endTime ? this.info.endTime : (this.info.closeTime ? this.info.closeTime:'23:59:59')}`
                }
            },
            // 停止入馆时间
            endTimeOptions() {
                return {
                    selectableRange: `${this.info.openTime ? this.info.openTime : '00:00:00'}-${this.info.closeTime ? this.info.closeTime : '23:59:59'}`
                }
            },
            // 闭馆时间
            closeTimeOptions() {
                return {
                    selectableRange: `${this.info.endTime ? this.info.endTime : '00:00:00'}-23:59:59}`
                }
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                this.$axios(this.api.website.getWebsiteBriefIntroductionAddress).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    } else {
                        this.$message.error('查询失败');
                    }
                })
            },
            tabSel(id) {
                this.activeName = id
                this.$refs[id].scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            },
            briefIntroductionEditor(data) {
                this.info.briefIntroduction = data
            },
            addressEditor(data) {
                this.info.address = data
            },
            openContentEditor(data) {
                this.info.openContent = data
            },
            ticketBookingInstructionsEditor(data) {
                this.info.ticketBookingInstructions = data
            },
            noticeToVisitorsContentEditor(data) {
                this.info.noticeToVisitorsContent = data
            },
            noticeToVisitorsEditor(data) {
                this.info.noticeToVisitors = data
            },
            contactUsEditor(data) {
                this.info.contactUs = data
            },
            massTransitEditor(data) {
                this.info.massTransit = data
            },
            // 提交
            submit(type) {
                let param = {}
                switch (type) {
                    case "introduction":
                        param = {
                            briefIntroduction: this.info.briefIntroduction
                        }
                        break;
                    case "speech":
                        param = {
                            address: this.info.address
                        }
                        break;
                    case "openTime":
                        param = {
                            openTime: this.info.openTime,
                            endTime: this.info.endTime,
                            closeTime: this.info.closeTime,
                            openContent: this.info.openContent,
                        }
                        break;
                    case "visitNotice":
                        param = {
                            noticeToVisitorsContent: this.info.noticeToVisitorsContent
                        }
                        break;
                    case "contactUs":
                        param = {
                            contactUs: this.info.contactUs
                        }
                        break;
                    case "traffic":
                        param = {
                            massTransit: this.info.massTransit
                        }
                        break;
                    case "teamNotice":
                        param = {
                            ticketBookingInstructions: this.info.ticketBookingInstructions
                        }
                        break;
                    case "regulations":
                        param = {
                            noticeToVisitors: this.info.noticeToVisitors
                        }
                        break;
                }
                if(type == 'openTime') {
                    if(this.info.openTime == null || this.info.endTime == null || this.info.closeTime == null) {
                        this.$message('请完善开放时间');
                        return
                    }
                }
                this.$axios(this.api.website[type], param, 'post').then((res) => {
                    if (res.status) {
                        this.$message.success('提交成功');
                    } else {
                        this.$message.error('提交失败');
                    }
                })
            }
        }
    }
</script>

<style scoped>
    #main .el-main-center .mainCenter .bg-white {
        padding: 0 !important;
    }
    .tabBox {
        height: 100%;
        border-right: 10px solid #ebeef5;
    }
    .label {
        width: 130px;
        text-align: right;
        font-size: 14px;
        color: #606266;
        padding-right: 12px;
    }
    .infoBox {
        background: #ebeef5;
        height: calc(85vh - 10px);
        overflow-y: auto;
    }
    .infoItem {
        background: #ffffff;
        padding: 20px;
        margin-bottom: 10px;
    }
    .infoItem:last-of-type {
        margin-bottom: 0;
    }
    .tabBox .el-button {
        display: block;
        border-radius: 0;
        width: 100%;
    }
    .tabBox .el-button+.el-button {
        margin-left: 0;
    }
</style>
