<template>
    <div class="page formPage" id="originalScript">
        <el-row :gutter="12">
            <el-col :span="4">
                <el-input placeholder="请输入原件/复制件" clearable size="small" v-model="culturalName"></el-input>
            </el-col>
            <el-col :span="2">
                <el-button size="small" icon="el-icon-search" type="primary" @click="getList(1)">查询</el-button>
            </el-col>
            <el-col :span="2">
                <el-button size="small" icon="el-icon-refresh-left" @click="reset()">重置</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right">
                <el-button v-if="hasPermission('culturalMent:originalScript:add')" size="small" type="primary"
                           @click="details(0,2)">新增
                </el-button>
                <el-button v-if="hasPermission('culturalMent:originalScript:delete')" size="small"
                           :disabled="multipleSelection.length == 0" type="primary" @click="Wudelete()">删除
                </el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 90px)"
                @selection-change="handleSelectionChange"
                class="table">
            <el-table-column type="selection" width="50" fixed></el-table-column>
            <el-table-column prop="name" label="原件/复制件" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="enabledState" label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.enabledState"
                            active-value="1"
                            inactive-value="0"
                            @change="stateChange(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="创建用户" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.updateTime | formatDate }}
                </template>
            </el-table-column>
            <el-table-column prop="updateName" label="修改用户" show-overflow-tooltip>
            </el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button
                            v-if="hasPermission('culturalMent:originalScript:see')"
                            icon="el-icon-view" type="text"
                            size="mini" @click="details(scope.row.id,0)">详情
                    </el-button>
                    <el-button
                            v-if="hasPermission('culturalMent:originalScript:edit')"
                            icon="el-icon-edit" type="text"
                            size="mini" @click="details(scope.row.id,1)">编辑
                    </el-button>
                    <el-button
                            v-if="hasPermission('culturalMent:originalScript:delete') && scope.row.enabledState == 0"
                            icon="el-icon-delete" type="text"
                            size="mini" @click="Wudelete(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--详情编辑弹窗-->
        <el-dialog
                :title="distinguish == 0 ? '原件/复制件详情': distinguish == 1 ? '原件/复制件编辑' : '原件/复制件新增'"
                :visible.sync="dialogVisible"
                width="30%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="ruleForm" size="small" :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="名称" prop="name">
                    <el-input maxlength="20" show-word-limit :disabled="!distinguish"
                              v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item prop="enabledState" label="状态">
                    <el-switch
                            :disabled="!distinguish"
                            v-model="ruleForm.enabledState"
                            active-value="1"
                            inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
                <el-form-item v-if="!distinguish" label="创建时间" prop="createTime">
                    <el-date-picker
                            style="width: 100%"
                            :disabled="!distinguish"
                            v-model="ruleForm.createTime"
                            type="datetime"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="!distinguish" label="创建用户" prop="createUser">
                    <el-input :disabled="!distinguish" v-model="ruleForm.userName"></el-input>
                </el-form-item>
                <el-form-item v-if="!distinguish" label="修改时间" prop="updateTime">
                    <el-date-picker
                            style="width: 100%"
                            :disabled="!distinguish"
                            v-model="ruleForm.updateTime"
                            type="datetime"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="!distinguish" label="修改用户" prop="updateUser">
                    <el-input :disabled="!distinguish" v-model="ruleForm.updateName"></el-input>
                </el-form-item>
                <el-form-item style="text-align: center">
                    <el-button v-noMoreClick v-if="distinguish == 1" type="primary" @click="submitForm('ruleForm',0)">
                        保存
                    </el-button>
                    <el-button v-noMoreClick v-if="distinguish == 2" type="primary" @click="submitForm('ruleForm',1)">
                        新增
                    </el-button>
                    <el-button v-if="distinguish" @click="handleClose()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "originalScript",
        data() {
            return {
                culturalName: '',//文物名称
                dataList: [],
                multipleSelection: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                distinguish: 0,
                dialogVisible: false,
                ruleForm: {
                    dictType: '5',
                    name: '',
                    createTime: '',
                    createUser: '',
                    updateTime: '',
                    updateUser: '',
                    enabledState: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                },
                timer: '',
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取列表
            getList(num) {
                this.loading = true;
                if (num) {
                    this.pageNo = 1
                }
                this.$axios(this.api.culturalRelics.list, {
                    name: this.culturalName,
                    current: this.pageNo,
                    size: this.pageSize,
                    type: 5,
                }, 'get').then(res => {
                    this.loading = false;
                    this.dataList = res.data.records;
                    this.total = parseInt(res.data.total)
                })
            },

            reset() {
                this.culturalName = '';
                this.getList()
            },

            //多选
            handleSelectionChange(val) {
                this.multipleSelection = [];
                val.forEach(item => {
                    if (item.enabledState == 0) {
                        this.multipleSelection.push(item.id)
                    }
                })
            },

            //num 0详情, 1编辑,2新增
            details(id, num) {
                if (id) {
                    //获取文件详情
                    this.$axios(this.api.culturalRelics.getById + id, {}, 'get').then(res => {
                        this.ruleForm = res.data;
                    })
                }
                this.distinguish = num;
                this.dialogVisible = true;
            },

            //修改状态
            stateChange(row) {
                this.$axios(this.api.culturalRelics.updateById, {
                    ...row
                }, 'put').then(res => {
                    console.log(res)
                    if (res.status) {
                        this.$message.success('修改成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.getList();
                })
            },

            //删除
            Wudelete(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = []
                    if (id) {
                        ids = [id]
                    } else {
                        ids = this.multipleSelection
                    }
                    this.$axios(this.api.culturalRelics.removeById, {
                        ids: ids.join(',')
                    }, 'delete').then(res => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('删除成功');
                            if (this.dataList.length == ids.length && this.pageNo > 1) this.pageNo--;
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {
                });
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getList();
            },

            //关闭弹窗
            handleClose() {
                this.$refs['ruleForm'].resetFields();
                this.dialogVisible = false;
                this.ruleForm = {
                    name: '',
                    createTime: '',
                    createUser: '',
                    updateTime: '',
                    updateUser: '',
                    enabledState: 0,
                };
            },

            //num 0保存，1新增
            submitForm(formName, num) {
                if (this.ruleForm.name.trim() == '') {
                    this.$message.error('请输入名称')
                    return
                }
                this.ruleForm.dictType = '5';
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (num == 0) { //保存
                            this.$axios(this.api.culturalRelics.updateById, {...this.ruleForm}, 'put').then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$message.success('保存成功')
                                    this.handleClose()
                                    this.getList();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            })
                        } else { //新增
                            this.$axios(this.api.culturalRelics.save, {...this.ruleForm}, 'post').then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$message.success('新增成功')
                                    this.handleClose()
                                    this.getList();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        }
    }
</script>

<style scoped>

</style>
