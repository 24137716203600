import router from '../router'
import axios from 'axios'

let envArr = ['https://api.hnembmusuem.com/'];


// 上传图片地址
export function uploadImgUrl() {
    return 'https://api.hnembmusuem.com/';
}

export function globalUrl() {
    return envArr[0];
}

/**
 * ajax get/post 请求封装
 * @param serve 接口地址
 * @param params 请求传参
 * @param method 请求方式(get/post)
 */
export function request(serve, params = {}, method = 'get', responseType = '', num) {
    let url = envArr[0] + serve;
    const token = sessionStorage.getItem("token");
    return new Promise(function (resolve, reject) {
        let data = null;
        if (method.toLocaleLowerCase() === 'get' || method.toLocaleLowerCase() === 'delete') {
            data = 'params';
        } else {
            data = 'data';
        }
        var obj = {
            method,
            url,
            [data]: params,
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
                satoken: token
            }
        }
        if (responseType != '') {
            obj.responseType = responseType
        }
        /*if(method == 'get') {
            params.token = token
        }*/
        axios(obj).then((response) => {
            if (response.status) {
                resolve(response.data);
            }
        }, err => {
            let response = err.response;
            const status = response.status;
            if (status === 401) {
                sessionStorage.clear();//清除缓存
                router.push({path: '/'});//返回登录页
            }
            reject(err)
        })
    })
}

//文件上传
export function upLoadFile(uploadFolder, data) {
    const token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(data)
        //异步执行函数
        fileReader.onload = function (e) {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", envArr[0] + 'xiangxiu-common/file/upload?uploadPath=' + uploadFolder);
            xhr.setRequestHeader("Authorization", token);
            xhr.setRequestHeader("satoken", token);
            let formData = new FormData();
            formData.append('file', data);
            xhr.send(formData);
            //监听状态
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    resolve(JSON.parse(xhr.responseText).data);
                }
            }
        }
    });
}
