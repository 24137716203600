<template>
    <el-upload
            class="avatarUpload"
            :action="imgUrl" :headers="myHeaders"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
        <img v-if="image" :src="image" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</template>

<script>
    export default {
        props: {
            image: {
                type: String,
                default: () => { return null }
            },
        },
        data() {
            return {
                // 图片上传
                imgUrl: this.$uploadImgUrl() + 'music-common/file/upload?uploadPath=user',
                myHeaders: {
                    Authorization: sessionStorage.getItem('token'),
                    satoken: sessionStorage.getItem('token'),
                },
                fileType: ['jpg', 'png'], // 上传文件类型
            }
        },
        methods: {
            // 头像上传成功
            handleAvatarSuccess(res, file) {
                if (res.status == true) {
                    this.$emit('getImgList', res.data);
                } else {
                    this.$message.error(res.msg);
                }
            },
            // 头像上传前判断
            beforeAvatarUpload(file) {
                let that = this;
                let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
                let testType = Boolean(that.fileType.find(item => item == testmsg) == testmsg)
                if (!testType) {
                    that.$message.error(`上传文件只能是${that.fileType.join('、')}格式!`);
                    return false
                }
            },
        }
    }
</script>

<style scoped>
    .avatarUpload >>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
    }

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 6px;
    }
</style>
