<template>
    <div class="page formPage" id="collectionDetails">
        <div class="plateBox">
            <div class="flex_b_c gaunbi">
                <!--                <div class="plateTit flex_l_c"><span-->
                <!--                        class="plateTitIcon mainBgColor"></span><span>{{resourceInfo.name}}</span>-->
                <!--                </div>-->
                <div class="el-icon-circle-close aofbuia" @click="returnaf"></div>
            </div>
            <div class="flex_b_c">
                <div class="mainName">{{resourceInfo.name}}</div>
                <el-button type="text" @click="flagMore = !flagMore">{{flagMore ? '更多>>' :
                    '收起'}}
                </el-button>
            </div>
            <!--            <div class="fieldBox" :style="{height: flagMore ? '2.8rem': 'auto'}">-->
            <div class="fieldBox" :style="{height: flagMore ? '3rem': 'auto'}">
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">附表编号：</div>
                        <div class="flex1">{{resourceInfo.serialNumber}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">现登记号：</div>
                        <div class="flex1">{{resourceInfo.registration}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">尺寸(CM)：</div>
                        <div class="flex1">{{resourceInfo.size}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">年代：</div>
                        <div class="flex1">{{resourceInfo.years}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">原名：</div>
                        <div class="flex1">{{resourceInfo.oldName}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">收藏单位：</div>
                        <div class="flex1">{{resourceInfo.collectionUnit}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">藏品类型：</div>
                        <div class="flex1">{{resourceInfo.collectionType}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">藏品级别：</div>
                        <div class="flex1">{{resourceInfo.culturalLevel}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">原件/复制件：</div>
                        <div class="flex1">{{resourceInfo.original}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">形式：</div>
                        <div class="flex1">{{resourceInfo.modality}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">样式：</div>
                        <div class="flex1">{{resourceInfo.style}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">年份：</div>
                        <div class="flex1">{{resourceInfo.year}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">文物来源：</div>
                        <div class="flex1">{{resourceInfo.culturalSource}}</div>
                    </div>
                </div>
                <!--                <div class="item">-->
                <!--                    <div class="xiaobiaot">-->
                <!--                        <div class="field">所在展厅：</div>-->
                <!--                        <div class="flex1">{{resourceInfo.exhibitionHall}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">填写日期：</div>
                        <div class="flex1">{{resourceInfo.fillDate }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">是否展出：</div>
                        <div class="flex1">{{resourceInfo.exhibit == 0 ? '待展出' : resourceInfo.exhibit == 1 ? '展出中' :
                            "已结束"}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">是否文物：</div>
                        <div class="flex1">{{resourceInfo.collection == 0 ? '否' : '是' }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">藏品价值：</div>
                        <div class="flex1">{{resourceInfo.collectionClassification == 1 ? '镇馆之宝' :
                            resourceInfo.collectionClassification == 2 ? '精品文物' : '一般文物'}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">件数：</div>
                        <div class="flex1">{{ !resourceInfo.piece || resourceInfo.piece == 0 ? '暂无' : resourceInfo.piece
                            + '件'}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="xiaobiaot">
                        <div class="field">启用状态：</div>
                        <div class="flex1">{{resourceInfo.enabledState == 0 ? '禁用' : '启用' }}</div>
                    </div>
                </div>
                <div class="item1">
                    <div class="xiaobiaot">
                        <div class="field">文物简介：</div>
                        <div class="flex1" v-html="resourceInfo.introduce"></div>
                    </div>
                </div>
                <div class="item1">
                    <div class="xiaobiaot">
                        <div class="field">备注：</div>
                        <div class="flex1" v-html="resourceInfo.remark"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plateBox adfadf">
            <el-tabs v-model="fileType" type="card" @tab-click="handleTabClick">
                <el-tab-pane :disabled="resourceInfo.pictureList.length == 0" label="图片" name="1"></el-tab-pane>

                <el-tab-pane :disabled="resourceInfo.threeList.length == 0" label="三维模型"
                             name="0"></el-tab-pane>

                <el-tab-pane :disabled="resourceInfo.audioList.length == 0" label="音频" name="2"></el-tab-pane>

                <el-tab-pane :disabled="resourceInfo.videoList.length == 0" label="视频" name="3"></el-tab-pane>

                <!--                    <el-tab-pane :disabled="resourceInfo.pictureList.length == 0" label="出版物" name="4"></el-tab-pane>-->

                <!--                    <el-tab-pane :disabled="resourceInfo.pictureList.length == 0"label="文献" name="5"></el-tab-pane>-->

                <!--                    <el-tab-pane :disabled="resourceInfo.pictureList.length == 0" label="文件" name="6"></el-tab-pane>-->

                <!--                    <el-tab-pane :disabled="resourceInfo.pictureList.length == 0" label="其他" name="7"></el-tab-pane>-->
            </el-tabs>
            <div>
                <div class="mainInfo">
                    <div class="noSHuju" v-if="fileType == 4">暂无数据</div>
                    <iframe v-show="fileType == '0'" class="infoBox" id="mainIframe" ref="mainIframe" name="mainIframe"
                            :src="iframeSrc" frameborder="0" width="100%" height="100%"/>
                    <el-row :gutter="20" v-show="fileType != '0'">
                        <el-col class="infoBox h100">
                            <el-carousel ref="carouselImg" class="infoBox" :autoplay="false" indicator-position="none"
                                         type="card"
                                         @change="changeImg" v-if="fileType == '1'" :initial-index="changeImgIndex">
                                <el-carousel-item v-for="(item, index) in resourceInfo.pictureList" :key="item.id">
                                    <el-image style="min-height: 200px;min-width: 200px" @load="loajdi(item)"
                                              v-loading="item.loading" :src="item.imgUrl" fit="contain"></el-image>
                                    <!--                            <div class="pollIndex">{{ index + 1 + '/' +  resourceInfo.pictureList.length}}</div>-->
                                </el-carousel-item>

                            </el-carousel>
                            <!--视频-->
                            <el-carousel ref="carouselVideo" @change="changeImg" :initial-index="changeVideoIndex"
                                         v-if="fileType == '3'"
                                         :autoplay="false" class="h100 w100">
                                <el-carousel-item v-for="item in resourceInfo.videoList"
                                                  :key="item.id">
                                    <video class="w100 h100" controls
                                           :src="item.imgUrl"></video>
                                </el-carousel-item>
                            </el-carousel>
                            <!--音频-->
                            <el-carousel ref="carouselAudio" @change="changeImg" :initial-index="changeAudioIndex"
                                         :autoplay="false" v-if="fileType == '2'" class="h100 w100">
                                <el-carousel-item v-for="item in resourceInfo.audioList"
                                                  :key="item.id">
                                    <div class="text_center">
                                        <img src="../../../assets/img/audioIcon1.png"
                                             :class="[audioIsPlay ? 'audioIcon rotateAudio' : 'audioIcon']">
                                        <audio class="w100" controls :src="item.imgUrl" @play="playAudio"
                                               @pause="pauseAudio"></audio>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                            <!--                                                            &lt;!&ndash;pdf&ndash;&gt;-->
                            <!--                                                            <div v-if="fileTypeSuffix == 'pdf'" class="w100 h100">-->
                            <!--                                                                <div class="pdfBox">-->
                            <!--                                                                    <pdf :src="info.url" :page="currentPage"-->
                            <!--                                                                         @num-pages="pageCount=$event"-->
                            <!--                                                                         @page-loaded="currentPage=$event"-->
                            <!--                                                                         @loaded="loadPdfHandler">-->
                            <!--                                                                    </pdf>-->
                            <!--                                                                </div>-->
                            <!--                                                                <p class="text_center">-->
                            <!--                                                            <span @click="changePdfPage(0)">-->
                            <!--                                                                <i class="el-icon-caret-left pointer" style="font-size: 20px"></i>-->
                            <!--                                                            </span>-->
                            <!--                                                                    <span style="position: relative;bottom: 2px">{{currentPage}} / {{pageCount}}</span>-->
                            <!--                                                                    <span @click="changePdfPage(1)">-->
                            <!--                                                                <i class="el-icon-caret-right pointer" style="font-size: 20px"></i>-->
                            <!--                                                            </span>-->
                            <!--                                                                </p>-->
                            <!--                                                            </div>-->
                            <!--                                                            &lt;!&ndash;word&ndash;&gt;-->
                            <!--                                                            <div class="word-wrap w100 h100" v-if="fileTypeSuffix === 'docx'">-->
                            <!--                                                                <div id="wordView" v-html="wordText"/>-->
                            <!--                                                            </div>-->
                            <!--                                                            &lt;!&ndash;xlsx&ndash;&gt;-->
                            <!--                                                            <div class="xlsx-wrap w100 h100" v-if="fileTypeSuffix === 'xlsx'"-->
                            <!--                                                                 v-html="xlsxText"></div>-->
                        </el-col>
                    </el-row>
                    <div v-if="fileType == 0 || fileType == 1" style="text-align: right;margin-right: 50px">
                        <el-tooltip class="item" effect="dark" content="下载文件" placement="top">
                            <div @click="fileDowwn()" class="xiazai">
                                <img src="../../../assets/img/downLoad.png">
                            </div>
                        </el-tooltip>
                    </div>
                    <div>
                        <el-table
                                v-show="showList && fileType != 0 && fileType != 4"
                                :data="dataList"
                                size="small"
                                height="calc(100% - 60px)"
                                highlight-current-row
                                @current-change="handleCurrentChange"
                                class="table pointer" ref="fileTable">
                            <el-table-column prop="fileName"
                                             show-overflow-tooltip label="文件名称">
                            </el-table-column>
                        </el-table>
                        <div v-if="fileType != 0 && fileType != 4"
                             :class="['mainColor showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
                             @click="showList = !showList"></div>
                    </div>
                </div>
                <div style="text-align: right">
                    <el-select v-show="fileType == 0" v-model="threeType" style="width: 150px" @change="changeSelect"
                               size="small" placeholder="请选择模型等级">
                        <el-option
                                v-for="item in threeListType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div style="text-align: center;margin-top: 20px">
                    <el-button size="small" type="primary" @click="returnaf">返回</el-button>
                    <el-button size="small" v-if="yulanList.name" type="primary" @click="tijiao" v-no-more-click>提交
                    </el-button>
                    <!--                    <el-button size="small" type="primary" @click="tijiao" v-no-more-click>提交</el-button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import XLSX from "xlsx/types";
    import pdf from "vue-pdf";
    import mammoth from "mammoth";

    export default {
        name: "collectionDetails",
        // components: {pdf,},
        props: {
            viewData: String,
        },
        data() {
            return {
                resourceId: '',
                flagMore: true, //切换是否显示更多
                resourceInfo: [],//文物信息
                dialogVisible: false,
                fileType: '0', //文件类型
                audioIsPlay: false, // 音频是否播放
                threeType: '',
                threeListType: [], //模型类型s
                iframeSrc: '/static/threeJs/examples/test_obj.html',//引入三维文件页面
                yulanList: [],//预览数据储存

                dataList: [],
                showList: false,

                changeImgIndex: 0,
                changeVideoIndex: 0,
                changeAudioIndex: 0,


                // resourceTypes: [],//类型有哪些
                // dataList: [//图片列表
                //     {
                //         url: 'http://t13.baidu.com/it/u=230088816,2918366315&fm=224&app=112&f=JPEG?w=250&h=500'
                //     },
                // ],
                // showList: false,
                // info: { //资源
                //     url: '',//视频
                // },
                // fileTypeSuffix: '',//文件后缀
                // pageNo: 0,
                // pageSize: 10,
                // total: 0,
                // wordText: "", //world地址
                // xlsxText: "", //xlax地址
                // currentPage: 0, // pdf文件页码
                // pageCount: 0, // pdf文件总页数
                // fileList: [],
                // modelType: '',
                // modelTypeOpt: [],
                // // 标签
                // dynamicTags: [],
                // inputVisible: false,
                // inputValue: '',
                // tagOpt: [],
                // mtlUrl: '',
                // objUrl: '',
                // objFile: '',
                // mtlFile: '',
                // // 相关资源
                // tabType: 0,
                // dataListType: [],
                // pageNoType: 1,
                // pageSizeType: 10,
                // totalType: 0,
                // listType: '',
                // collectionType: 0,// 藏品类型
                // collectionMaster: false,
                // onLabel: false,//是否显示标签
                // pageHistory: '', //从哪个页面来的
            }
        },
        mounted() {
            // if (this.viewData != 'BorrowForm') {
            this.init()
            // }
        },

        methods: {
            init() {
                if (!this.$route.params.num) { //查看
                    let id = this.$route.query.id;
                    this.threeListType = [];
                    //获取文件详情
                    this.$axios(this.api.threeDFileControl.getById + id, {}, 'get').then(res => {
                        // this.getFileType()
                        this.resourceInfo = res.data;
                        this.resourceInfo.collectionUnit = res.data.collectionUnitName
                        this.resourceInfo.collectionType = res.data.collectionTypeName
                        this.resourceInfo.culturalLevel = res.data.culturalLevelName
                        this.resourceInfo.original = res.data.originalName
                        this.resourceInfo.modality = res.data.modalityName
                        this.resourceInfo.style = res.data.styleName
                        this.resourceInfo.years = res.data.yearsName
                        this.resourceInfo.culturalSource = res.data.culturalSourceName
                        this.resourceInfo.pictureList.forEach(item => {
                            this.$set(item, 'loading', true)
                            if (item.compressedPath) {
                                item.imgUrl = item.compressedPath
                            }
                        })
                        console.log(this.resourceInfo.pictureList)
                        this.collList()
                        let aa = this.fileType == 1 ? '0' : this.fileType == 0 ? '1' : this.fileType
                        console.log(aa)
                        let data = {
                            index: aa
                        }
                        this.handleTabClick(data)
                        if (!this.resourceInfo.stringMapMap) {
                            this.$set(this.resourceInfo, 'stringMapMap', {})
                        }
                        this.resourceInfo.stringMapMap = {};
                        this.resourceInfo.threeList.forEach(item => {
                            if (!this.resourceInfo.stringMapMap[item.modelType]) {
                                this.$set(this.resourceInfo.stringMapMap, item.modelType, item)
                            }
                        })
                        let aa1 = this.resourceInfo.threeList.filter(item => {
                            return item.modelType == 3
                        })
                        if (aa1 && aa1.length != 0) {
                            this.threeListType.push({label: '低模', value: '3'})
                        }
                        setTimeout(() => {
                            let aa2 = this.resourceInfo.threeList.filter(item => {
                                return item.modelType == 2
                            })
                            if (aa2 && aa1.length != 0) {
                                this.threeListType.push({label: '中模', value: '2'})
                            }
                        })
                        setTimeout(() => {
                            let aa3 = this.resourceInfo.threeList.filter(item => {
                                return item.modelType == 1
                            })
                            if (aa3 && aa1.length != 0) {
                                this.threeListType.push({label: '高模', value: '1'})
                            }
                        })
                        this.threeListType.sort(function (a, b) {
                            return a.value - b.value
                        })
                        this.threeType = this.threeListType[0].value
                        this.$nextTick(() => {
                            // this.changeSelect(this.threeType,)
                        })
                        // if (Object.keys(this.resourceInfo.stringMapMap).length != 0) {
                        //     if (this.resourceInfo.stringMapMap[3]) {
                        //         this.threeListType.push({label: '低模', value: '3'})
                        //     }
                        //     if (this.resourceInfo.stringMapMap[2]) {
                        //         this.threeListType.push({label: '中模', value: '2'})
                        //     }
                        //     if (this.resourceInfo.stringMapMap[1]) {
                        //         this.threeListType.push({label: '高模', value: '1'})
                        //     }
                        //     this.threeType = this.threeListType[0].value
                        //     this.$nextTick(() => {
                        //         this.changeSelect(this.threeType,)
                        //     })
                        //
                        // }
                    })
                } else {
                    //新增预览
                    this.getFileType()
                    this.yulanList = JSON.stringify(this.$route.params.list);
                    this.yulanList = JSON.parse(this.yulanList);
                    this.resourceInfo = this.$route.params.list;
                    this.resourceInfo.pictureList.forEach(item => {
                        this.$set(item, 'loading', true)
                    })
                    // if (this.$route.params.num == 2) {
                    if (!this.resourceInfo.stringMapMap) {
                        this.$set(this.resourceInfo, 'stringMapMap', {})
                    }
                    this.resourceInfo.stringMapMap = {};
                    this.resourceInfo.threeList.forEach(item => {
                        if (!this.resourceInfo.stringMapMap[item.modelType]) {
                            this.$set(this.resourceInfo.stringMapMap, item.modelType, item)
                        }
                    })
                    // else {
                    //     this.resourceInfo.threeList.forEach(item=>{
                    //         if (!this.resourceInfo.stringMapMap[item.modelType]){
                    //             this.$set(this.resourceInfo.stringMapMap,item.modelType,item)
                    //         }
                    //     })
                    // }
                    console.log(this.resourceInfo.stringMapMap)
                    // }
                    this.collList()
                    let aa = this.fileType == 1 ? '0' : this.fileType == 0 ? '1' : this.fileType
                    console.log(aa)
                    let data = {
                        index: aa
                    }
                    this.handleTabClick(data)
                    if (this.resourceInfo.threeList.length) {
                        let aa1 = this.resourceInfo.threeList.filter(item => {
                            return item.modelType == 3
                        })
                        if (aa1 && aa1.length != 0) {
                            this.threeListType.push({label: '低模', value: '3'})
                        }
                        setTimeout(() => {
                            let aa2 = this.resourceInfo.threeList.filter(item => {
                                return item.modelType == 2
                            })
                            if (aa2 && aa1.length != 0) {
                                this.threeListType.push({label: '中模', value: '2'})
                            }
                        })
                        setTimeout(() => {
                            let aa3 = this.resourceInfo.threeList.filter(item => {
                                return item.modelType == 1
                            })
                            if (aa3 && aa1.length != 0) {
                                this.threeListType.push({label: '高模', value: '1'})
                            }
                        })
                        // this.resourceInfo.threeList.forEach(item => {
                        //     if (item.modelType == 1) {
                        //         this.threeListType.push({label: '高模', value: '1'})
                        //     }
                        //     if (item.modelType == 2) {
                        //         this.threeListType.push({label: '中模', value: '2'})
                        //     }
                        //     if (item.modelType == 3) {
                        //         this.threeListType.push({label: '低模', value: '3'})
                        //     }
                        // })
                        this.threeType = this.threeListType[0].value
                        this.$nextTick(() => {
                            // this.changeSelect(this.threeType,)
                        })
                    }
                    // else {
                    //     console.log(3)
                    //     //编辑预览
                    //     this.yulanList = this.$route.params.list;
                    //     this.resourceInfo = this.$route.params.list;
                    //     this.$set(this.resourceInfo,'stringMapMap',this.resourceInfo.threeList)
                    //     console.log(this.yulanList)
                    //     console.log(this.resourceInfo)
                    // }

                }
            },

            getFileType() {
                //文物各项分类
                this.getCulturalLevelList()
                this.getCollectionUnitList()
                this.getModalityList()
                this.getStyleList()
                this.getYearsList()
                this.getOriginalList()
                this.getCulturalSourceList()
                this.getColleZhanList()
            },

            //文物有哪些类型
            collList() {
                if (this.resourceInfo.pictureList.length != 0) {
                    this.fileType = '1';
                    return;
                } else if (this.resourceInfo.threeList.length != 0) {
                    this.fileType = '0';
                    return;
                } else if (this.resourceInfo.audioList.length != 0) {
                    this.fileType = '2';
                    return;
                } else if (this.resourceInfo.videoList.length != 0) {
                    this.fileType = '3';
                    return;
                } else {
                    this.fileType = '4';
                }
            },

            handleClose() {
                this.dialogVisible = false;
            },


            // // 下载
            // downloadThree() {
            //     this.$message.info("资源文件在进行下载中，请勿重复点击")
            //     const a = document.createElement('a')
            //     if (0 == this.fileType) {
            //         // stl 下载方式
            //         if (this.inputForm.fileFormat === 'stl') {
            //             const stlUrl = this.inputForm.url
            //             const stlName = this.inputForm.fileName
            //             fetch(stlUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            //                 a.href = URL.createObjectURL(blob)
            //                 a.download = stlName, // 下载文件的名字
            //                     document.body.appendChild(a)
            //                 a.click()
            //             })
            //             return
            //         }
            //         if ((this.objFile == null || this.objFile.url == null) && (this.mtlFile == null || this.mtlFile.url == null)) {
            //             this.$message.error("当前资源未存在文件或文件损坏！")
            //             return
            //         }
            //         //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
            //         // 完整的url则直接使用
            //         const mtlurl = this.mtlFile.url
            //         const objurl = this.objFile.url
            //         const imgUrl = this.imgFile.url
            //         const mtlname = this.mtlFile.fileName
            //         const objname = this.objFile.fileName
            //         const imgname = this.imgFile.fileName
            //         // 这里是将url转成blob地址，
            //         fetch(mtlurl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            //             a.href = URL.createObjectURL(blob)
            //             a.download = mtlname, // 下载文件的名字
            //                 // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
            //                 document.body.appendChild(a)
            //             a.click()
            //         })
            //         fetch(objurl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            //             a.href = URL.createObjectURL(blob)
            //             a.download = objname, // 下载文件的名字
            //                 // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
            //                 document.body.appendChild(a)
            //             a.click()
            //         })
            //         fetch(imgUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            //             a.href = URL.createObjectURL(blob)
            //             console.log(a.href)
            //             a.download = imgname, // 下载文件的名字
            //                 // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
            //                 document.body.appendChild(a)
            //             a.click()
            //         })
            //     } else /*if(this.fileType=='2'||this.fileType=='3')*/{
            //         if (this.info == null || this.info.url == '') {
            //             this.$message.error("当前资源未存在文件或文件损坏！")
            //             return
            //         }
            //         const url = this.info.url
            //         fetch(url).then(res => res.blob()).then(blob => {
            //             a.href = URL.createObjectURL(blob)
            //             a.download = this.info.fileName;
            //             document.body.appendChild(a)
            //             a.click();
            //         })
            //     }
            //
            //
            // },

            // 音频播放
            playAudio() {
                this.audioIsPlay = true;
            },
            // 音频暂停
            pauseAudio() {
                this.audioIsPlay = false;
            },
            // // pdf加载时
            // loadPdfHandler(e) {
            //     this.currentPage = 1 // 加载的时候先加载第一页
            // },
            // // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
            // changePdfPage(val) {
            //     if (val === 0 && this.currentPage > 1) {
            //         this.currentPage--
            //     }
            //     if (val === 1 && this.currentPage < this.pageCount) {
            //         this.currentPage++
            //     }
            // },

            // 获取文件后缀识别文件类型
            // getFileType(data) {
            //     if (data == null) {
            //         return
            //     }
            //     let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
            //     let namelength = data.length;//取到文件名长度
            //     this.fileTypeSuffix = data.substring(first + 1, namelength);//截取获得后缀名
            //     if (this.fileType > 3) {
            //         if (this.fileTypeSuffix == 'docx') {
            //             this.getWordText()
            //         }
            //         if (this.fileTypeSuffix == 'xlsx') {
            //             this.getXlsxText()
            //         }
            //         if (this.fileTypeSuffix != 'pdf' && this.fileTypeSuffix != 'docx' && this.fileTypeSuffix != 'xlsx') {
            //             this.$message('当前文件暂不支持预览，敬请下载查阅')
            //         }
            //     }
            // },
            // getWordText() {
            //     const xhr = new XMLHttpRequest();
            //     xhr.open("get", this.info.url, true);
            //     xhr.responseType = "arraybuffer";
            //     xhr.onload = () => {
            //         if (xhr.status == 200) {
            //             mammoth.convertToHtml({arrayBuffer: new Uint8Array(xhr.response)}).then((resultObject) => {
            //                 this.$nextTick(() => {
            //                     this.wordText = resultObject.value;
            //                 });
            //             });
            //         }
            //     };
            //     xhr.send();
            // },
            // getXlsxText() {
            //     const xhr = new XMLHttpRequest();
            //     xhr.open("get", this.info.url, true);
            //     xhr.responseType = "arraybuffer";
            //     xhr.onload = () => {
            //         if (xhr.status == 200) {
            //             let workbook = XLSX.read(new Uint8Array(xhr.response), {type: "array"}); // 解析数据
            //             var worksheet = workbook.Sheets[workbook.SheetNames[0]]; // workbook.SheetNames 下存的是该文件每个工作表名字,这里取出第一个工作表
            //             this.xlsxText = XLSX.utils.sheet_to_html(worksheet); // 渲染
            //         }
            //     };
            //     xhr.send();
            // },

            changeSelect(type) {
                console.log(type)
                var that = this
                let newarr = {};
                // console.log(this.$route.query.id)
                console.log(this.resourceInfo.stringMapMap)
                console.log(type)
                console.log(this.$route.params.num)
                for (let key in this.resourceInfo.stringMapMap) {
                    let item = this.resourceInfo.stringMapMap[key]
                    if (key == type) {
                        if (item.decompresses) {
                            if (item.decompresses.length == 1) {
                                this.$set(newarr, 'stl', {})
                                this.$set(newarr.stl, 'imgUrl', item.decompresses[0].url)
                                console.log(newarr)
                            } else {
                                this.$set(newarr, 'mtl', {})
                                this.$set(newarr, 'obj', {})
                                this.$set(newarr, 'img', {})
                                item.decompresses.forEach(item3 => {
                                    if (item3.type == 'mtl') {
                                        this.$set(newarr.mtl, 'imgUrl', item3.url)
                                    } else if (item3.type == 'obj') {
                                        this.$set(newarr.obj, 'imgUrl', item3.url)
                                    } else {
                                        this.$set(newarr.img, 'imgUrl', item3.url)
                                    }
                                })
                            }
                        } else {
                            console.log(3)
                            newarr = this.resourceInfo.stringMapMap[type]
                        }
                    }
                }
                console.log(newarr)
                if (type == '1') {
                    this.$message('预览高模文件，请保持客户端内存充足')
                }
                // if (!newarr) {
                //     this.$message('无三维文件，请上传三维文件')
                //     return;
                // }
                let iframeData
                if (!newarr.stl) {  // obj模型
                    this.iframeSrc = '/static/threeJs/examples/test_obj.html'
                    // this.inputForm = newarr.obj
                    // that.objFile = newarr.obj.imgUrl
                    // that.mtlFile = newarr.mtl.imgUrl
                    // that.imgFile = newarr.img.imgUrl
                    iframeData = {
                        mtlUrl: newarr.mtl.imgUrl,
                        objUrl: newarr.obj.imgUrl,
                        imgUrl: newarr.img.imgUrl,
                    }
                } else if (newarr.stl) {  // stl模型
                    this.iframeSrc = '/static/threeJs/examples/test_stl.html'
                    // this.inputForm = newarr.imgUrl
                    iframeData = {
                        stlUrl: newarr.stl.imgUrl,
                    }
                } else {
                    this.$message('缺失文件')
                    this.resourceExists = true;
                    return;
                }
                // 使用ref 获取 dom
                const mapFrame = this.$refs['mainIframe']
                // 因为iframe页面打开就已经加载 获取接口成功后    刷新他
                mapFrame.contentWindow.location.reload()
                if (mapFrame.attachEvent) {
                    // 兼容浏览器判断 // iframe的加载完成函数
                    mapFrame.attachEvent('onload', function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    })
                } else {
                    // iframe的加载完成函数
                    mapFrame.onload = function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    }
                }
                return
            },

            //获取文物分级列表
            getCulturalLevelList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 1
                }, 'get').then(res => {
                    let levelList = res.data;
                    levelList.forEach(item => {
                        if (this.resourceInfo.culturalLevel == item.id) {
                            this.resourceInfo.culturalLevel = item.name;
                        }
                    })
                })
            },

            //形式列表
            getModalityList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 2
                }, 'get').then(res => {
                    let modalityList = res.data;
                    modalityList.forEach(item => {
                        if (this.resourceInfo.modality == item.id) {
                            this.resourceInfo.modality = item.name;
                        }
                    })
                })
            },

            //样式列表
            getStyleList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 3
                }, 'get').then(res => {
                    let styleList = res.data;
                    styleList.forEach(item => {
                        if (this.resourceInfo.style == item.id) {
                            this.resourceInfo.style = item.name;
                        }
                    })
                })
            },

            //年代列表
            getYearsList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 4
                }, 'get').then(res => {
                    let yearsList = res.data;
                    yearsList.forEach(item => {
                        if (this.resourceInfo.years == item.id) {
                            this.resourceInfo.years = item.name;
                        }
                    })
                })
            },

            //原件/复制件列表
            getOriginalList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 5
                }, 'get').then(res => {
                    let originalList = res.data;
                    originalList.forEach(item => {
                        if (this.resourceInfo.original == item.id) {
                            this.resourceInfo.original = item.name;
                        }
                    })
                })
            },

            //文物来源列表
            getCulturalSourceList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 6
                }, 'get').then(res => {
                    let culturalSourceList = res.data;
                    culturalSourceList.forEach(item => {
                        if (this.resourceInfo.culturalSource == item.id) {
                            this.resourceInfo.culturalSource = item.name;
                        }
                    })
                })
            },

            //获取收藏单位列表
            getCollectionUnitList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 8
                }, 'get').then(res => {
                    let collectionUnitList = res.data;
                    collectionUnitList.forEach(item => {
                        if (this.resourceInfo.collectionUnit == item.id) {
                            this.resourceInfo.collectionUnit = item.name;
                            console.log(this.resourceInfo)
                        }
                    })
                })
            },

            //获取文物展厅列表
            getColleZhanList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 9
                }, 'get').then(res => {
                    let zhanting = res.data;
                    zhanting.forEach(item => {
                        if (this.resourceInfo.collectionType == item.id) {
                            this.resourceInfo.collectionType = item.name;
                        }
                    })
                })
            },

            //返回
            returnaf() {
                let pageNo
                if (this.$route.query.pageNo) {
                    pageNo = this.$route.query.pageNo;
                } else {
                    pageNo = this.$route.params.pageNo;
                }
                console.log(pageNo)
                if (this.yulanList.name) { //预览
                    this.$router.push({
                        path: sessionStorage.getItem('superior'),
                        query: {
                            list: this.yulanList,
                            num: 6,
                            pageNo: pageNo,
                        }
                    })
                } else { //查看
                    this.$router.push({
                        path: '/culturalMent/3DFileControl',
                        query: {
                            pageNo: pageNo,
                        }
                    })
                }
                sessionStorage.removeItem('superior')
            },

            //提交
            tijiao() {
                console.log(this.yulanList)
                let pageNo = this.$route.params.pageNo;
                console.log(pageNo)
                if (!this.yulanList.hasOwnProperty('id')) {
                    //新增
                    let xinxiList = {...this.yulanList};
                    this.$delete(xinxiList, 'pictureList')
                    // this.$delete(xinxiList, 'threeList')
                    this.$delete(xinxiList, 'audioList')
                    this.$delete(xinxiList, 'videoList')
                    this.$delete(xinxiList, 'stringMapMap')
                    let culturalFiles = [...this.yulanList.pictureList, ...this.yulanList.threeList, ...this.yulanList.audioList, ...this.yulanList.videoList]
                    this.$axios(this.api.threeDFileControl.save, {
                        ...xinxiList,
                        culturalFiles: culturalFiles,
                    }, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('新增成功')
                            this.$router.push({
                                path: '/culturalMent/3DFileControl'
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    //编辑
                    let xinxiList = {...this.yulanList};
                    this.$delete(xinxiList, 'pictureList')
                    this.$delete(xinxiList, 'threeList')
                    this.$delete(xinxiList, 'audioList')
                    this.$delete(xinxiList, 'videoList')
                    this.$delete(xinxiList, 'stringMapMap')
                    let culturalFiles = [...this.yulanList.pictureList, ...this.yulanList.threeList, ...this.yulanList.audioList, ...this.yulanList.videoList]
                    this.$axios(this.api.threeDFileControl.updateById, {
                        ...xinxiList,
                        culturalFiles: culturalFiles,
                    }, 'put').then(res => {
                        if (res.status) {
                            this.$message.success('修改成功')
                            this.$router.push({
                                path: '/culturalMent/3DFileControl',
                                query: {
                                    pageNo: pageNo,
                                }
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            },

            // tab切换
            handleTabClick(val) {
                console.log(val.index)
                this.$nextTick(() => {
                    if (val.index == 0) {
                        this.dataList = this.resourceInfo.pictureList
                        this.$refs.fileTable.setCurrentRow(this.dataList[this.changeImgIndex]);
                    } else if (val.index == 2) {
                        this.dataList = this.resourceInfo.audioList
                        this.$refs.fileTable.setCurrentRow(this.dataList[this.changeAudioIndex]);
                    } else if (val.index == 3) {
                        this.dataList = this.resourceInfo.videoList
                        this.$refs.fileTable.setCurrentRow(this.dataList[this.changeVideoIndex]);
                    }
                    if (val.index == 1) {
                        this.showList = false
                        this.$nextTick(() => {
                            this.changeSelect(this.threeType,)
                        })
                    }
                })
            },

            // 图片、视频、音频、切换切换
            changeImg(val) {
                if (this.fileType == 1) { //图片
                    this.changeImgIndex = val
                } else if (this.fileType == 3) { //视频
                    this.changeVideoIndex = val
                } else if (this.fileType == 2) { //音频
                    this.changeAudioIndex = val
                }
                this.$refs.fileTable.setCurrentRow(this.dataList[val]);
            },

            //表的点击
            handleCurrentChange(val) {
                this.dataList.forEach((item, index) => {
                    if (item.fileName == val.fileName) {
                        if (this.fileType == 1) { //图片
                            this.changeImgIndex = index
                            //  手动切换轮播图
                            this.$nextTick(() => {
                                this.$refs.carouselImg.setActiveItem(index)
                            })
                        } else if (this.fileType == 3) { //视频
                            this.changeVideoIndex = index
                            //  手动切换轮播图
                            this.$nextTick(() => {
                                this.$refs.carouselVideo.setActiveItem(index)
                            })
                        } else if (this.fileType == 2) { //音频
                            this.changeAudioIndex = index
                            //  手动切换轮播图
                            this.$nextTick(() => {
                                this.$refs.carouselAudio.setActiveItem(index)
                            })
                        }
                    }
                })
            },

            //根据地址下载
            downLode(url, name) {
                // const a = document.createElement('a')
                // a.href = url
                // a.download = name // 下载后文件名
                // a.style.display = 'none'
                // document.body.appendChild(a)
                // a.click() // 点击下载
                // document.body.removeChild(a) // 下载完成移除元素
                console.log(url)
                let t1 = url + '?' + new Date().valueOf()
                console.log(t1)
                fetch(t1).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(blob)
                    console.log(a.href)
                    a.download = name  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
                })
            },

            loajdi(row) {
                console.log(1)
                // setTimeout(()=>{
                row.loading = false;
                // },200)
            },

            //文物下载
            fileDowwn() {
                if (this.fileType == 1) { //图片
                    this.downLode(this.dataList[this.changeImgIndex].imgUrl, this.dataList[this.changeImgIndex].fileName)
                } else { //三维文件
                    let shuju = this.resourceInfo.stringMapMap[this.threeType]
                    console.log(shuju)
                    if (shuju.decompresses) {
                        if (shuju.decompresses.length == 1) {
                            let name = shuju.decompresses[0].url.split('/')
                            let textName = name[name.length - 1]
                            this.downLode(shuju.decompresses[0].url, textName)
                        } else {
                            shuju.decompresses.forEach(item => {
                                let name = item.url.split('/')
                                let textName = name[name.length - 1]
                                this.downLode(item.url, textName)
                            })
                        }
                    } else {
                        if (shuju.stl) {
                            this.downLode(shuju.stl.imgUrl, shuju.stl.fileName)
                        } else {
                            for (let key in shuju) {
                                this.downLode(shuju[key].imgUrl, shuju[key].fileName)
                            }
                        }
                    }
                }
            },
        }
    }
</script>

<style scoped>
    #main .el-main-center .mainCenter .formPage {
        overflow: auto;
        overflow-x: hidden;
    }

    .el-carousel >>> .el-carousel__container {
        height: 100% !important;
    }

    .el-carousel__item >>> .el-image {
        height: 100%;
    }

    .el-carousel__item {
        text-align: center;
    }

    .word-wrap, .xlsx-wrap {
        overflow: auto;
    }

    .word-wrap >>> img, .xlsx-wrap >>> table {
        width: 100% !important;
    }

    .xlsx-wrap >>> tr {
        border-bottom: 1px solid #DCDCDC;
    }

    .xlsx-wrap >>> td {
        padding: 6px;
        text-align: center;
    }

    .info .el-button--small {
        font-size: 34px;
    }

    .button-new-tag {
        margin-left: 5px;
    }

    .home .plateTit {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .mainName {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .home .plateTit .plateTitIcon {
        display: inline-block;
        width: 0.31rem;
        height: 1.75rem;
        background: #A82126;
        border-radius: 0.16rem;
        margin-right: 0.5rem;
    }

    .fieldBox .item {
        display: inline-block;
        width: 24%;
        margin-right: 1%;
        margin-bottom: 1.2rem;
        vertical-align: top;
        font-size: 1rem;
        word-break: break-all;
    }

    .fieldBox .item1 {
        display: inline-block;
        width: 80%;
        margin-right: 1%;
        margin-bottom: 1.2rem;
        vertical-align: top;
        font-size: 1rem;
        word-break: break-all;
    }


    .plateBox {
        padding: 20px;
        border-radius: 5px;
        background: #ffffff;
        /*box-shadow: 0px 0px 3px 0px #000000;*/
        margin-bottom: 10px;
    }

    .adfadf {
        margin: 0;
    }

    .flex_l_t {
        display: flex;
        align-items: center;
    }

    .infoBox {
        height: 500px;
    }

    .w100 {
        width: 100%;
    }

    .formPage {
        padding: 0 !important;
        background: transparent !important;
    }

    .mainInfo {
        position: relative;
    }

    .noSHuju {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 1rem;
        font-weight: 400;
        color: rgba(192,207,227,1);
    }

    .xiazai {
        bottom: 0px;
        right: 50px;
        position: absolute;
        font-size: 30px;
        color: #5e6d82;
        cursor: pointer;
    }

    .flex1 {
        flex: 1;
    }

    .xiaobiaot {
        display: flex;
        justify-content: flex-start;
    }

    .gaunbi {
        position: relative;
    }

    .aofbuia {
        font-size: 18px;
        top: -10px;
        right: 0;
        position: absolute;
        cursor: pointer;
    }

    .showListBtn {
        position: absolute;
        top: 50%;
        right: -2rem;
        transform: translateY(-50%);
        background: #eeeeee;
        padding: 2rem 0.5rem;
        z-index: 100;
        cursor: pointer;
        font-weight: bold;
        border-radius: 0.4rem 0 0 0.4rem;
    }

    .table {
        position: absolute;
        width: 250px;
        top: 0;
        right: 0;
        z-index: 99;
    }

    .pollIndex {
        font-size: 25px;
        font-weight: bold;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }

</style>
