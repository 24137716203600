<template>
    <div class="page formPage" id="fileControl">
        <div class="minmoxing">
            <div class="biaoti">藏品编辑</div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="基本信息" name="1"></el-tab-pane>
                <el-tab-pane label="图片" name="2"></el-tab-pane>
                <el-tab-pane label="三维模型" name="3"></el-tab-pane>
                <el-tab-pane label="音频" name="4"></el-tab-pane>
                <el-tab-pane label="视频" name="5"></el-tab-pane>
            </el-tabs>
            <div v-show="activeName != 1" class="updatea">
                <!--图片-->
                <el-col v-show="activeName==2">
                    <div>
                        <el-button :disabled="ruleForm.pictureList.length == pictureNumber" size="small" type="primary"
                                   @click="upDate(2)">
                            上传图片
                        </el-button>
                        <div class="fileconfig">
                            支持格式：{{pictureFiles.join('、')}}；限{{pictureNumber}}张图片。
                        </div>
                    </div>
                </el-col>

                <!--三维模型-->
                <el-col v-show="activeName==3">
                    <div style="text-align: center">
                        <el-button :disabled="ruleForm.threeList.length == 3" size="small" type="primary"
                                   @click="upDate(3)">
                            上传模型
                        </el-button>
                        <div class="fileconfig">仅支持格式：rar、stl、7z、zip等</div>
                    </div>
                </el-col>

                <!--音频-->
                <el-col v-show="activeName==4">
                    <div style="text-align: center">
                        <el-button :disabled="ruleForm.audioList.length == 10" size="small" type="primary"
                                   @click="upDate(4)">
                            上传音频
                        </el-button>
                        <div class="fileconfig">仅支持mp3文件，单个文件最大100M,限{{audioNumber}}个音频</div>
                    </div>
                </el-col>

                <!--视频-->
                <el-col v-show="activeName==5">
                    <div style="text-align: center">
                        <el-button :disabled="ruleForm.videoList.length == 10" size="small" type="primary"
                                   @click="upDate(5)">
                            上传视频
                        </el-button>
                        <div class="fileconfig">仅支持mp4文件,单个文件最大100M,限{{videoNumber}}个视频</div>
                    </div>
                </el-col>
            </div>

        </div>
        <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="100px">
            <el-row class="minmoxing zhne">

                <!--基本信息-->
                <div v-show="activeName==1">
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="名称" prop="name">
                                <el-input clearable maxlength="100" placeholder="请填写文物名称(限100字)"
                                          v-model="ruleForm.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="原名" prop="oldName">
                                <el-input clearable maxlength="100"
                                          placeholder="请填写文物名称(限100字)"
                                          v-model="ruleForm.oldName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="附表编号" prop="serialNumber">
                                <el-input clearable maxlength="10"
                                          placeholder="请输入附表编号(限10字)"
                                          v-model="ruleForm.serialNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="现登记号" prop="registration">
                                <el-input clearable maxlength="20"
                                          placeholder="请输入现登记号(限20字)"
                                          v-model="ruleForm.registration"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="收藏单位" prop="collectionUnit">
                                <el-select clearable style="width: 100% ;"
                                           v-model="ruleForm.collectionUnit"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in collectionUnitList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="藏品类型" prop="collectionType">
                                <el-select clearable style="width: 100% ;"
                                           filterable
                                           remote
                                           :remote-method="getCulturalTypeList"
                                           v-model="ruleForm.collectionType"
                                           :loading="typeLoading"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in cangTypeList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="藏品级别" prop="culturalLevel">
                                <el-select clearable style="width: 100% ;"
                                           v-model="ruleForm.culturalLevel"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in levelList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="原件/复制件" prop="original">
                                <el-select clearable style="width: 100%;"
                                           v-model="ruleForm.original"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in originalList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="形式" prop="modality">
                                <el-select clearable style="width: 100%;"
                                           v-model="ruleForm.modality"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in modalityList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="样式" prop="style">
                                <el-select clearable style="width: 100%;"
                                           v-model="ruleForm.style" placeholder="请选择">
                                    <el-option
                                            v-for="item in styleList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="年代" prop="years">
                                <el-select clearable style="width: 100%;"
                                           filterable
                                           remote
                                           :remote-method="getYearsList"
                                           v-model="ruleForm.years"
                                           :loading="yearsLoading"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in yearsList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="年份" prop="year">
                                <el-input clearable maxlength="10" placeholder="请输入年份(限10字)"
                                          v-model="ruleForm.year"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="文物来源" prop="culturalSource">
                                <el-select clearable style="width: 100%;"
                                           v-model="ruleForm.culturalSource"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in culturalSourceList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="尺寸(cm)" prop="size">
                                <el-input clearable maxlength="100"
                                          placeholder="请输入尺寸(限100字)"
                                          v-model="ruleForm.size"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="展出状态" prop="exhibit">
                                <el-select clearable
                                           :disabled="true"
                                           style="width: 100% "
                                           v-model="ruleForm.exhibit"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in exhibitionList"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="填写日期" prop="fillDate">
                                <el-date-picker
                                        clearable
                                        style="width: 100%"
                                        v-model="ruleForm.fillDate"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="启用状态" prop="enabledState">
                                <el-select clearable style="width: 100% "
                                           v-model="ruleForm.enabledState"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in enableList"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item label="是否藏品" prop="collection">
                                <el-select clearable style="width: 100% "
                                           v-model="ruleForm.collection"
                                           @change="switchState"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in collectionList"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="10">
                            <el-form-item label="件数" prop="piece">
                                <el-input clearable maxlength="3"
                                          placeholder="请输入件数 1~999"
                                          @input="ruleForm.piece=ruleForm.piece.replace(/^(0+)|[^\d]+/g, '')"
                                          v-model.trim="ruleForm.piece"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="3">
                            <el-form-item v-if="this.ruleForm.collection == 1" label="藏品价值"
                                          prop="collectionClassification">
                                <el-select clearable style="width: 100% "
                                           v-model="ruleForm.collectionClassification"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in collectionClassificationList"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>

                    <el-col :span="23">
                        <el-form-item label="藏品简介" prop="introduce">
                            <Editor :disMenus="'video'" :editortext="ruleForm.introduce" ref="editor"
                                    @changeHtml="noticeGetEditor"></Editor>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">
                        <el-form-item label="备注" prop="remark">
                            <Editor :disMenus="'video'" :editortext="ruleForm.remark" ref="editor1"
                                    @changeHtml="noticeGetRemark"></Editor>
                        </el-form-item>
                    </el-col>
                </div>

                <!--图片上传展示-->
                <div v-show="activeName==2 && ruleForm.pictureList.length !=0">
                    <el-col>
                        <el-table
                                :data="ruleForm.pictureList"
                                size="small"
                                height="440"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    prop="imgUrl"
                                    label="图片"
                                    width="150">
                                <template slot-scope="scope">
                                    <div>
                                        <el-image
                                                v-loading="scope.row.losd"
                                                @load="loajdi(scope.row)"
                                                style="width: 100px; height: 100px"
                                                :src="scope.row.imgUrl"
                                                fit="contain"></el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileName"
                                    label="文件名称"
                                    width="200">
                                <template slot-scope="scope">
                                    <el-form-item :label-width="'auto'" :prop="`pictureList.${scope.$index}.fileName`"
                                                  :rules="[{required: true, message: '请输入标题', trigger: 'blur'}]">
                                        <el-input size="small" v-model="scope.row.fileName"
                                                  placeholder="请输入标题"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileFormat"
                                    show-overflow-tooltip
                                    label="文件格式">
                            </el-table-column>
                            <el-table-column
                                    prop="fileSize"
                                    show-overflow-tooltip
                                    label="文件大小（MB）">
                            </el-table-column>
                            <el-table-column
                                    prop="videoSize"
                                    show-overflow-tooltip
                                    label="分辨率">
                            </el-table-column>
                            <el-table-column
                                    label="顺序"
                                    type="sequenceNumber"
                                    width="150">
                                <template slot-scope="scope">
                                    <el-form-item :label-width="'auto'"
                                                  :prop="`pictureList.${scope.$index}.sequenceNumber`"
                                                  :rules="[{required: true, message: '请输入排序', trigger: 'blur'}]">
                                        <el-input size="small" v-model="scope.row.sequenceNumber"
                                                  @blur="handleChangejishu(scope.row)"
                                        label="描述文字"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="上传时间"
                                    type="createDate"
                                    width="100">
                                <template slot-scope="scope">
                                    {{ scope.row.createDate | formatDate}}
                                </template>
                            </el-table-column>
                            <el-table-column width="200" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text" v-if="scope.row.fileShow == 0"
                                            size="mini" @click="mainDisplay(scope.row.fileName,2)">设为主显
                                    </el-button>
                                    <el-button
                                            v-else type="text"
                                            size="mini" @click="scope.row.fileShow = 0">取消主显
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="mini" @click="deletesshuj(scope.row,2)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
                <!--留白-->
                <div v-show="activeName==2 && ruleForm.pictureList.length ==0" class="liubai"></div>

                <!--三维模型-->
                <div v-show="activeName==3 && ruleForm.threeList.length !=0">
                    <el-col>
                        <el-table
                                :data="ruleForm.threeList"
                                size="small"
                                height="440"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    prop="modelType"
                                    show-overflow-tooltip
                                    label="数据分级">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.modelType == 1 ? '高模' : scope.row.modelType == 2 ? '中模' : '低模'}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="modelType"
                                    show-overflow-tooltip
                                    label="文件类型">
                                <template slot-scope="scope">
                                    <div>三维资源</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileName"
                                    label="文件名称"
                                    width="200">
                                <template slot-scope="scope">
                                    <el-form-item :label-width="'auto'" :prop="`threeList.${scope.$index}.fileName`"
                                                  :rules="[{required: true, message: '请输入标题', trigger: 'blur'}]">
                                        <el-input size="small" v-model="scope.row.fileName"
                                                  placeholder="请输入标题"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column-->
                            <!--                                    prop="fileFormat"-->
                            <!--                                    show-overflow-tooltip-->
                            <!--                                    label="文件格式">-->
                            <!--                            </el-table-column>-->
                            <el-table-column
                                    prop="fileSize"
                                    show-overflow-tooltip
                                    label="文件大小（MB）">
                            </el-table-column>
                            <el-table-column width="200" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text" v-if="scope.row.fileShow == 0"
                                            size="mini" @click="mainDisplay(scope.row.fileName,3)">设为主显
                                    </el-button>
                                    <el-button
                                            v-else type="text"
                                            size="mini" @click="scope.row.fileShow = 0">取消主显
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="mini" @click="deletesshuj(scope.row,3)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
                <div v-show="activeName==3 && ruleForm.threeList.length ==0" class="liubai"></div>

                <!--音频-->
                <div v-show="activeName==4 && ruleForm.audioList.length !=0">
                    <el-col>
                        <el-table
                                :data="ruleForm.audioList"
                                size="small"
                                height="440"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    prop="imgUrl"
                                    label="音频"
                                    width="350"
                            >
                                <template slot-scope="scope">
                                    <audio
                                            controls preload
                                            :src="scope.row.imgUrl"
                                    ></audio>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileName"
                                    label="文件名称"
                                    width="200">
                                <template slot-scope="scope">
                                    <el-form-item :label-width="'auto'" :prop="`audioList.${scope.$index}.fileName`"
                                                  :rules="[{required: true, message: '请输入标题', trigger: 'blur'}]">
                                        <el-input size="small" v-model="scope.row.fileName"
                                                  placeholder="请输入标题"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileFormat"
                                    show-overflow-tooltip
                                    label="文件格式">
                            </el-table-column>
                            <el-table-column
                                    prop="fileSize"
                                    show-overflow-tooltip
                                    label="文件大小（MB）">
                            </el-table-column>
                            <el-table-column
                                    label="上传时间"
                                    type="createDate"
                                    width="100">
                                <template slot-scope="scope">
                                    {{ scope.row.createDate | formatDate}}
                                </template>
                            </el-table-column>
                            <el-table-column width="200" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text" v-if="scope.row.fileShow == 0"
                                            size="mini" @click="mainDisplay(scope.row.fileName,4)">设为主显
                                    </el-button>
                                    <el-button
                                            v-else type="text"
                                            size="mini" @click="scope.row.fileShow = 0">取消主显
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="mini" @click="deletesshuj(scope.row,4)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
                <div v-show="activeName==4 && ruleForm.audioList.length ==0" class="liubai"></div>

                <!--视频-->
                <div v-show="activeName==5 && ruleForm.videoList.length !=0">
                    <el-col>
                        <el-table
                                :data="ruleForm.videoList"
                                size="small"
                                height="440"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    prop="imgUrl"
                                    label="视频"
                                    width="100"
                            >
                                <template slot-scope="scope">
                                    <el-button type="text" @click="videoPlay(scope.row.imgUrl)"
                                               icon="el-icon-video-play"></el-button>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileName"
                                    label="文件名称"
                                    width="200">
                                <template slot-scope="scope">
                                    <el-form-item :label-width="'auto'" :prop="`videoList.${scope.$index}.fileName`"
                                                  :rules="[{required: true, message: '请输入标题', trigger: 'blur'}]">
                                        <el-input size="small" v-model="scope.row.fileName"
                                                  placeholder="请输入标题"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="fileFormat"
                                    show-overflow-tooltip
                                    label="文件格式">
                            </el-table-column>
                            <!--                            <el-table-column-->
                            <!--                                    prop="videLength"-->
                            <!--                                    show-overflow-tooltip-->
                            <!--                                    label="视频时长">-->
                            <!--                            </el-table-column>-->
                            <!--                            <el-table-column-->
                            <!--                                    prop="videoSize"-->
                            <!--                                    show-overflow-tooltip-->
                            <!--                                    label="视频分辨率">-->
                            <!--                            </el-table-column>-->
                            <el-table-column
                                    prop="fileSize"
                                    show-overflow-tooltip
                                    label="文件大小（MB）">
                            </el-table-column>
                            <el-table-column
                                    label="上传时间"
                                    type="createDate"
                                    width="100">
                                <template slot-scope="scope">
                                    {{ scope.row.createDate | formatDate}}
                                </template>
                            </el-table-column>
                            <el-table-column width="200" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text" v-if="scope.row.fileShow == 0"
                                            size="mini" @click="mainDisplay(scope.row.fileName,5)">设为主显
                                    </el-button>
                                    <el-button
                                            v-else type="text"
                                            size="mini" @click="scope.row.fileShow = 0">取消主显
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="mini" @click="deletesshuj(scope.row,5)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
                <!--留白-->
                <div v-show="activeName==5 && ruleForm.videoList.length ==0" class="liubai"></div>

                <div style="text-align: center;margin-top: 10px">
                    <el-button size="small" type="primary" @click="submitForm()">
                        下一步
                    </el-button>
                    <!--                <el-button size="small" type="primary" @click="submitForm()">-->
                    <!--                    保存-->
                    <!--                </el-button>-->
                    <el-button size="small" @click="returnSuperior()">返回</el-button>
                </div>
            </el-row>
        </el-form>
        <video v-show="videoaddress" :src="videoaddress" controls ref="mediaPlayers"></video>
        <uploadPopup ref="uploadpopup" :fileList="fileList" :allowFileNumber="allowFileNumber"
                     :allowFileSize="allowFileSize" :allowFiles="allowFiles" @updatalist=receiveList></uploadPopup>
    </div>
</template>

<script>
    import uploadPopup from '../exhibitionManagement/exhibition/uploadPopup'
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件

    export default {
        name: "addFileControl",
        components: {uploadPopup, Editor},
        data() {
            return {
                dialogVisible: false,
                distinguish: 2, //1编辑,2新增
                activeName: '1',
                yearsLoading: false,
                typeLoading: false,
                ruleForm: {
                    name: '',
                    oldName: '',
                    serialNumber: '',
                    registration: '',
                    collectionType: '',
                    size: '',
                    modality: '',
                    style: '',
                    years: '',
                    year: '',
                    culturalLevel: '',
                    original: '',
                    culturalSource: '',
                    collectionUnit: '',
                    exhibit: '',
                    collection: '',
                    exhibitionaddress: '',
                    fillDate: '',
                    enabledState: '',
                    introduce: '',
                    collectionClassification: '',
                    remark: '',
                    pictureList: [],
                    threeList: [],
                    audioList: [],
                    videoList: [],
                    piece: 1,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入文物名称', trigger: 'blur'},
                    ],
                    serialNumber: [
                        {required: true, message: '请输入附表编号', trigger: 'blur'},
                    ],
                    registration: [
                        {required: true, message: '请输入现登记号', trigger: 'blur'},
                    ],
                    exhibit: [
                        {required: true, message: '请选择展出状态', trigger: 'change'},
                    ],
                    collection: [
                        {required: true, message: '请选择是否藏品', trigger: 'change'},
                    ],
                    enabledState: [
                        {required: true, message: '请选择启用状态', trigger: 'change'},
                    ],
                },
                allowFiles: [], //文件格式
                fileList: [], //已经上传的文件列表
                allowFileNumber: 0,//上传的文件数量
                allowFileSize: 0,//单个文件大小
                levelList: [],
                modalityList: [],
                styleList: [],
                yearsList: [],
                cangTypeList: [],//藏品类型
                originalList: [],
                culturalSourceList: [],
                collectionUnitList: [],
                exhibitionList: [
                    {
                        label: '已结束',
                        id: '2',
                    },
                    {
                        label: '展出中',
                        id: '1',
                    },
                    {
                        label: '待展出',
                        id: '0',
                    }
                ],
                collectionList: [
                    {
                        label: '是',
                        id: '1',
                    },
                    {
                        label: '否',
                        id: '0',
                    }
                ],
                enableList: [
                    {
                        label: '启用',
                        id: '1',
                    },
                    {
                        label: '禁用',
                        id: '0',
                    }
                ],
                videoaddress: '',
                collectionClassificationList: [
                    {
                        label: '镇馆之宝',
                        id: '1',
                    },
                    {
                        label: '精品文物',
                        id: '2',
                    },
                    {
                        label: '一般文物',
                        id: '3',
                    },
                ],

                pictureNumber: 20, //图片上传数量
                pictureSize: 0,//图片传大小M
                pictureFiles: ['png', 'bmp', 'jpg', 'jpeg', 'gif'],//图片类型

                //三维
                threeNumber: 1,
                threeSize: 0,
                threeFiles: ['stl', 'rar', '7z', 'zip', 'apz', 'ar', 'bz', 'car', 'dar', 'cpgz', 'ha', 'hbc', 'hbc2', 'hbe', 'hpk', 'hyp', 'rar4', 'jar', 'cab', 'zipx', 'bzip2'],

                //音频
                audioNumber: 10,
                audioSize: 100,
                audioFiles: ['mp3'],

                //视频
                videoNumber: 10,
                videoSize: 100,
                videoFiles: ['mp4'],


            }
        },
        mounted() {
            const that = this
            window.onresize = function () {
                if (!that.checkFull()) {
                    that.videoaddress = false;
                    // 退出全屏后要执行的动作
                    console.log("退出全屏")
                } else {
                    console.log("全屏")
                }
            }
            if (this.$route.query.num == 6) { //预览返回
                this.ruleForm = this.$route.query.list;
                console.log(this.ruleForm)
            }
            //编辑
            if (this.$route.query.id && this.$route.query.id != 0) {
                this.init(this.$route.query.id)
            }

            //文物各项分类
            this.getCulturalLevelList()
            this.getCollectionUnitList()
            this.getModalityList()
            this.getStyleList()
            this.getYearsList()
            this.getOriginalList()
            this.getCulturalSourceList()
            // this.getCollonUnitList()
            this.getCulturalTypeList()
        },
        methods: {
            init(id) {
                //获取文件详情
                this.$axios(this.api.threeDFileControl.getById + id, {}, 'get').then(res => {
                    this.ruleForm = res.data;
                    this.ruleForm.pictureList.forEach(item => {
                        this.$set(item, 'losd', true)
                    })
                    this.sddShuju(this.collectionUnitList, res.data.collectionUnit, res.data.collectionUnitName)
                    this.sddShuju(this.cangTypeList, res.data.collectionType, res.data.collectionTypeName)
                    this.sddShuju(this.levelList, res.data.culturalLevel, res.data.culturalLevelName)
                    this.sddShuju(this.originalList, res.data.original, res.data.originalName)
                    this.sddShuju(this.modalityList, res.data.modality, res.data.modalityName)
                    this.sddShuju(this.styleList, res.data.style, res.data.styleName)
                    this.sddShuju(this.yearsList, res.data.years, res.data.yearsName)
                    this.sddShuju(this.culturalSourceList, res.data.culturalSource, res.data.culturalSourceName)
                })
            },

            //添加数据
            sddShuju(list, id, name) {
                let shujuList = list.filter(item => {
                    return item.id == id
                })
                if (shujuList.length == 0) {
                    list.push({
                        id: id,
                        name: name,
                    })
                }
            },

            checkFull() {
                // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
                var isFull = document.mozFullScreen || document.fullScreen || document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled
                if (isFull === undefined) {
                    isFull = false
                }
                return isFull;
            },

            handleChangejishu(row, index) {
                if (row.sequenceNumber != '' && row.sequenceNumber) {
                    row.sequenceNumber = row.sequenceNumber.replace(/\D/g, '').substring(0, 4);
                    console.log(row.sequenceNumber)
                    let repeat = this.ruleForm.pictureList.filter(item => {
                        return item.sequenceNumber == row.sequenceNumber
                    })
                    if (repeat.length >= 2 && row.sequenceNumber != '') {
                        row.sequenceNumber = '';
                        this.$message.error('排序不能重复')
                    }
                }
            },

            //关闭弹窗
            handleClose() {
                this.$refs['ruleForm'].resetFields();
                this.dialogVisible = false;
                this.ruleForm.pictureList = [];
                this.ruleForm.threeList = [];
                this.ruleForm.audioList = [];
                this.ruleForm.videoList = [];
                this.activeName = '1';
            },

            handleClick(tab, event) {
                // console.log(tab, eve nt);
            },

            // 获取文物简介富文本
            noticeGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.introduce = data;
            },

            //备注富文本
            noticeGetRemark(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.remark = data;
            },

            //上传 num2为图片上传 3三维模型
            upDate(num) {
                this.$refs.uploadpopup.uploadPath = 'cultural',
                    this.$refs.uploadpopup.zhantingImg = false;
                let that = this
                if (num == 2) {
                    that.allowFiles = that.pictureFiles;
                    that.fileList = this.ruleForm.pictureList;
                    that.allowFileNumber = that.pictureNumber;
                    that.allowFileSize = that.pictureSize;
                } else if (num == 3) {
                    that.allowFiles = that.threeFiles;
                    that.fileList = this.ruleForm.threeList;
                    that.allowFileNumber = that.threeNumber;
                    that.allowFileSize = that.threeSize;
                } else if (num == 4) {
                    that.allowFiles = that.audioFiles;
                    that.fileList = this.ruleForm.audioList;
                    that.allowFileNumber = that.audioNumber;
                    that.allowFileSize = that.audioSize;
                } else if (num == 5) {
                    that.allowFiles = that.videoFiles;
                    that.fileList = this.ruleForm.videoList;
                    that.allowFileNumber = that.videoNumber;
                    that.allowFileSize = that.videoSize;
                }
                that.$refs.uploadpopup.numb = num;
                that.$refs.uploadpopup.dialogVisible = true;
                that.$refs.uploadpopup.uploading = true;
            },

            //获取图片尺寸
            getImageSize(address) {
                // 创建实例对象
                var img = new Image();
                // 图片地址
                img.src = address;
                return new Promise((resolve, reject) => {
                    img.onload = function () {
                        let res = `${img.width} * ${img.height}`;
                        resolve({
                            res,
                        });
                    }
                })
            },

            //获取视频分辨率
            getvideoSize(url) {
                var video = this.$refs.mediaPlayers;
                video.src = url;
                var size = '';
                return new Promise((resolve, reject) => {
                    video.oncanplay = function () {
                        size = video.videoWidth + '*' + video.videoHeight
                        resolve({
                            size,
                        });
                    }
                })
            },

            //获取视频长度
            videoUpload(url) {
                let that = this;
                var audio = new Audio(url);
                var res = 0;
                return new Promise((resolve, reject) => {
                    audio.addEventListener("loadedmetadata", function (e) {
                        res = that.formatSeconds(parseInt(audio.duration)); // 通过添加监听来获取视频总时长字段，即duration
                        resolve({
                            res,
                        });
                    });
                })
            },
            formatSeconds(value) {
                var secondTime = parseInt(value);// 秒
                var minuteTime = 0;// 分
                var hourTime = 0;// 小时
                hourTime = parseInt(secondTime / 60 / 60)
                minuteTime = parseInt(secondTime / 60 % 60)
                secondTime = parseInt(secondTime % 60)

                if (minuteTime == 0) {
                    hourTime = '00'
                } else if ((hourTime + '').length == 1) {
                    hourTime = '0' + hourTime
                }
                if (minuteTime == 0) {
                    minuteTime = '00'
                } else if ((minuteTime + '').length == 1) {
                    minuteTime = '0' + minuteTime
                }
                if (secondTime == 0) {
                    secondTime = '00'
                } else if ((secondTime + '').length == 1) {
                    secondTime = '0' + secondTime
                }
                var result = hourTime + ":" + minuteTime + ":" + secondTime;

                return result;
            },

            //获取音频播放时长
            getAudioDate(url) {
                let audio = document.createElement('audio')
                audio.src = url;
                return new Promise((resolve, reject) => {
                    audio.addEventListener('canplay', function () {
                        console.log(audio.duration)
                        resolve({
                            data: audio.duration,
                        })
                    })
                })
            },

            //接受上传数据
            receiveList(arry) {
                if (arry[1] == 2) {
                    this.ruleForm.pictureList = arry[0];
                    this.ruleForm.pictureList.forEach(async (item, index) => {
                        item.culturalType = 1
                        if (!item.imgSize) {
                            this.$set(item, 'sequenceNumber', '')
                            this.$set(item, 'losd', true)
                            await this.getImageSize(item.imgUrl).then(res => {
                                console.log(res)
                                this.$set(item, 'videoSize', res.res)
                            })
                            // await this.videoUpload(item.imgUrl).then(res => {
                            //     this.$set(item, 'imgSize', res.res)
                            // })
                        }
                    })
                } else if (arry[1] == 3) {
                    console.log(arry)
                    arry[0].forEach(item => {
                        item.culturalType = 0
                    })
                    this.ruleForm.threeList = arry[0];
                } else if (arry[1] == 4) {
                    arry[0].forEach(item => {
                        item.culturalType = 2
                        this.getAudioDate(item.imgUrl).then(res => {
                            this.$set(item, 'audioTime', res.data)
                            console.log(res.data)
                        })
                    })
                    this.ruleForm.audioList = arry[0];
                } else if (arry[1] == 5) {
                    arry[0].forEach(async item => {
                        item.culturalType = 3
                        console.log(item)
                        if (!item.videosize) {
                            console.log(item)
                            await this.videoUpload(item.imgUrl).then(res => {
                                this.$set(item, 'videlength', res.res)
                            })
                            await this.getvideoSize(item.imgUrl).then(res => {
                                this.$set(item, 'videoSize', res.size)
                                console.log(res.size)
                            })
                        }

                    })
                    this.ruleForm.videoList = arry[0];
                    console.log(this.ruleForm.videoList)
                }
            },

            //设为主显 num2为图片上传 3三维模型
            mainDisplay(fileName, num) {
                if (num == 2) {
                    this.ruleForm.pictureList.forEach(item => {
                        if (item.fileName == fileName) {
                            item.fileShow = 1;
                        }
                    })
                } else if (num == 3) {
                    this.ruleForm.threeList.forEach(item => {
                        if (item.fileName == fileName) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                } else if (num == 4) {
                    this.ruleForm.audioList.forEach(item => {
                        if (item.fileName == fileName) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                } else if (num == 5) {
                    this.ruleForm.videoList.forEach(item => {
                        if (item.fileName == fileName) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                }
            },

            //删除
            deletesshuj(row, num) {
                let that = this;
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //删除列表
                    console.log(row)
                    if (num == 2) {
                        that.ruleForm.pictureList = that.ruleForm.pictureList.filter(item => item.fileName != row.fileName)
                    } else if (num == 3) {
                        that.ruleForm.threeList = that.ruleForm.threeList.filter(item => item.fileName != row.fileName)
                    } else if (num == 4) {
                        that.ruleForm.audioList = that.ruleForm.audioList.filter(item => item.fileName != row.fileName)
                    } else if (num == 5) {
                        that.ruleForm.videoList = that.ruleForm.videoList.filter(item => item.fileName != row.fileName)
                    }
                }).catch(() => {
                });
            },

            //视频播放
            videoPlay(address) {
                this.videoaddress = address;
                // this.videoaddress = 'http://10.10.10.182/imgaeUploading/914887437988134912/%E7%BB%88%E7%89%A9%E8%AF%AD/%E7%BB%88%E7%89%A9%E8%AF%AD(1).mp4';
                // 获取要全屏的组件节点
                var element = this.$refs["mediaPlayers"];
                // 异步发起全屏请求 适配多浏览器全屏事件 为火狐 谷歌 ie safari
                // requestFullscreen() 方法用于发出异步请求使元素进入全屏模式 但并不保证元素一定能够进入全屏模式
                // 因此需要进行适配多方法
                var requestMethod =
                    element.requestFullScreen ||
                    element.webkitRequestFullScreen ||
                    element.mozRequestFullScreen ||
                    element.msRequestFullScreen;
                if (requestMethod) {
                    // 如果全屏请求通过需要改变返回的fullscreenchange事件失效，因此重新指向this
                    requestMethod.call(element);
                } else if (typeof window.ActiveXObject !== "undefined") { // 这里本意是对ie的全屏活动做适配
                    // 调用ie游览器工具
                    var wscript = new ActiveXObject("WScript.Shell");
                    // 如果本地程序不存在，则对ie执行F11全屏事件
                    if (wscript !== null) {
                        wscript.SendKeys("{F11}");
                    }
                }
            },

            //切换是否藏品
            switchState(e) {
                if (e != 1) {
                    this.ruleForm.collectionClassification = '';
                    console.log(this.ruleForm.collectionClassification)
                }
            },

            //获取文物分级列表
            getCulturalLevelList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 1
                }, 'get').then(res => {
                    this.levelList = res.data;
                })
            },

            //形式列表
            getModalityList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 2
                }, 'get').then(res => {
                    this.modalityList = res.data;
                })
            },

            //样式列表
            getStyleList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 3
                }, 'get').then(res => {
                    this.styleList = res.data;
                })
            },

            //年代列表
            getYearsList(query) {
                console.log(query)
                this.yearsLoading = true;
                this.$axios(this.api.culturalRelics.selsetQueryAll, {
                    dictType: 4,
                    name: query,
                    enabledState: 1,
                }, 'post').then(res => {
                    this.yearsLoading = false
                    this.yearsList = res.data;
                })
            },

            //原件/复制件列表
            getOriginalList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 5
                }, 'get').then(res => {
                    this.originalList = res.data;
                })
            },

            //文物来源列表
            getCulturalSourceList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 6
                }, 'get').then(res => {
                    this.culturalSourceList = res.data;
                })
            },

            //藏品类型
            getCulturalTypeList(query) {
                console.log(query)
                this.typeLoading = true
                this.$axios(this.api.culturalRelics.selsetQueryAll, {
                    dictType: 9,
                    name: query,
                    enabledState: 1,
                }, 'post').then(res => {
                    this.cangTypeList = res.data;
                    this.typeLoading = false
                })
            },

            //获取文物分级列表
            getCollectionUnitList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 8
                }, 'get').then(res => {
                    this.collectionUnitList = res.data;
                })
            },

            // //获取文物展厅列表
            // getCollonUnitList() {
            //     this.$axios(this.api.culturalRelics.getZanTingList, {}, 'get').then(res => {
            //         this.exhibitionList = res.data;
            //     })
            // },


            //下一步
            submitForm() {
                // console.log(this.$route.query.num)
                let num = 2//1编辑2新增
                if (this.$route.query.num == 6) {
                    num = 1
                } else {
                    num = this.$route.query.num
                }
                let pageNo = this.$route.query.pageNo
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.name.trim() == '') {
                            this.$message.error('请输入名称')
                            return
                        }
                        if (this.ruleForm.serialNumber.trim() == '') {
                            this.$message.error('请输入附表编号')
                            return
                        }
                        if (this.ruleForm.registration.trim() == '') {
                            this.$message.error('请输入现登记号')
                            return
                        }
                        // console.log('预览')
                        // let xinxiList = {...this.ruleForm};
                        // this.$delete(xinxiList, 'pictureList')
                        // this.$delete(xinxiList, 'threeList')
                        // this.$delete(xinxiList, 'audioList')
                        // this.$delete(xinxiList, 'videoList')
                        // let culturalFiles = [...this.ruleForm.pictureList, ...this.ruleForm.threeList, ...this.ruleForm.audioList, ...this.ruleForm.videoList]
                        sessionStorage.setItem('superior', '/culturalMent/editleFileControl')
                        this.$router.push({
                            name: '藏品详情',
                            params: {
                                list: this.ruleForm,
                                num: num,
                                pageNo: pageNo,
                            },
                        })

                        // this.$axios(this.api.threeDFileControl.save, {
                        //     ...xinxiList,
                        //     culturalFiles: culturalFiles,
                        // }, 'post').then(res => {
                        //
                        // })
                    } else {
                        console.log('error submit!!');
                        // this.activeName = 1
                        this.$message.info('请输入完整信息！')
                        return false;
                    }
                });
            },

            //返回藏品列表
            returnSuperior() {
                let pageNo = this.$route.query.pageNo
                this.$router.push({
                    path: '/culturalMent/3DFileControl',
                    query: {
                        pageNo: pageNo,
                    }
                })
            },

            loajdi(row) {
                row.losd = false;
            },
        }
    }
</script>

<style scoped>
    .fileconfig {
        font-size: 12px;
        margin-top: 10px;
    }

    .biaoti {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .formPage {
        background: transparent !important;
        padding: 0 !important;
    }

    .minmoxing {
        margin-bottom: 10px;
        padding: 20px;
        border-radius: 5px;
        background: #ffffff;
    }

    .zhne {
        margin-bottom: 0px;
    }

    .liubai {
        height: calc(100vh - 450px);
        /*height: 260px;*/
        margin-bottom: 0;
    }

    .updatea {
        padding-top: 20px;
        height: 100px;
        text-align: center;
    }
</style>