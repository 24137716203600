/**
 * 全站路由配置
 * 代码中路由统一使用path属性跳转
 */
import Vue from 'vue'
import Router from 'vue-router'
import {request} from '@/utils/httpRequest'
import api from  '@/utils/api'
import {isURL} from '@/utils/validate'
import {refreshDictList} from '@/utils/dictUtils'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
// 开发环境不使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)
// 全局路由
const globalRoutes = [
    {path: '/login', component: _import('modules/sys/login/login'), name: 'login', meta: {title: '登录'}},
    {path: '/home', component: _import('home'), name: 'home', meta: {title: '首页'}},
    {path: '/bigData/overview', component: _import('modules/bigData/overview'), name: 'overview', meta: {title: '总览'}},
]

// 主入口路由
const mainRoutes = {
    path: '/',
    component: _import('main'),
    name: 'main',
    redirect: {name: 'login'},
    meta: {title: '整体布局'},
    children: [
        {path: '/404', component: _import('common/404'), name: '404', meta: {title: '404未找到'}},
        {path: '/exhibitionManagement/exhibition/exhibitionDetails', component: _import('modules/exhibitionManagement/exhibition/exhibitionDetails'), name: '展厅详情', meta: {title: '展厅详情'}},
        {path: '/exhibitionManagement/exhibition/exhibitionAdd', component: _import('modules/exhibitionManagement/exhibition/exhibitionAdd'), name: '展厅新增', meta: {title: '展厅新增'}},
        {path: '/exhibitionManagement/exhibition/exhibitionEdit', component: _import('modules/exhibitionManagement/exhibition/exhibitionEdit'), name: '展厅编辑', meta: {title: '展厅编辑'}},

        {path: '/culturalMent/collectionDetails', component: _import('modules/culturalMent/collectionDetails'), name: '藏品详情', meta: {title: '藏品详情'}},
        {path: '/culturalMent/addFileControl', component: _import('modules/culturalMent/addFileControl'), name: '新增藏品', meta: {title: '新增藏品'}},
        {path: '/culturalMent/editleFileControl', component: _import('modules/culturalMent/editleFileControl'), name: '藏品编辑', meta: {title: '藏品编辑'}},

        {path: '/exhibitionManagement/management/exhibitionDetails', component: _import('modules/exhibitionManagement/management/exhibitionDetails'), name: '展览详情', meta: {title: '展览详情'}},
        {path: '/exhibitionManagement/management/editexhibitionA', component: _import('modules/exhibitionManagement/management/editexhibitionA'), name: '展览编辑', meta: {title: '展览编辑'}},
        {path: '/exhibitionManagement/management/addexhibitionA', component: _import('modules/exhibitionManagement/management/addexhibitionA'), name: '展览新增', meta: {title: '展览新增'}},

        {path: '/activity/releaseActivities/edit', component: _import('modules/activity/releaseActivities'), name: '编辑活动', meta: {title: '编辑活动'}},
        {path: '/activity/releaseActivities/view', component: _import('modules/activity/releaseActivities'), name: '查看活动', meta: {title: '查看活动'}},
    ]
    }

const router = new Router({
    mode: 'history',  //去掉url中的#
    // mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
    routes: globalRoutes.concat(mainRoutes)
})

// 添加动态(菜单)路由
router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem("token")
    if (!token || !/\S/.test(token)) { // token为空，跳转到login登录
        if (process.env.VUE_APP_SSO_LOGIN === 'true') { // 如果是单点登录
            if (to.name === 'casLogin') { // 单点登录跳转页面获取token
                next()
            } else {
                window.location.href = `${process.env.VUE_APP_CAS_SERVER}/login?service=${process.env.VUE_APP_CLIENT_LOGIN}`
            }
        } else {
            if (fnCurrentRouteType(to, globalRoutes) === 'global') {
                next()
            } else {
                next({name: 'login'})
            }
        }
    } else if (router.options.isAddDynamicMenuRoutes) { // 如果已经包含权限
        next()
    } else {
        request(api.auth.getMenus, {}, 'get').then( data => {
            //request(api.jeeplusSys.getMenus, {}, 'get').then( data => {
            if (data && data.status) {
                fnAddDynamicMenuRoutes(data.data.menuList)
                router.options.isAddDynamicMenuRoutes = true
                sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || '[]'))
                sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || '[]'))
                let dictList = JSON.parse(sessionStorage.getItem('dictList') || '[]')
                if(dictList.length == 0) {
                    refreshDictList();
                }
                next({...to, replace: true})
            } else {
                sessionStorage.setItem('allMenuList', '[]')
                sessionStorage.setItem('permissions', '[]')
                next()
            }
        }).catch((e) => {
            // next({name: 'login'})
            sessionStorage.clear();//清除缓存
            router.push({path: '/'});//返回登录页
            console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
        })
    }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
    let temp = []
    for (let i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return 'global'
        } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
            temp = temp.concat(globalRoutes[i].children)
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
    let temp = []
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].children && menuList[i].children.length >= 1) {
            temp = temp.concat(menuList[i].children)
        }

        if (menuList[i].href && /\S/.test(menuList[i].href)) {
            menuList[i].href = menuList[i].href.replace(/[/]$/, '')
            const route = {
                path: menuList[i].href.split('?')[0],
                component: null,
                name: menuList[i].href.replace(/^\//g, '').replace(/[/]/g, '-').replace(/[?]/g, '-').replace(/&/g, '-').replace(/=/g, '-'),
                meta: {
                    parentIds: menuList[i].parentIds,
                    menuId: menuList[i].id,
                    title: menuList[i].name,
                    isDynamic: true,
                    type: menuList[i].target,
                    affix: menuList[i].affix === '1',
                    iframeUrl: ''
                }
            }
            // url以http[s]://开头, 通过iframe展示
            if (isURL(menuList[i].href)) {
                route.path = '/' + route.path
                route.meta.iframeUrl = menuList[i].href
                route['meta']['type'] = 'iframe'
            } else if (menuList[i].target === 'iframe') {
                route['meta']['iframeUrl'] = `${process.env.VUE_APP_SERVER_URL}${menuList[i].href}`
            } else {
                try {
                    if (menuList[i].href) {
                        route['component'] = _import(`modules${menuList[i].href.split('?')[0]}`) || null
                    }
                } catch (e) {
                    // console.log(e)
                }
            }
            routes.push(route)
        }
    }
    if (temp.length >= 1) {
        fnAddDynamicMenuRoutes(temp, routes)
    } else {
        mainRoutes.name = 'main-dynamic'
        mainRoutes.children = routes
        router.addRoute(mainRoutes)
        router.addRoute({path: '*', redirect: {name: '404'}})
        sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || []))
    }
}

export default router
