<template>
    <el-dialog top="2vh" title="爽约详情" width="70%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
        <div class="dialogBox">
            <el-descriptions title="爽约信息" :column="2" class="m_b1 b_b">
                <el-descriptions-item label="累计爽约次数">{{info.cumulativeNums}}</el-descriptions-item>
                <el-descriptions-item label="爽约时间">{{info.breakAppointmentTime}}</el-descriptions-item>
                <el-descriptions-item label="拉黑触发方式">{{info.blackRule == 0 ? '累计': (info.blackRule == 90 ? '近3月':
                    (info.blackRule == 180 ? '近半年' : (info.blackRule == 365 ? '近1年' : '')))}}
                </el-descriptions-item>
                <el-descriptions-item label="拉黑触发次数">{{info.blackNums}}</el-descriptions-item>
                <el-descriptions-item label="拉黑禁止时长">{{info.blackDay}}</el-descriptions-item>
                <el-descriptions-item label="拉黑状态">{{info.blackState == 0 ? '否':'是'}}</el-descriptions-item>
                <el-descriptions-item label="拉黑时间">{{info.pullBlackTime}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="订单信息" :column="2" class="p_t1 m_b1 b_b">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="平台交易号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    <el-tag size="small" v-if="info.payState">
                        {{$dictUtils.getDictLabel("order_status",info.payState,'')}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="订单类型">
                    {{info.sysTicketingMakeTeamDetailVoList.length ? '团队预约' : '散客预约'}}
                    <el-tooltip class="orderType" effect="dark" content="团队预约只记联系人爽约次数。" placement="top">
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="参观信息" :column="2" class="p_t1 m_b1 b_b">
                <el-descriptions-item label="参观日期">{{info.makeDate}}</el-descriptions-item>
                <el-descriptions-item label="入馆时段">{{info.storehouseTime}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{info.ticketingVerbName}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.ticketingVerbPhone}}</el-descriptions-item>
                <el-descriptions-item label="总价（元）">{{info.countMoney}}</el-descriptions-item>
                <el-descriptions-item label="授权电话">{{info.authorizationPhone}}</el-descriptions-item>
                <el-descriptions-item label="预约状态">
                    {{$dictUtils.getDictLabel("ticketing_state",info.ticketingState,'')}}
                </el-descriptions-item>
            </el-descriptions>
            <div class="el-descriptions__title el-descriptions p_t2 p_b1">门票信息</div>
            <el-table :data="list" border size="small" style="width: 100%">
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cardTypeName" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="证件号码" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingValidationCode" label="票号" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingMoney" label="单价" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorState" label="参观状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("visitor_state",scope.row.ticketingVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingValidationTime" label="参观时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRequestNo" label="退款单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outMoney" label="退款金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="payState" label="退款状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("order_status",scope.row.payState,'-')}}
                    </template>
                </el-table-column>
                <el-table-column prop="outRequestDate" label="退款时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createOutRequestDate" label="退票时间" show-overflow-tooltip></el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                id: null,
                info: {},
                list: []
            }
        },
        methods: {
            init(row) {
                this.id = row.id
                this.visible = true
                this.getData();
            },
            // 获取数据
            getData() {
                this.$axios(this.api.order.sysGetTicketingBreakAppointmentDetail, {id: this.id}).then((res) => {
                    if (res.status) {
                        this.info = res.data
                        if(this.info.sysTicketingMakeTeamDetailVoList.length) {
                            this.list = this.info.sysTicketingMakeTeamDetailVoList
                        } else {
                            this.list = this.info.sysTicketingMakeDetailVoList
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .dialogBox >>> .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
        position: relative !important;
    }
.orderType {
    position: absolute;
    top: 4px;
    left: -20px;
}
</style>
