<template>
    <el-dialog title="服务订单详情" width="80%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="订单信息" :column="2" class="b_b p_b1">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="平台交易号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    {{$dictUtils.getDictLabel("server_orderState",info.serverOrderState,'')}}
                </el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="支付状态">
                    {{$dictUtils.getDictLabel("server_payState",info.serverPayState,'')}}
                </el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.orderPayTime}}</el-descriptions-item>
                <el-descriptions-item label="支付方式">{{$dictUtils.getDictLabel("pay_platform",info.payPlatform,'')}}</el-descriptions-item>
                <el-descriptions-item label="授权电话">{{info.authorizationPhone}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{info.ticketingVerbName}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.ticketingVerbPhone}}</el-descriptions-item>
            </el-descriptions>
            <div class="el-descriptions__title el-descriptions p_t2 p_b1">服务信息</div>
            <el-table :data="info.orderDetailVoList" show-summary :summary-method="getSummaries" border size="small"
                      style="width: 100%">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column prop="ticketingServerName" label="服务名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link type="primary" @click="toDetails(scope.row.ticketingServeId)">{{scope.row.ticketingServerName}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="language" label="规格" width="110" show-overflow-tooltip>
                    <template slot-scope="scope">{{scope.row.age}} {{scope.row.numberOfPeople}} {{scope.row.language}}</template>
                </el-table-column>
                <el-table-column prop="ticketingServerCode" label="券码" width="110"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingServerCodeState" label="券码状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.ticketingServerCodeState != 0">{{$dictUtils.getDictLabel("server_codeState",scope.row.ticketingServerCodeState,'')}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingServerCodeTime" label="使用时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createOutRequestDate" label="退款申请时间" width="140"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRequestNo" label="退款单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="commission" label="退款服务费" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outMoney" label="退款金额（元）" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="payState" label="退款状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("server_payState",scope.row.payState,'-')}}
                    </template>
                </el-table-column>
                <el-table-column prop="outRequestDate" label="退款到账时间" width="140"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingServerMoney" label="价格（元）" width="110"
                                 show-overflow-tooltip></el-table-column>
                <!--<el-table-column prop="ticketingServerMoney" label="实付款（元）" width="120"
                                 show-overflow-tooltip></el-table-column>-->
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
        <!--查看-->
        <ViewService ref="viewService"></ViewService>
    </el-dialog>
</template>

<script>
    import ViewService from '../service/viewService'

    export default {
        components: {ViewService},
        data() {
            return {
                visible: false,
                info: {},
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.$axios(this.api.order.sysGetTicketingServerOrderById + '/' + row.id).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    }
                })
            },
            // 查看服务详情
            toDetails(id) {
                this.$refs.viewService.init(id, 0);
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value)) && index == 12) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = sums[index].toFixed(2) +  ' 元';
                    }
                });
                return sums;
            }
        }
    }
</script>

<style scoped>

</style>
