<template>
    <div class="audifhaui">
        <div class="page formPage" id="collectionLocation">
            <div class="banaojoi1">
                <el-select size="small" v-model="exhibition1" placeholder="请选择展览" @change="exhibition1Select">
                    <el-option
                            v-for="item in exhibition1List"
                            :key="item.id"
                            :label="item.exhibitionName"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select style="margin-left: 20px" size="small" @change="getList" v-model="exhibition2"
                           placeholder="请选择展厅">
                    <el-option
                            v-for="item in exhibition2List"
                            :key="item.id"
                            :label="item.exhibitionName"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <el-row :key="item.id" class="auifbaudf" v-for="(item, index) in list">
                <el-col :span="16">
                    <div class="banaojoi">
                        <el-row>
                            <div class="flex_b_c" style="margin-bottom: 10px">
                                <el-col :span="16" class="flex_b_c">
                                    <el-input
                                            maxlength="50"
                                            placeholder="请输入地图名称（限50字）"
                                            size="small"
                                            style="margin-right: 15px; width: 500px"
                                            v-if="item.bianji"
                                            v-model="item.mapName"
                                    ></el-input>
                                    <!--                                    @blur="preservationMap(index)"-->
                                    <div
                                            style="margin-right: 15px; font-weight: bold; width: 500px"
                                            v-else @dblclick="item.bianji = !item.bianji"
                                    >
                                        {{ item.mapName }}
                                    </div>
                                    <el-button
                                            @click="preservationMap(index)"
                                            size="small"
                                            type="primary"
                                            v-if="item.bianji"
                                    >保存
                                    </el-button>

                                    <el-button
                                            @click="preservationNoMap(index)"
                                            size="small"
                                            type="primary"
                                            v-if="item.bianji && item.id"
                                    >取消
                                    </el-button>
                                    <!--                                    <el-button-->
                                    <!--                                            @click="item.bianji = !item.bianji"-->
                                    <!--                                            size="small"-->
                                    <!--                                            type="primary"-->
                                    <!--                                            v-else-->
                                    <!--                                    >编辑-->
                                    <!--                                    </el-button>-->
                                </el-col>
                                <el-col :span="8">
                                    <div class="flex_b_c tubiaoafd" v-if="item.mapUrl">
                                        <!--                                        <div>-->
                                        <!--                                            <i-->
                                        <!--                                                    @click="reUpload(index)"-->
                                        <!--                                                    class="el-icon-video-play bahjdb ahrwrgad"-->
                                        <!--                                            ></i>-->
                                        <!--                                        </div>-->
                                        <div>
                                            <i
                                                    v-if="!item.bianji || !item.id"
                                                    @click="item.bianji = true"
                                                    class="el-icon-edit bahjdb"
                                            ></i>
                                        </div>
                                        <div>
                                            <i
                                                    @click="newPunctuation(index)"
                                                    class="el-icon-location bahjdb"
                                            ></i>
                                        </div>
                                        <div>
                                            <i
                                                    @click="mapDelete(index)"
                                                    class="el-icon-delete bahjdb"
                                            ></i>
                                        </div>
                                    </div>
                                </el-col>
                            </div>
                        </el-row>
                        <div
                                v-if="item.mapUrl"
                                :ref="'gundong' + index"
                                @wheel.prevent="scrollInit($event, index)"
                                class="tupian"
                        >
                            <div v-if="item.bianji" class="zhezhao">
                                <div>
                                    <el-button
                                            icon="el-icon-upload2"
                                            @click="reUpload(index)"
                                            size="mini"
                                            type="primary"
                                            class="ahrwrgad2"
                                    >重新上传
                                    </el-button>
                                    <!--                                    <i-->
                                    <!--                                            @click="reUpload(index)"-->
                                    <!--                                            class="el-icon-video-play ahrwrgad2"-->
                                    <!--                                    ></i>-->
                                </div>
                            </div>
                            <div
                                    :ref="'huaBu' + index"
                                    :style="{
                  width: item.imgWidth + '%',
                  top: item.imgTop + 'px',
                  left: item.imgLeft + 'px',
                }"
                                    @mousedown.prevent="mousePress($event, index)"
                                    class="tuozhuaiimg"
                            >
                                <!--                                @mouseup.prevent="tupiandianji($event, index)"-->
                                <el-image
                                        :ref="'imagemove' + index"
                                        :src="item.mapUrl"
                                        @mouseup.prevent="tupiandianji($event, index)"
                                        fit="contain"
                                        style="width: 100%"
                                ></el-image>
                                <i
                                        :class="[
                    'el-icon-location',
                    item2.xuanzhong ? 'zhanfua' : 'bahjdb',
                  ]"
                                        :key="item2.x"
                                        :ref="'biaodian' + index + index2"
                                        :style="weizhicomp(item2)"
                                        @mousedown.prevent.stop="movedianji($event, index, index2)"
                                        v-for="(item2, index2) in item.culturalPositionCoordinates"
                                ></i>
                            </div>
                        </div>
                        <div @click="uploadClick(index)" class="banaojoi aufuiadf" v-else>
                            <el-upload
                                    :disabled="initialEntry"
                                    :before-upload="beforeAvatarUpload"
                                    :on-success="handleAvatarSuccess"
                                    :headers="myHeaders"
                                    :multiple="false"
                                    :data="filedata"
                                    :show-file-list="false"
                                    :name="'file'"
                                    :action="$globalUrl() + 'xiangxiu-common/file/upload'"
                                    class="avatar-uploader"
                            >
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div :ref="'rigth' + index" class="banaojoi setw">
                        <div class="flex_b_c">
                            <el-input
                                    v-model="item.culturalRelicName"
                                    placeholder="请输入文物名称"
                                    prefix-icon="el-icon-search"
                                    size="small"
                                    clearable
                                    style="margin-right: 10px"
                            >
                            </el-input>
                            <el-button
                                    :disabled="initialEntry"
                                    @click="getMapPunctuation(item, index,1)"
                                    size="small"
                                    type="primary"
                            >搜索
                            </el-button>
                        </div>
                        <div class="xinzeng" v-if="!item.xinzengs">
                            <el-button
                                    :disabled="initialEntry"
                                    @click="newPunctuation(index)"
                                    icon="el-icon-plus"
                                    size="small"
                                    type="primary"
                            >
                                新增坐标
                            </el-button>
                        </div>
                        <div class="deitXinxi flex_b_c" v-else>
                            <!--                            <el-input-number v-model="item.serialNumber" size="mini" :min="1" :max="10"-->
                            <!--                                             label="描述文字"></el-input-number>-->
                            <el-input v-model="item.serialNumber" @blur="sequentialProcessing(1,index)" style="flex: 1;" type="number" size="mini"
                                      placeholder="请输入陈列顺序0~9999"></el-input>
<!--                            :popper-class="'xuanZeKaung'"-->
<!--                            :popper-append-to-body="false"-->
                            <el-select
                                    style="flex: 1; margin-right: 10px;margin-left: 10px"
                                    placeholder="请选择"
                                    filterable
                                    remote
                                    @focus="remoteMethod('',index)"
                                    :remote-method="(query) => remoteMethod(query, index)"
                                    :loading="item.loading2"
                                    size="mini"
                                    v-model="item.addsele"
                            >
                                <el-option
                                        :key="item.id"
                                        :label="item.name "
                                        :value="item.id"
                                        v-for="item in options"
                                >
                                    <el-tooltip class="item" effect="dark" :content="item.name"
                                                placement="top">
                                        <div class="tanChang">{{ item.name }}
                                        </div>
                                    </el-tooltip>
                                </el-option>
                            </el-select>
                            <el-button
                                    @click="xinzbaocun(item.id, index,item)"
                                    size="mini"
                                    type="primary"
                                    :loading="item.addBiaoDian"
                            >保存
                            </el-button
                            >
                            <el-button @click="xinzNo(item.id, index)" size="mini"
                            >取消
                            </el-button
                            >
                        </div>
                        <div>
                            <el-table
                                    :data="item.culturalPositionCoordinates ? item.culturalPositionCoordinates.filter((item) => item.zhanshi !== true) : []"
                                    v-loading="item.loading"
                                    size="small"
                                    height="410px"
                                    class="table"
                            >
                                <el-table-column prop="name" label="展陈顺序">
                                    <template slot-scope="scope">
                                        <div>
                      <span v-if="!scope.row.xuanzhong">{{
                        scope.row.serialNumber
                      }}</span>
                                            <el-input v-else v-model="scope.row.serialNumber" style="width: 100px"  @blur="sequentialProcessing(2,index,scope.$index)"
                                                      type="number" size="small" placeholder="请输入陈列顺序0~9999"></el-input>
                                            <!--                                            <el-input-number v-else v-model="scope.row.serialNumber" size="mini"-->
                                            <!--                                                             :min="1" :max="10"-->
                                            <!--                                                             label="描述文字"></el-input-number>-->
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="名称" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div class="deitXinxi">
                                            <span v-show="!scope.row.xuanzhong">{{scope.row.fileDate}}</span>
                                            <!--                                            :popper-class="'xuanZeKaung'"-->
                                            <!--                                            :popper-append-to-body="false"-->
                                            <el-select
                                                    v-show="scope.row.xuanzhong"
                                                    placeholder="请选择"
                                                    filterable
                                                    remote
                                                    @focus="remoteMethod('',index,scope.$index)"
                                                    :remote-method="(query) => remoteMethod(query, index,scope.$index)"
                                                    :loading="scope.row.loading3"
                                                    @change="fileSelect"
                                                    size="small"
                                                    v-model="scope.row.fileDate"
                                            >
                                                <el-option
                                                        :key="relic.id"
                                                        :label="relic.name"
                                                        :value="relic.id"
                                                        v-for="relic in options"
                                                >
                                                    <el-tooltip class="item" effect="dark" :content="relic.name"
                                                                placement="top">
                                                        <div class="tanChang">{{ relic.name }}
                                                        </div>
                                                    </el-tooltip>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        fixed="right"
                                        width="150"
                                        label="操作"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                                v-if="!scope.row.xuanzhong"
                                                icon="el-icon-edit"
                                                type="text"
                                                size="mini"
                                                @click="culturalRelicEdit(index, scope.$index)"
                                        >编辑
                                        </el-button>
                                        <el-button
                                                v-if="!scope.row.xuanzhong"
                                                icon="el-icon-delete"
                                                type="text"
                                                size="mini"
                                                @click="culturalRelicDelete(scope.row.id, index)"
                                        >删除
                                        </el-button>
                                        <el-button
                                                v-if="scope.row.xuanzhong"
                                                icon="el-icon-success"
                                                type="text"
                                                size="mini"
                                                @click="culturalRelicSave(index, scope.$index)"
                                        >保存
                                        </el-button>
                                        <el-button
                                                v-if="scope.row.xuanzhong"
                                                icon="el-icon-error"
                                                type="text"
                                                size="mini"
                                                @click="culturalRelicNoEdit(item, index, scope.$index)"
                                        >取消
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination
                                    :pager-count="3"
                                    :current-page.sync="item.current"
                                    :page-size.sync="item.pageSize"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :total="item.total"
                                    @current-change="
                  (val) => currentChangeHandle(val, item.id, index)
                "
                                    background
                                    layout="total, prev, pager, next"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div style="margin-left: 10px;margin-bottom: 20px">
                <!--                this.list.length !=5-->
                <el-button
                        v-if="!mapAddType"
                        icon="el-icon-plus"
                        @click="addPunctuation()"
                        size="small"
                        type="primary"
                >新增地图
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "collectionLocation",
        data() {
            return {
                initialEntry:true,
                exhibition1: '',//展览
                exhibition1List: [],
                exhibition2: '',//展厅
                exhibition2List: [],
                sessList:[{
                    id: '',
                    mapName: "",
                    addBiaoDian:false,//保存点击
                    bianji: true, //是否编辑图片名字
                    //拖拽图片的样式
                    imgWidth: 100, //图片放大宽度
                    imgTop: 0, //图片顶部的定位
                    imgLeft: 0, //图片左边的定位
                    mapUrl: "", //图片地址
                    xinzengs: false, //是否处于新增标点的状态
                    loading: false, //列表加载
                    addsele: "", //新增数据选择的文物
                    current: 1, //第几页
                    oldCurrent: 1,//老分页
                    pageSize: 10, //一页多少条数据
                    total: 0, //数据总量
                    culturalPositionCoordinates: [], //地图标点
                    culturalRelicName: "", //右边搜索栏文物名称
                    movedianIndex: "", //正在编辑的点的索引
                    movedianIndex2: "", //正在编辑的点的索引
                    serialNumber: 1,
                    operationTimeStart: 0,
                    operationTimeEnd: 0,

                    loading2: false,//搜索状态
                    serialNumber: '',//编号
                    // culturalPositionCoordinates: [
                    //     {
                    //         xAxis: 50, //坐标百分比
                    //         yAxis: 50,
                    //         top: 50, //鼠标拖动时临时地址存储点px
                    //         left: 50,
                    //         xuanzhong: false, //是否选中该点
                    //         tuodng: false, //点位是否使用px作为坐标点
                    // zhanshi:false,//新增还未保存的点显示
                    //   mouseLeftPress:'false',//用于解决鼠标左键长按和图片放大冲突问题
                    //     },
                    // ],
                }],
                list: [
                    {
                        id: '',
                        mapName: "",
                        bianji: true, //是否编辑图片名字
                        addBiaoDian:false,//保存点击
                        //拖拽图片的样式
                        imgWidth: 100, //图片放大宽度
                        imgTop: 0, //图片顶部的定位
                        imgLeft: 0, //图片左边的定位
                        mapUrl: "", //图片地址
                        xinzengs: false, //是否处于新增标点的状态
                        loading: false, //列表加载
                        addsele: "", //新增数据选择的文物
                        current: 1, //第几页
                        oldCurrent: 1,//老分页
                        pageSize: 10, //一页多少条数据
                        total: 0, //数据总量
                        culturalPositionCoordinates: [], //地图标点
                        culturalRelicName: "", //右边搜索栏文物名称
                        movedianIndex: "", //正在编辑的点的索引
                        movedianIndex2: "", //正在编辑的点的索引
                        serialNumber: 1,
                        operationTimeStart: 0,
                        operationTimeEnd: 0,
                    }
                ],
                mapAddType: true, //是否处于新增地图的状态
                myHeaders: {
                    Authorization: sessionStorage.getItem("token"),
                    satoken: sessionStorage.getItem("token"),
                },
                fileFormat: ["jpg", "png", "jpeg"],
                filedata: {
                    uploadPath: "guided",
                },
                mapIndex: "",

                //拖拽图片宽高
                tuozhuaiImageWidth: "",
                tuozhuaiImageHeight: "",
                //图片屏幕左边和上边的距离
                tuozhuaiImageLeft: "",
                tuozhuaiImageTop: "",

                //记录鼠标按下的点
                yuanX: "",
                yuanY: "",

                //图片可视区域宽高
                visualWidth: "",
                visualHeight: "",
                //区域距离屏幕左边和上边的距离
                visualLift: "",
                visualTop: "",

                //未标点的文物信息
                options: [],
                filselects: false,
            };
        },
        mounted() {
            this.mapAddType = true;
            this.getExhibition1()
        },
        methods: {
            //获取所有展览信息
            getExhibition1() {
                this.$axios(this.api.mapGuide.queryAll, {
                    flag:2,
                }, "get").then((res) => {
                    if (res.status) {
                        this.exhibition1List = res.data;
                    }
                })
            },

            //选中展览，获取该展览下的所有展厅信息
            exhibition1Select() {
                this.$axios(this.api.mapGuide.getByIdByExhibitionManagementId + this.exhibition1, {}, "get").then((res) => {
                    if (res.status) {
                        this.exhibition2List = res.data;
                        this.exhibition2 = null;
                    }
                })
            },

            //页面地图数据
            getList(currt,indexe) {
                this.initialEntry = false;
                this.$axios(this.api.mapGuide.queryByExhibitionId, {
                    exhibitionId: this.exhibition2,
                }, "post").then((res) => {
                    this.mapAddType = false;
                    if (res.data.length == 0){
                        this.list = JSON.parse(JSON.stringify(this.sessList))
                        this.mapAddType = true;
                    }else {
                        this.list = res.data;
                        this.mapAddType = false;
                        this.list.forEach((item, index) => {
                            this.$set(item, "bianji", false);
                            this.$set(item, "imgWidth", 100);
                            this.$set(item, "imgTop", 0);
                            this.$set(item, "imgLeft", 0);
                            this.$set(item, "addsele", "");
                            this.$set(item, "pageSize", 10);
                            this.$set(item, "total", 1000000000000000000000000000);
                            this.$set(item, "xinzengs", false);
                            this.$set(item, "loading", true);
                            this.$set(item, "movedianIndex", null);
                            this.$set(item, "movedianIndex2", null);
                            this.$set(item, "loading2", false);
                            this.$set(item, "serialNumber", '');

                            if ( index == indexe){
                                this.$set(item, "current", currt);
                                this.$set(item, "oldCurrent", currt);
                            }else {
                                this.$set(item, "current", 1);
                                this.$set(item, "oldCurrent", 1);
                            }

                            this.getMapPunctuation(item, index);

                            //暂用
                            // item.culturalPositionCoordinates.forEach(item2 => {
                            //     this.$set(item2, 'top', item2.yAxis)
                            //     this.$set(item2, 'left', item2.xAxis)
                            //     this.$set(item2, 'xuanzhong', false)
                            //     this.$set(item2, 'tuodng', false)
                            //     this.$set(item2, 'zhanshi', false)
                            // })
                        });
                    }
                });
                console.log(this.list)
            },

            //获取第一页的数据资源
            getMapPunctuation(mapItem, index, num) {
                this.list[index].loading = true
                if (num) {
                    mapItem.current = 1
                }
                this.$axios(this.api.mapGuide.punctuationList, {
                    id: mapItem.id,
                    name: mapItem.culturalRelicName,
                    current: mapItem.current,
                    size: mapItem.pageSize,
                }, 'get').then(res => {
                    console.log(res)
                    this.list[index].total = parseInt(res.data.total)
                    this.list[index].loading = false
                    let culturalPositionCoordinates = res.data.records;
                    culturalPositionCoordinates.forEach(item2 => {
                        this.$set(item2, 'top', item2.yAxis)
                        this.$set(item2, 'left', item2.xAxis)
                        this.$set(item2, 'xuanzhong', false)
                        this.$set(item2, 'tuodng', false)
                        this.$set(item2, 'zhanshi', false)
                        this.$set(item2, 'mouseLeftPress', false)
                        this.$set(item2, 'loading3', false)
                        let fileDate = item2.culturalDetail.name
                        this.$set(item2, 'fileDate', fileDate)
                    })
                    mapItem.culturalPositionCoordinates = culturalPositionCoordinates
                    // this.$set(this.list[index],'culturalPositionCoordinates',culturalPositionCoordinates)
                })
            },

            //重新上传触发索引
            uploadClick(index) {
                if(this.initialEntry){
                    this.$message.error('请先选择展览和展厅')
                    return;
                }
                this.mapIndex = index;
                console.log(this.mapIndex);
            },

            //上传处理
            beforeAvatarUpload(file) {
                console.log(file);
                let suf = file.type.split("/")[1].toLowerCase();
                if (this.fileFormat.indexOf(suf) == -1) {
                    this.$message.error(`图片只能是${this.fileFormat.join()}格式`);
                    return false;
                }
                // const isJPG = file.type === "image/jpeg";
                // const isLt2M = file.size / 1024 / 1024 < 2;
            },

            //上传成功
            handleAvatarSuccess(res) {
                console.log(res);
                this.list[this.mapIndex].mapUrl = res.data;
                this.$message.success('地图上传成功')
            },

            //保存地图和地图名称
            preservationMap(index) {
                if (this.list[index].mapName.trim() == "") {
                    this.$message.error("请填写地图名称");
                    return;
                } else if (this.list[index].mapUrl.trim() == "") {
                    this.$message.error("请上传地图图片");
                    return;
                }
                if (this.list[index].id == '') {
                    //新增
                    this.$axios(this.api.mapGuide.mapPhototSave, {
                        mapUrl: this.list[index].mapUrl,
                        mapName: this.list[index].mapName,
                        exhibitionId: this.exhibition2,
                    }, 'post').then(res => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('地图保存成功')
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    //修改
                    this.$axios(this.api.mapGuide.mapPhototModify, {
                        id: this.list[index].id,
                        mapUrl: this.list[index].mapUrl,
                        mapName: this.list[index].mapName,
                        exhibitionId: this.exhibition2,
                    }, 'put').then(res => {
                        if (res.status) {
                            this.$message.success('地图修改成功')
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            },

            //取消保存地图和地图名称
            preservationNoMap(index) {
                this.getList()
            },

            //重新上传地图
            reUpload(index) {
                if (this.list[index].img != ''){
                    this.$confirm("替换地图后，现有坐标的位置会有偏差, 是否替换?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.list[index].mapUrl = "";
                    });
                }
            },

            //地图删除
            mapDelete(index) {
                console.log(index);
                console.log(this.list[index]);
                this.$confirm("删除地图时将同步删除全部坐标点, 是否删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.mapGuide.mapDelete + this.list[index].id, {}, 'delete').then(res => {
                        if (res.status) {
                            this.$message.success('删除成功')
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    });
                });
            },
            //添加地图
            addPunctuation() {
                this.mapAddType = true;
                this.list.push({
                    id: '',
                    mapName: "",
                    bianji: true, //是否编辑图片名字
                    //拖拽图片的样式
                    imgWidth: 100, //图片放大宽度
                    addBiaoDian:false,//保存点击
                    imgTop: 0, //图片顶部的定位
                    imgLeft: 0, //图片左边的定位
                    mapUrl: "", //图片地址
                    xinzengs: false, //是否处于新增标点的状态
                    loading: false, //列表加载
                    addsele: "", //新增数据选择的文物
                    current: 1, //第几页
                    oldCurrent: 1,//老分页
                    pageSize: 10, //一页多少条数据
                    total: 0, //数据总量
                    culturalPositionCoordinates: [], //地图标点
                    culturalRelicName: "", //右边搜索栏文物名称
                    movedianIndex: "", //正在编辑的点的索引
                    movedianIndex2: "", //正在编辑的点的索引
                    serialNumber: 1,
                    operationTimeStart: 0,
                    operationTimeEnd: 0,

                    loading2: false,//搜索状态
                    serialNumber: '',//编号
                    // culturalPositionCoordinates: [
                    //     {
                    //         xAxis: 50, //坐标百分比
                    //         yAxis: 50,
                    //         top: 50, //鼠标拖动时临时地址存储点px
                    //         left: 50,
                    //         xuanzhong: false, //是否选中该点
                    //         tuodng: false, //点位是否使用px作为坐标点
                    // zhanshi:false,//新增还未保存的点显示
                    //   mouseLeftPress:'false',//用于解决鼠标左键长按和图片放大冲突问题
                    //     },
                    // ],
                });
            },

            //处理顺序，限制顺序大小 num为1新增的框 2编辑的框
            sequentialProcessing(num,index,index2){
                if (num == 1){
                    if (this.list[index].serialNumber < 0){
                        this.list[index].serialNumber = 0
                    }else if (this.list[index].serialNumber > 9999){
                        this.list[index].serialNumber = 9999
                    }
                }else {
                    if (this.list[index].culturalPositionCoordinates[index2].serialNumber < 0){
                        this.list[index].culturalPositionCoordinates[index2].serialNumber = 0
                    }else if (this.list[index].culturalPositionCoordinates[index2].serialNumber > 9999){
                        this.list[index].culturalPositionCoordinates[index2].serialNumber = 9999
                    }
                }
            },

            //边打边搜
            //获取未标点的文物列表
            remoteMethod(query, index, index2) {
                console.log(query, index, index2)
                if ((index2 == 0 || index2) && (index == 0 || index)) {
                    this.list[index].culturalPositionCoordinates[index2].loading3 = true;
                }
                if (index == 0 || index) {
                    this.list[index].loading2 = true;
                }
                this.$axios(this.api.mapGuide.getNotPunctuatedFileList, {
                    name: query
                }, 'post').then(res => {
                    console.log(res)
                    if (res.status) {
                        this.options = res.data
                        if (this.options.length == 0 && query.trim() == ''){
                            this.$message.error('暂无文物可选，请先新增文物')
                        }
                        if ((index2 == 0 || index2) && (index == 0 || index)) {
                            this.list[index].culturalPositionCoordinates[index2].loading3 = false;
                        }
                        if (index == 0 || index) {
                            this.list[index].loading2 = false;
                        }
                    }
                })
            },

            //新增标点
            newPunctuation(index) {
                console.log(index);
                console.log(this.list[index]);
                if (this.list[index].bianji == true) {
                    this.$message.error("未保存地图数据，请先保存");
                    return;
                } else if (this.list[index].xinzengs == true) {
                    this.$confirm("是否保存当前新增坐标?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.xinzbaocun(this.list[index].id, index)
                        })
                        .catch(() => {
                            this.xinzNo(this.list[index].id, index);
                        });
                } else if (
                    this.list[index].culturalPositionCoordinates.filter(
                        (item) => item.xuanzhong == true
                    ).length
                ) {
                    this.$confirm("是否保存当前修改?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.culturalRelicSave(
                                this.list[index].movedianIndex,
                                this.list[index].movedianIndex2
                            );
                        })
                        .catch(() => {
                            this.culturalRelicNoEdit(this.list[index], index);
                        });
                } else {
                    this.list[index].xinzengs = true;
                    this.list[index].culturalPositionCoordinates.push({
                        xAxis: 50, //坐标百分比
                        yAxis: 50,
                        top: 50, //鼠标拖动时临时地址存储点px
                        left: 50,
                        xuanzhong: true, //是否选中该点
                        tuodng: false, //点位是否使用px作为坐标点
                        zhanshi: true, //新增未保存的点，不在右边列表上展示
                        mouseLeftPress: 'false',//用于解决鼠标左键长按和图片放大冲突问题
                        serialNumber: '1',
                        loading2: false,
                    });
                    this.list[index].movedianIndex = index;
                    this.list[index].movedianIndex2 =
                        this.list[index].culturalPositionCoordinates.length - 1;
                }
            },

            //新增保存标点
            xinzbaocun(mapId, index,mapItem) {
                if (this.list[index].addsele == "") {
                    this.$message.error("请选择文物信息");
                    return;
                }
                // serialNumber
                if (this.list[index].serialNumber == '') {
                    this.$message.error("请输入陈展顺序");
                    return;
                }
                if (this.list[index].serialNumber <= 0 || this.list[index].serialNumber > 9999) {
                    this.$message.error("陈展顺序必须在1~9999之间");
                    return;
                }
                let punctuationIndex = this.list[index].culturalPositionCoordinates.length - 1
                this.list[index].addBiaoDian = true;
                this.$axios(this.api.mapGuide.punctuationSave, {
                    exhibitionManagementId:this.exhibition1,
                    exhibitionHallId: this.exhibition2,
                    mapId: mapId,
                    culturalDetail: {
                        id: this.list[index].addsele,
                    },
                    xAxis: this.list[index].culturalPositionCoordinates[punctuationIndex].xAxis,
                    yAxis: this.list[index].culturalPositionCoordinates[punctuationIndex].yAxis,
                    serialNumber: this.list[index].serialNumber,
                }, 'post').then(res => {
                    if (res.status) {
                        this.$message.success('标点保存成功')
                        // mapItem.current = res.data
                        this.getList(res.data, index)
                        // this.getMapPunctuation(mapItem, index);
                    } else {
                        this.$message.error(res.msg)
                    }
                    this.list[index].addBiaoDian = false;
                })
            },

            //新增坐标取消
            xinzNo(mapId, index) {
                this.list[index].xinzengs = false;
                this.list[index].movedianIndex = null
                this.list[index].culturalPositionCoordinates = this.list[index].culturalPositionCoordinates.filter((item) => item.zhanshi != true);
                this.list[index].serialNumber = '';
                this.list[index].addsele = '';
            },

            //列表的点击编辑
            culturalRelicEdit(index, index2) {
                if (this.list[index].xinzengs) {
                    this.$message.error("您新增标点还未保存，请先保存");
                } else {
                    if (
                        this.list[index].movedianIndex != null &&
                        this.list[this.list[index].movedianIndex].culturalPositionCoordinates[
                            this.list[index].movedianIndex2
                            ].xuanzhong == true
                    ) {
                        this.$confirm("是否保存当前修改?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                this.culturalRelicSave(
                                    this.list[index].movedianIndex,
                                    this.list[index].movedianIndex2
                                );
                            })
                            .catch(() => {
                                this.culturalRelicNoEdit(this.list[index], index);
                            });
                    } else {
                        this.list[index].culturalPositionCoordinates[index2].xuanzhong = true;
                        this.list[index].movedianIndex = index;
                        this.list[index].movedianIndex2 = index2;
                    }
                }
            },

            //文物取消编辑
            culturalRelicNoEdit(mapItem, index, index2) {
                this.list[index].culturalPositionCoordinates[index2].xuanzhong = false;
                this.list[index].movedianIndex = null;
                this.list[index].movedianIndex2 = null;
                this.getMapPunctuation(this.list[index], index);
            },

            //文物选择
            fileSelect(value) {
                this.filselects = true;
            },

            //文物修改
            culturalRelicSave(index, index2, refresh) {
                let fileId = this.list[index].culturalPositionCoordinates[index2].fileDate;
                if (!this.filselects) {
                    fileId = null;
                }
                this.$axios(this.api.mapGuide.punctuationUpdateById, {
                    culturalDetail: {
                        id: fileId,
                    },
                    exhibitionManagementId:this.exhibition1,
                    exhibitionHallId: this.exhibition2,
                    id: this.list[index].culturalPositionCoordinates[index2].id,
                    mapId: this.list[index].id,
                    xAxis: this.list[index].culturalPositionCoordinates[index2].xAxis,
                    yAxis: this.list[index].culturalPositionCoordinates[index2].yAxis,
                    serialNumber: this.list[index].culturalPositionCoordinates[index2].serialNumber,
                }, 'put').then(res => {
                    if (res.status) {
                        this.$message.success('修改成功')
                        this.filselects = false;
                        this.list[index].movedianIndex = null
                        this.list[index].movedianIndex2 = null
                        if (!refresh) {
                            this.getMapPunctuation(this.list[index], index)
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },

            //文物删除
            culturalRelicDelete(fileId, index) {
                this.$confirm("此操作将永久删除该标点, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.mapGuide.punctuationDelete + fileId, {}, 'delete').then(res => {
                        if (res.status) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            if (this.list[index].culturalPositionCoordinates.length == 1 && this.list[index].current != 1 ){
                                this.list[index].current --;
                            }
                            this.getMapPunctuation(this.list[index], index)
                        } else {
                            this.$message.error(res.msg)
                        }
                    });
                });
            },

            // 当前页
            currentChangeHandle(val, mapId, index) {
                this.list[index].current = this.list[index].oldCurrent
                if (this.list[index].xinzengs) {
                    this.$message.error("您新增标点还未保存，请先保存");
                } else {
                    if (
                        this.list[index].movedianIndex != null &&
                        this.list[this.list[index].movedianIndex].culturalPositionCoordinates[
                            this.list[index].movedianIndex2
                            ].xuanzhong == true
                    ) {
                        this.$confirm("是否保存当前修改?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                this.culturalRelicSave(
                                    this.list[index].movedianIndex,
                                    this.list[index].movedianIndex2,
                                    true
                                );
                                this.list[index].current = val;
                                this.getMapPunctuation(this.list[index], index);
                            })
                            .catch(() => {
                                this.culturalRelicNoEdit(this.list[index], index);
                            });
                    } else {
                        this.list[index].current = val;
                        this.list[index].oldCurrent = val;
                        this.getMapPunctuation(this.list[index], index);
                    }
                }
            },

            //标点位置计算
            weizhicomp(obj) {
                //12和24为图标自身的宽高一半
                if (obj.tuodng) {
                    return {
                        top: `calc(${obj.top}px - 24px)`,
                        left: `calc(${obj.left}px - 12px)`,
                    };
                } else {
                    return {
                        top: `calc(${obj.yAxis}% - 24px)`,
                        left: `calc(${obj.xAxis}% - 12px)`,
                    };
                }
            }
            ,

            //标点的点击编辑
            movedianji(e, index, index2) {
                if (
                    this.list[index].movedianIndex == index &&
                    this.list[index].movedianIndex2 == index2
                ) {
                    //是选中的这个点
                    this.mousePress(e, index, index2);
                } else {
                    //不是选中的这个点
                    //新增点是否保存
                    this.culturalRelicEdit(index, index2)
                }
            }
            ,

            //图片点击
            tupiandianji(e, index) {
                setTimeout(() => {
                    if ((this.operationTimeEnd - this.operationTimeStart) <= 200) {
                        if (this.list[index].movedianIndex != null) {
                            let x = e.clientX - this.tuozhuaiImageLeft;
                            let y = e.clientY - this.tuozhuaiImageTop;
                            console.log(this.list[index].movedianIndex);
                            console.log(this.list[this.list[index].movedianIndex]);
                            this.list[this.list[index].movedianIndex].culturalPositionCoordinates[
                                this.list[index].movedianIndex2
                                ].yAxis = (y / this.tuozhuaiImageHeight) * 100;
                            this.list[this.list[index].movedianIndex].culturalPositionCoordinates[
                                this.list[index].movedianIndex2
                                ].xAxis = (x / this.tuozhuaiImageWidth) * 100;
                            this.list[this.list[index].movedianIndex].culturalPositionCoordinates[
                                this.list[index].movedianIndex2
                                ].tuodng = false;
                        }
                    }
                }, 200)
            },

            //图片缩放
            scrollInit(event, index) {
                const that = this;
                // 获取要绑定事件的元素
                let nav = that.$refs[`gundong${index}`][0];
                // document.addEventListener('DOMMouseScroll', handler, false)
                // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
                // nav.addEventListener("mousewheel", handler, false);
                // 滚动事件的出来函数
                // function handler(event) {
                // console.log
                // 获取滚动方向
                const detail = event.wheelDelta || event.detail;
                // 定义滚动距离
                let step = 5;
                // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
                if (detail < 0) {
                    //向下 缩小
                    if (that.list[index].imgWidth != 100) {
                        //限制能缩小的最小尺寸
                        that.list[index].imgWidth -= step;
                        that.imagNarrow(event, index, detail);
                    } else {
                        that.list[index].imgLeft = 0;
                    }
                } else {
                    //向上 放大
                    if (that.list[index].imgWidth != 300) {
                        //限制能放大的最大尺寸
                        that.list[index].imgWidth += step;
                        that.imagNarrow(event, index, detail);
                    }
                }
                // }
            }
            ,

            //图片缩放处理
            imagNarrow(event, index, detail) {
                let that = this;
                let aa =
                    event.clientX -
                    this.$refs["gundong" + index][0].getBoundingClientRect().x;
                let bb =
                    event.clientY -
                    this.$refs["gundong" + index][0].getBoundingClientRect().y;
                that.getImageWidth(index);
                if (that.list[index].movedianIndex2 && that.list[index].movedianIndex) {
                    if (that.list[index].culturalPositionCoordinates[that.list[index].movedianIndex2].mouseLeftPress == true) {
                        that.operationConflict(event, index)
                    }
                }
                let henBili = aa / that.visualWidth;
                let shuBili = bb / that.visualHeight;
                if (
                    that.visualWidth - that.list[index].imgLeft >
                    that.tuozhuaiImageWidth
                ) {
                    //图片右边到达边界
                    that.list[index].imgLeft = that.visualWidth - that.tuozhuaiImageWidth;
                } else {
                    //没达到边界时放大鼠标位置x
                    that.list[index].imgLeft = aa - henBili * that.tuozhuaiImageWidth;
                }
                if (
                    that.visualHeight - that.list[index].imgTop >
                    that.tuozhuaiImageHeight
                ) {
                    //图片下边界到达，不能往上拉
                    if (that.visualHeight < that.tuozhuaiImageHeight) {
                        that.list[index].imgTop =
                            that.visualHeight - that.tuozhuaiImageHeight;
                    } else {
                        //图片高度小于视口宽度情况下
                        that.list[index].imgTop = 0;
                    }
                } else {
                    //没达到边界时放大鼠标位置y
                    that.list[index].imgTop = bb - shuBili * that.tuozhuaiImageHeight;
                }
            }
            ,

            //左键长按地图缩放时的点位处理
            operationConflict(e, index) {
                let xwei = e.clientX - this.tuozhuaiImageLeft;
                let ywei = e.clientY - this.tuozhuaiImageTop;
                // this.$set(this.list[index].culturalPositionCoordinates[this.list[index].movedianIndex2], 'top', ywei)
                this.list[index].culturalPositionCoordinates[that.list[index].movedianIndex2].top = ywei;
                this.list[index].culturalPositionCoordinates[this.list[index].movedianIndex2].left = xwei;
                this.list[index].culturalPositionCoordinates[this.list[index].movedianIndex2].tuodng = true;
            }
            ,

            //计算图片的尺寸
            getImageWidth(index) {
                let wh =
                    this.$refs["imagemove" + index][0].imageWidth /
                    this.$refs["imagemove" + index][0].imageHeight;
                this.tuozhuaiImageLeft =
                    this.$refs["huaBu" + index][0].getBoundingClientRect().x;
                this.tuozhuaiImageTop =
                    this.$refs["huaBu" + index][0].getBoundingClientRect().y;
                this.visualWidth = this.$refs["gundong" + index][0].offsetWidth;
                this.visualHeight = this.$refs["gundong" + index][0].offsetHeight;
                this.visualLift =
                    this.$refs["gundong" + index][0].getBoundingClientRect().x;
                this.visualTop =
                    this.$refs["gundong" + index][0].getBoundingClientRect().y;
                //图片宽高
                this.tuozhuaiImageWidth =
                    (this.visualWidth * this.list[index].imgWidth) / 100;
                this.tuozhuaiImageHeight = this.tuozhuaiImageWidth / wh;
            }
            ,

            //拖拽
            //鼠标按下
            mousePress(e, index, index2) {
                console.log(1)
                if (e.buttons == 1) { //鼠标左键
                    this.operationTimeStart = new Date()
                    let that = this;
                    this.getImageWidth(index);
                    this.yuanX = e.clientX;
                    this.yuanY = e.clientY;
                    if (index2) {
                        this.list[index].culturalPositionCoordinates[index2].mouseLeftPress = true;
                    }
                    document.onmousemove = function (e) {
                        //鼠标移动
                        if (that.yuanX) {
                            let moveX = e.clientX - that.yuanX;
                            let moveY = e.clientY - that.yuanY;

                            if (!index2 && index2 != 0) {
                                //图片拖拽
                                if (that.list[index].imgTop + moveY > 0) {
                                    //图片上边界到达，不能往下拉
                                    that.list[index].imgTop = 0;
                                } else {
                                    that.list[index].imgTop = moveY + that.list[index].imgTop;
                                }
                                if (
                                    that.visualHeight - that.list[index].imgTop >
                                    that.tuozhuaiImageHeight
                                ) {
                                    //图片下边界到达，不能往上拉
                                    if (that.visualHeight < that.tuozhuaiImageHeight) {
                                        that.list[index].imgTop =
                                            that.visualHeight - that.tuozhuaiImageHeight;
                                    } else {
                                        //图片高度小于视口宽度情况下
                                        that.list[index].imgTop = 0;
                                    }
                                }
                                if (that.list[index].imgLeft + moveX > 0) {
                                    //图片左边界到达，不能往右拉
                                    that.list[index].imgLeft = 0;
                                } else {
                                    that.list[index].imgLeft = moveX + that.list[index].imgLeft;
                                }
                                if (
                                    that.visualWidth - that.list[index].imgLeft >
                                    that.tuozhuaiImageWidth
                                ) {
                                    that.list[index].imgLeft =
                                        that.visualWidth - that.tuozhuaiImageWidth;
                                }
                            } else {
                                //图标拖拽
                                let ywei = e.clientY - that.tuozhuaiImageTop;
                                let xwei = e.clientX - that.tuozhuaiImageLeft;
                                if (ywei < 0) {
                                    //触顶
                                    ywei = 0;
                                }
                                if (ywei > that.tuozhuaiImageHeight) {
                                    //触底
                                    ywei = that.tuozhuaiImageHeight;
                                }
                                // if (ywei > that.visualHeight) {
                                //   //触底
                                //   ywei = that.visualHeight;
                                // }
                                if (xwei < 0) {
                                    //触左
                                    xwei = 0;
                                }
                                if (xwei > that.visualWidth) {
                                    //触右
                                    xwei = that.visualWidth;
                                }
                                that.list[index].culturalPositionCoordinates[index2].top = ywei;
                                that.list[index].culturalPositionCoordinates[index2].left = xwei;
                                that.list[index].culturalPositionCoordinates[index2].tuodng = true;
                            }
                            that.yuanX = e.clientX;
                            that.yuanY = e.clientY;
                        }
                    };
                    document.onmouseup = function (e) {
                        //鼠标松开
                        console.log(2)
                        that.operationTimeEnd = new Date()
                        console.log(that.operationTimeEnd)
                        that.yuanX = "";
                        that.yuanY = "";
                        // if (index2 || index2 == 0) {
                        if (that.list[index].culturalPositionCoordinates[index2].tuodng == true) {
                            that.list[index].culturalPositionCoordinates[index2].xAxis =
                                (that.list[index].culturalPositionCoordinates[index2].left /
                                    that.tuozhuaiImageWidth) *
                                100;
                            that.list[index].culturalPositionCoordinates[index2].yAxis =
                                (that.list[index].culturalPositionCoordinates[index2].top /
                                    that.tuozhuaiImageHeight) *
                                100;
                            that.list[index].culturalPositionCoordinates[index2].tuodng = false;
                        }
                        that.list[index].culturalPositionCoordinates[index2].mouseLeftPress = false;
                    };
                }
            },

            setOptionWidth(event) {
                this.$nextTick(() => {
                    let selectOptionWidth = event.srcElement.offsetWidth + "px";
                });
            }
        },
    };
</script>

<style scoped>
    .auifbaudf {
        margin-bottom: 10px;
    }

    .formPage {
        background-color: transparent !important;
        padding: 0 !important;
        border-radius: 4px;
    }

    .banaojoi >>> .el-upload--text {
        width: 100% !important;
    }

    .banaojoi1 {
        margin: 5px;
        padding: 20px;
        background-color: #ffffff;
    }

    .banaojoi {
        height: 590px;
        margin: 5px;
        padding: 20px;
        background-color: #ffffff;
    }

    .aufuiadf {
        height: 500px;
        margin: 5px;
        padding: 20px;
    }

    .setw {
        height: 590px;
    }

    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100%;
        /*height: 500px;*/
        height: 100%;
        line-height: 400px;
        text-align: center;
    }

    .tupian {
        overflow: hidden;
        height: 500px;
        position: relative;
    }

    .zhezhao {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, .5);
        /*background: red;*/
        /*opacity: .5;*/
    }

    .tuozhuaiimg {
        position: absolute;
        /*transition: width .2s linear 0s;*/
    }

    .zhanfua {
        cursor: pointer;
        font-size: 25px;
        position: absolute;
        color: red;
        z-index: 5;
    }

    .tubiaoafd {
        width: 100px;
        float: right;
        padding: 0 30px 23px 0;
    }

    .bahjdb {
        cursor: pointer;
        font-size: 25px;
        position: absolute;
        color: #8c939d;
        z-index: 5;
    }

    .xinzeng {
        margin: 5px;
        text-align: center;
    }

    .ahrwrgad {
        transform: rotate(-90deg);
    }


    .ahrwrgad2 {
        /*transform: rotate(-90deg);*/
        color: #ffffff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        /*font-size: 25px;*/
        position: absolute;
        /*color: #8c939d;*/
        z-index: 99;
    }

    .table {
        z-index: 30 !important;
    }

    .deitXinxi {
        margin: 10px 0;
    }

    .deitXinxi >>> .xuanZeKaung {
        width: 300px;
        z-index: 99999 !important;
    }

    .tanChang {
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
                                                             