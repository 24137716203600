<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            v-dialogDrag
            width="700px"
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" status-icon :rules="dataRule" v-loading="loading" ref="inputForm"
                 @keyup.enter.native="inputFormSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-row :gutter="15">
                <el-col :span="24">
                    <el-form-item label="角色名称" prop="name" :rules="[
            {required: true, whitespace: true, message: '角色名称不能为空', trigger: 'blur'}
          ]">
                        <el-input v-model="inputForm.name" placeholder="角色名称" maxlength="50"></el-input>
<!--                        <input type="hidden" v-model="inputForm.oldName"/>-->
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="hasPermission('sys:role:admin')">
                    <el-form-item label="是否管理员" prop="isSys" :rules="[
            {required: true, message: '是否管理员不能为空', trigger: 'blur'}
          ]">
                        <el-select v-model="inputForm.isSys" placeholder="请选择" style="width: 100%;">
                            <el-option
                                    v-for="item in optionArr2"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
<!--                        <div class="help-block">“是”代表此数据只有超级管理员能进行修改</div>-->
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="是否可用" prop="useable" :rules="[
            {required: true, message: '是否可用不能为空', trigger: 'blur'}
          ]">
                        <el-select v-model="inputForm.useable" placeholder="请选择" style="width: 100%;">
                            <el-option
                                    v-for="item in optionArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="help-block">“是”代表此数据可用，“否”则表示此数据不可用</div>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remarks">
                        <el-input type="textarea" v-model="inputForm.remarks" placeholder="备注" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" type="primary" @click="inputFormSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                inputForm: {
                    id: '',
                    name: '',
                    remarks: '',
                    isSys: '1',
                    useable: '1'
                },
                dataRule: {
                    name: [
                        {required: true, message: '角色名称不能为空', trigger: 'blur'}
                    ]
                },
                optionArr: [
                    {
                        value: '0',
                        label: '是'
                    }, {
                        value: '1',
                        label: '否'
                    }
                ],
                optionArr2: [
                    {
                        value: '1',
                        label: '是'
                    }, {
                        value: '0',
                        label: '否'
                    }
                ]
            }
        },
        methods: {
            init(method, id) {
                this.method = method
                this.inputForm.id = id
                if (method === 'add') {
                    this.title = `新建角色`
                } else if (method === 'edit') {
                    this.title = '修改角色'
                } else if (method === 'view') {
                    this.title = '查看角色'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs.inputForm.resetFields()
                    if (this.inputForm.id) {
                        this.loading = true
                        this.$axios(this.api.auth.sysroleGetById, {roleId: this.inputForm.id}, 'get').then(data => {
                            console.log(data);
                            if (data.status) {
                                this.loading = false
                                this.inputForm = this.recover(this.inputForm, data.data)
                                // this.inputForm.oldName = data.role.name
                            }
                        })
                    }
                })
            },
            // 表单提交
            inputFormSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.method === 'add') {  // 新增
                            var param = {
                                name: this.inputForm.name,
                                remarks: this.inputForm.remarks,
                                isSys: this.inputForm.isSys,
                                useable: this.inputForm.useable,
                            }
                            this.$axios(this.api.auth.sysroleSave, param, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        } else if(this.method === 'edit') {  // 修改
                            this.$axios(this.api.auth.sysroleUpdateById, this.inputForm, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
