<template>
    <div class="page formPage" id="fileControl">
        <el-row :gutter="12">
            <el-col :span="8">
                <el-col :span="6">
                    <div style="text-align: right">填写日期:</div>
                </el-col>
                <el-col :span = '18'>
                    <el-date-picker
                            clearable
                            style="width: 100%;"
                            size="small"
                            v-model="search.dataTime"
                            type="daterange"
                            range-separator="至"
                            @change="selectDate()"
                            value-format="yyyy-MM-dd"
                            start-placeholder="起始时间"
                            end-placeholder="结束时间">
                    </el-date-picker>
                </el-col>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%" size="small" clearable
                           v-model="search.collectionUnit"
                           placeholder="请选择收藏单位">
                    <el-option
                            v-for="item in collectionUnitList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%" size="small" clearable
                           v-model="search.culturalLevel"
                           placeholder="请选择藏品级别">
                    <el-option
                            v-for="item in levelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%" size="small" clearable
                           v-model="search.modality"
                           placeholder="请选择形式">
                    <el-option
                            v-for="item in modalityList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%" size="small" clearable
                           v-model="search.style"
                           placeholder="请选择样式">
                    <el-option
                            v-for="item in styleList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%" size="small" clearable
                           v-model="search.years" placeholder="请选择年代">
                    <el-option
                            v-for="item in yearsList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row :gutter="12" style="margin-top: 10px">
            <el-col :span="8">
                <el-col :span="18" :offset="6">
                    <el-select style="width: 100%;" size="small" clearable
                               v-model="search.original"
                               placeholder="请选择原件/复制件">
                        <el-option
                                v-for="item in originalList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%;" size="small" clearable
                           v-model="search.culturalSource"
                           placeholder="请选择藏品来源">
                    <el-option
                            v-for="item in culturalSourceList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%;" size="small" clearable
                           v-model="search.exhibit"
                           placeholder="请选择是否展出">
                    <el-option
                            v-for="item in exhibitList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="6">
                <el-input clearable placeholder="附表编号/现登记号/名称/原名" size="small" v-model="search.name"></el-input>
            </el-col>
            <el-col :span="3">
                <el-select style="width: 100%;" size="small" clearable
                           v-model="search.collectionClassification"
                           placeholder="请选择价值">
                    <el-option
                            v-for="item in collectionClassificationList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
<!--            <el-row :gutter="12" style="margin-top: 10px">-->
<!--                <el-col :span="8">-->
<!--                    <el-col :span="18" :offset="6">-->
            <el-col :span="3" :offset="2" style="margin-top: 10px">
                <el-checkbox :false-label="null" :true-label="1" v-model="search.collection">只看文物</el-checkbox>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4">
                <el-button size="small" type="primary" icon="el-icon-search" @click="getList(1)">查询</el-button>
                <el-button size="small" type="primary" icon="el-icon-refresh-right" @click="resetSearch()">重置
                </el-button>
            </el-col>
            <el-col :span="19" style="text-align: right">
                <el-button v-if="hasPermission('3DFileControl:3DFileControl:add')" size="small" type="primary"
                           @click="editadd(0,2)">新增
                </el-button>
                <el-button
                        v-if="hasPermission('3DFileControl:3DFileControl:downLoad')" type="primary"
                        :disabled="seltDownLosd.length == 0"
                        size="small" @click="downLoadList()">下载二维码
                </el-button>
                <el-button
                        v-if="hasPermission('3DFileControl:3DFileControl:delete')" type="primary"
                        :disabled="selectListIds.length == 0"
                        size="small" @click="Wudelete()">删除
                </el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 135px)"
                @selection-change="handleSelectionChange"
                class="table">
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="200">
                <template slot-scope="scope">
                    <div class="flex_l_c">
                        <el-tooltip class="item" effect="dark" :content=scope.row.name placement="top">
                            <div class="stayName">{{ scope.row.name }}</div>
                        </el-tooltip>
                        <img v-if="scope.row.img == true" class="zhanshiIcon" src="../../../assets/img/photoIcon.png">
                        <img v-if="scope.row.three == true" class="zhanshiIcon" src="../../../assets/img/threeIcon.png">
                        <img v-if="scope.row.audio == true" class="zhanshiIcon" src="../../../assets/img/audioIcon.png">
                        <img v-if="scope.row.video == true" class="zhanshiIcon" src="../../../assets/img/vudioIcon.png">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="oldName" label="原名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="serialNumber" label="附表编号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="fillDate" label="填写日期" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="enabledState" label="启用状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.enabledState"
                            active-value="1"
                            inactive-value="0"
                            @change="stateChange(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
<!--            <el-table-column prop="collection" label="是否文物" show-overflow-tooltip>-->
<!--                <template slot-scope="scope">-->
<!--                    <el-switch-->
<!--                            v-model="scope.row.collection"-->
<!--                            active-value="1"-->
<!--                            inactive-value="0"-->
<!--                            @change="stateChange(scope.row)"-->
<!--                    >-->
<!--                    </el-switch>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="collectionUnit" label="收藏单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="registration" label="现登记号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="culturalLevel" label="文物级别" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="modality" label="形式" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="style" label="样式" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="years" label="年代" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="collectionClassification" label="藏品价值" show-overflow-tooltip>
                <template slot-scope="scope">
                   {{ scope.row.collectionClassification == 1 ? '镇馆之宝' : scope.row.collectionClassification == 2 ? '精品文物' : '一般文物'}}
                </template>
            </el-table-column>
            <el-table-column prop="original" label="原件/复制件" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="culturalSource" label="文物来源" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="size" label="具体尺寸" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="piece" label="件数" show-overflow-tooltip>
            </el-table-column>
<!--            <el-table-column prop="introduce" label="藏品简介" show-overflow-tooltip>-->
<!--                <template slot-scope="scope">-->
<!--                    <div v-html="scope.row.introduce"></div>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="remark" label="备注" show-overflow-tooltip>-->
<!--                <template slot-scope="scope">-->
<!--                    <div v-html="scope.row.remark"></div>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="userName" label="创建人" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column>
            <el-table-column prop="updateName" label="修改人" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.updateTime | formatDate }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button
                            v-if="hasPermission('3DFileControl:3DFileControl:see')"
                            icon="el-icon-view" type="text"
                            size="mini" @click="details(scope.row.id,0)">详情
                    </el-button>
                    <el-button
                            v-if="hasPermission('3DFileControl:3DFileControl:edit')"
                            icon="el-icon-edit" type="text"
                            size="mini" @click="editadd(scope.row.id,1)">编辑
                    </el-button>
                    <el-button
                            v-if="hasPermission('3DFileControl:3DFileControl:delete') && scope.row.enabledState == 0"
                            icon="el-icon-delete" type="text"
                            size="mini" @click="Wudelete(scope.row.id)">删除
                    </el-button>
                    <el-button
                            v-if="hasPermission('3DFileControl:3DFileControl:downLoad')"
                            icon="el-icon-download" type="text"
                            size="mini" @click="downLoad(scope.row)">下载二维码
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--新增-->
<!--        <addFileControl :collectionUnitList="collectionUnitList" :levelList="levelList" :modalityList="modalityList"-->
<!--                        :styleList="styleList" :yearsList="yearsList" :originalList="originalList" :exhibitionList="exhibitionList"-->
<!--                        :culturalSourceList="culturalSourceList" ref="addfilecontrol"></addFileControl>-->
    </div>
</template>

<script>
    export default {
        name: "fileControl",
        data() {
            return {
                search: {
                    startTime: '',
                    endTime: "",
                    collectionUnit: '',
                    culturalLevel: '',
                    modality: '',
                    years: '',
                    original: '',
                    culturalSource: '',
                    collectionClassification:'',
                    name: '',
                    exhibit:'',
                    collection:null,
                    dataTime: [],
                },
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,

                timer: '',//防抖

                //文物各项分类
                collectionUnitList: [],
                levelList: [],
                modalityList: [],
                styleList: [],
                yearsList: [],
                originalList: [],
                culturalSourceList: [],
                exhibitionList:[],
                selectListIds:[],
                seltDownLosd:[],
                collectionClassificationList: [
                    {
                        label: '镇馆之宝',
                        id: '1',
                    },
                    {
                        label: '精品文物',
                        id: '2',
                    },
                    {
                        label: '一般文物',
                        id: '3',
                    },
                ],
                exhibitList:[
                    {
                        label: '已结束',
                        id: '2',
                    },
                    {
                        label: '展出中',
                        id: '1',
                    },
                    {
                        label: '待展出',
                        id: '0',
                    }
                ],
            }
        },
        mounted() {
            console.log(this.$route.query.pageNo)
            if (this.$route.query.pageNo){
                console.log(this.$route.query.pageNo)
                this.search = JSON.parse(sessionStorage.getItem('3DFileControlSearch'))
                this.total = 10000000;//在pagination源码中，会对传入的current-page属性进行监听，如果total为0，不管设置的页码是多少，最后都是1。
                this.pageNo = parseInt(this.$route.query.pageNo)
            }
            this.getList();

            //文物各项分类
            this.getCulturalLevelList()
            this.getCollectionUnitList()
            this.getCollectionadfaitList()
            this.getModalityList()
            this.getStyleList()
            this.getYearsList()
            this.getOriginalList()
            this.getCulturalSourceList()
        },
        methods: {
            selectDate() {
                console.log(this.search.dataTime)
                if (!this.search.dataTime || this.search.dataTime.length == 0){
                    this.search.startTime = '';
                    this.search.endTime = '';
                }else {
                    this.search.startTime = this.search.dataTime[0];
                    this.search.endTime = this.search.dataTime[1];
                }
            },

            resetSearch() {
                this.search = {
                    startTime: '',
                    endTime: "",
                    collectionUnit: '',
                    culturalLevel: '',
                    modality: '',
                    years: '',
                    original: '',
                    culturalSource: '',
                    name: '',
                    exhibit:'',
                    collection:'',
                    dataTime:[],
                };
                this.getList()
            },

            //获取列表
            getList(num) {
                this.loading = true;
                if (num){
                    this.pageNo = 1
                }
                this.$axios(this.api.threeDFileControl.list, {
                    ...this.search,
                    current: this.pageNo,
                    pageSize: this.pageSize,
                }, 'post').then(res => {
                    this.loading = false;
                    this.dataList = res.data.records;
                    this.total = parseInt(res.data.total)
                })
            },

            //详情、预览
            details(id) {
                sessionStorage.setItem('3DFileControlSearch',JSON.stringify(this.search))
                this.$router.push({
                    path: '/culturalMent/collectionDetails',
                    query: {
                        id:id,
                        pageNo:this.pageNo,
                    },
                })
            },

            //num有1编辑，2为新增
            editadd(id, num) {
                sessionStorage.setItem('3DFileControlSearch',JSON.stringify(this.search))
                if(num == 1){
                    this.$router.push({
                        path: '/culturalMent/editleFileControl',
                        query: {
                            id:id,
                            num:num,
                            pageNo:this.pageNo,
                        },
                    })
                }else {
                    this.$router.push({
                        path: '/culturalMent/addFileControl',
                        query: {
                            id:id,
                            num:num,
                            pageNo:this.pageNo
                        },
                    })
                }
            },

            //多选文件
            handleSelectionChange(val){
                this.seltDownLosd = val
                this.selectListIds = []
                val.forEach(item=>{
                    if (item.enabledState != 1){
                        this.selectListIds.push(item.id)
                    }
                })
            },

            //修改状态
            stateChange(obj){
                // this.$axios(this.api.threeDFileControl.updateById, {...obj}, 'put').then(res => {
                console.log(obj)
                this.$axios(this.api.threeDFileControl.updateStateById, {
                    id:obj.id,
                    enabledState:obj.enabledState,
                }, 'put').then(res => {
                    if (res.status) {
                        this.$message.success('修改成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.getList();
                })
            },

            //删除
            Wudelete(id){
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = []
                    if (id){
                        ids.push(id)
                    }else {
                        ids = this.selectListIds
                    }
                    this.$axios(this.api.threeDFileControl.removeById,{ids:ids.join(',')},'delete').then(res=>{
                        if (res.status){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            if (this.dataList.length == ids.length && this.pageNo>1){
                                this.pageNo--;
                            }
                            this.getList();
                        } else {
                            this.$message({
                                type: 'error',
                                message: '删除失败!'
                            });
                        }
                    })
                }).catch(() => {
                });
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getList();
            },

            //一键下载二维码
            downLoadList(){
                this.seltDownLosd.forEach(item=>{
                    this.downLoad(item)
                })
            },

            //下载文物二维码
            downLoad(row){
                this.$axios(this.api.threeDFileControl.wechatSession,{
                    page:'pages/service/audioguide/details/culturalDetailSee',
                    scene:`id=${row.id}`,
                },'get').then(res=>{
                    if (res.status){
                        this.handleDownloadQrIMg(row.name,res.data)
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            },

            //图片下载
            //qrBase64是后台传回来的base64数据
            handleDownloadQrIMg(name,qrBase64) {
                // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
                const imgUrl = `data:image/png;base64,${qrBase64}`
                // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
                if (window.navigator.msSaveOrOpenBlob) {
                    const bstr = atob(imgUrl.split(',')[1])
                    let n = bstr.length
                    const u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr])
                    window.navigator.msSaveOrOpenBlob(blob, name + '.' + 'png')
                } else {
                    // 这里就按照chrome等新版浏览器来处理
                    const a = document.createElement('a')
                    a.href = imgUrl
                    a.setAttribute('download', name  + '.' + 'png')
                    a.click()
                }
            },


            //获取文物分级列表
            getCulturalLevelList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 1
                }, 'get').then(res => {
                    this.levelList = res.data;
                })
            },

            //形式列表
            getModalityList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 2
                }, 'get').then(res => {
                    this.modalityList = res.data;
                })
            },

            //样式列表
            getStyleList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 3
                }, 'get').then(res => {
                    this.styleList = res.data;
                })
            },

            //年代列表
            getYearsList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 4
                }, 'get').then(res => {
                    this.yearsList = res.data;
                })
            },

            //原件/复制件列表
            getOriginalList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 5
                }, 'get').then(res => {
                    this.originalList = res.data;
                })
            },

            //文物来源列表
            getCulturalSourceList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 6
                }, 'get').then(res => {
                    this.culturalSourceList = res.data;
                })
            },

            //获取文物分级列表
            getCollectionadfaitList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 8
                }, 'get').then(res => {
                    this.collectionUnitList = res.data;
                })
            },

            //获取文物展厅列表
            getCollectionUnitList() {
                this.$axios(this.api.culturalRelics.selectByTypeAll, {
                    type: 9
                }, 'get').then(res => {
                    this.exhibitionList = res.data;
                })
            },
        }
    }
</script>

<style>
    .el-tooltip__popper {
        max-width: 60%!important;
    }

    .zhanshiIcon{
        width: 15px;
        margin-left: 5px;
    }

    .stayName{
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
