var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":_vm.title,"close-on-click-modal":false,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"inputForm",class:_vm.method==='view'?'readonly':'',attrs:{"size":"small","model":_vm.inputForm,"disabled":_vm.method==='view',"label-width":"120px"}},[_c('el-row',{attrs:{"gutter":15}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"岗位名称","prop":"name","rules":[
                  {required: true, message:'岗位名称不能为空', trigger:'blur'}
                 ]}},[_c('el-input',{attrs:{"placeholder":"请填写岗位名称"},model:{value:(_vm.inputForm.name),callback:function ($$v) {_vm.$set(_vm.inputForm, "name", $$v)},expression:"inputForm.name"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"岗位编码","prop":"code","rules":[
                  {required: true, message:'岗位编码不能为空', trigger:'blur'},
                  {validator: _vm.validator.isRightfulString, trigger:'blur'}
                 ]}},[_c('el-input',{attrs:{"placeholder":"请填写岗位编码"},model:{value:(_vm.inputForm.code),callback:function ($$v) {_vm.$set(_vm.inputForm, "code", $$v)},expression:"inputForm.code"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"是否可用","prop":"status","rules":[
                  {required: true, message:'岗位状态不能为空', trigger:'blur'}
                 ]}},[_c('el-radio-group',{model:{value:(_vm.inputForm.status),callback:function ($$v) {_vm.$set(_vm.inputForm, "status", $$v)},expression:"inputForm.status"}},_vm._l((_vm.$dictUtils.getDictList('yes_no')),function(item){return _c('el-radio',{key:item.id,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"岗位排序","prop":"sort","rules":[
                  {required: true, message:'岗位排序不能为空', trigger:'blur'},
                  {validator: _vm.validator.isIntGtZero, trigger:'blur'}
                 ]}},[_c('el-input-number',{attrs:{"min":1,"max":1000,"label":"请填写岗位排序"},model:{value:(_vm.inputForm.sort),callback:function ($$v) {_vm.$set(_vm.inputForm, "sort", $$v)},expression:"inputForm.sort"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"岗位类型","prop":"type","rules":[
                  {required: true, message:'岗位类型不能为空', trigger:'blur'}
                 ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.inputForm.type),callback:function ($$v) {_vm.$set(_vm.inputForm, "type", $$v)},expression:"inputForm.type"}},_vm._l((_vm.$dictUtils.getDictList('sys_post_type')),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"备注信息","prop":"remarks","rules":[
                 ]}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请填写备注信息"},model:{value:(_vm.inputForm.remarks),callback:function ($$v) {_vm.$set(_vm.inputForm, "remarks", $$v)},expression:"inputForm.remarks"}})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("关闭")]),(_vm.method != 'view')?_c('el-button',{directives:[{name:"noMoreClick",rawName:"v-noMoreClick"}],attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.doSubmit()}}},[_vm._v("确定")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }