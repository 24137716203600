<template>
    <div id="homeBox">
        <img src="../assets/img/logo.png" class="logo">
        <div class="title">
            <div>欢迎进入</div>
            <div>湘绣数字化保护和利用系统</div>
        </div>
        <div class="menuBox flex_w" v-if="list.length > 0">
            <div class="item flex_l_c" v-for="(item, index) in list" :key="index" @click="handlerMenu(item)">
                <img class="itemBg" :src="item.imgUrl">
                <div class="imgBox flex_c_c" :class="item.icon"></div>
                <div class="name">{{item.name}}</div>
            </div>
        </div>
        <div class="title hint" v-else>暂无菜单权限哦，快去联系管理员分配菜单权限吧~</div>
        <div class="outLoginBtn" @click="logoutHandle">退出登录</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: JSON.parse(sessionStorage.getItem("allMenuList")) || [],
            }
        },
        mounted() {
            this.list.forEach((item, index) => {
                if(index < 10) {
                    this.$set(item, "imgUrl", require('../assets/img/menu'+index+'.png'))
                } else if (index >= 10 && index < 20) {
                    this.$set(item, "imgUrl", require('../assets/img/menu'+(index - 10)+'.png'))
                }else if (index >= 20 && index < 30) {
                    this.$set(item, "imgUrl", require('../assets/img/menu'+(index - 20)+'.png'))
                } else {
                    this.$set(item, "imgUrl", require('../assets/img/menu0.png'))
                }
            })
        },
        methods: {
            // 通知、待办
            toManagement() {
                this.$router.push({path: '/daily/management'})
            },
            // 点击菜单
            handlerMenu(menu) {
                if (menu.permission == "link") {
                    window.open(menu.href);
                    return
                }
                if(menu.children[0]) {
                    this.$router.push({path: menu.children[0].href})
                } else {
                    this.$router.push({path: menu.href})
                }
            },
            // 退出
            logoutHandle() {
                this.$confirm(`确定进行[退出]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.clear();
                    this.$router.replace({name: 'login'});
                    window.location.reload();
                    this.$axios(this.api.auth.loginout, {}, 'post').then(data => {

                    })
                })
            },
        },
    }
</script>

<style scoped>
</style>
